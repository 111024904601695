import React, { useState, useRef, useEffect } from "react";
import Dropzone from "react-dropzone";
import { Slider } from "react-semantic-ui-range";
import AvatarEditor from "react-avatar-editor";
import styled, { keyframes } from 'styled-components';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useToasts } from 'react-toast-notifications'
import { Popup, Icon, Button } from "semantic-ui-react";

import { UPDATE_EMPLOYEE_PIC } from "common/constants/actions";



var EXIF = require("exif-js");

const P = styled.p`
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
`

const Butt = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
  outline:none;
`

const Info = styled.div`
    font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;
`

const Box = styled.div`
  border-radius:10px;
  border:1px solid #e9e9e9;
  display:flex;
  align-items:center;
  padding:20px;
  width:340px;
  position:relative;
  margin-bottom:30px;

  @media (max-width:768px){
    width:250px;
  }
`

const Rotate = styled.div`
  position:absolute;
  bottom:5px;
  right:-30px;
  width:100px;
  cursor:pointer;
  color:#6a00ff;
`
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
`;

const ImageUpload = ({ employee_id, setOpen, onClickSaveImg }) => {
  const dispatch = useDispatch();
  // fakeImage.image_url
  const [image, setImage] = useState(false);
  const [newImage, setNewImage] = useState(false);
  const [editing, setEditing] = useState(null);
  const setEditorRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [slider, setSlider] = useState(1);
  const [employee, setEmployee] = React.useState(false);
  const { addToast } = useToasts()

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_employees) {
      setEmployee(get_employees[employee_id]);
      if (get_employees[employee_id]?.picture) {
        setImage(get_employees[employee_id].picture);
        setNewImage(false)
      }
    }
  }, [get_employees, employee_id]);

  const settings = {
    start: 50,
    min: 1,
    max: 100,
    step: 1,
    onChange: (value) => {
      setSlider(value);
      setScale((value * 2) / 100 + 0.1);
    },
  };


  function dataURLtoBlob(dataURL) {
    var array, binary, i;
    binary = atob(dataURL.split(",")[1]);
    array = [];
    i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  }


  const onClickSave = () => {
    if (setEditorRef.current) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = setEditorRef.current.getImage();
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = setEditorRef.current.getImageScaledToCanvas();
      // var fullQuality = canvasScaled.toDataURL('image/jpeg', .8);
      // console.log(canvasScaled)
      // console.log(canvasScaled?.toDataURL()? "Yes": "No")

      let image_file = ''
      try {
        image_file = dataURLtoBlob(canvasScaled.toDataURL());
        setNewImage(false)
      }
      catch (err) {
        // no image found
        setOpen(false)
        // console.log('no image found')
        addToast('No Image found to updated',
          {
            appearance: 'warning',
            autoDismiss: true,
          })
        return
      }

      // console.log(image_file)

      var myFile = new File([image_file], "name.jpg");
      if (employee) {
        // // update employee
        dispatch({
          type: UPDATE_EMPLOYEE_PIC,
          payload: {
            id: employee.id,
            picture: myFile,
          },
        });
        setOpen(false)
        addToast('Profile Picture Updated',
          {
            appearance: 'success',
            autoDismiss: true,
          })
      }

      // canvasScaled.toBlob(function(blob) {
      //   var newImg = document.createElement('img'),
      //       url = URL.createObjectURL(blob);
      //   newImg.onload = function() {
      //     // no longer need to read the blob so it's revoked
      //     URL.revokeObjectURL(url);
      //   };
      //   setImage(myFile)
      //   console.log(myFile)
      //   console.log(image)
      // });

      // setImage(fullQuality)
      //  canvasScaled.toBlob(
      //   (blob) => {
      //     const previewUrl = window.URL.createObjectURL(blob);
      //     const anchor = document.createElement("a");
      //     anchor.download = "cropPreview.png";
      //     anchor.href = URL.createObjectURL(blob);
      //     anchor.click();
      //     console.log(anchor.download)
      //     window.URL.revokeObjectURL(previewUrl);
      //   },
      //   "image/png",
      //   1
      // );
    }
  };

  // console.log(onClickSaveImg)

  // React.useEffect(() =>{
  //   if (onClickSaveImg) {
  //     onClickSave()
  //   }
  // },[onClickSaveImg, onClickSave])

  const handleDrop = (dropped) => {
    // var that = this;
    console.log("New Image")
    setNewImage(true)
    EXIF.getData(dropped[0], function () {
      var orientation = EXIF.getTag(this, "Orientation");
      let rotatePic = 0;
      //   console.log(rotatePic)
      //   console.log(dropped[0])
      switch (orientation) {
        case 8:
          rotatePic = 270;
          break;
        case 6:
          rotatePic = 90;
          break;
        case 3:
          rotatePic = 180;
          break;
        default:
          rotatePic = 0;
      }
      setRotate(rotatePic);
      setImage(dropped[0]);
    });
  };
  React.useEffect(() => {
    if (image) {
      setEditing(true)
    } else {
      setEditing(false)
    }
  }, [image])

  function ImageEditing() {
    if (image) {
      setEditing(true)
    } else {
      setEditing(false)
    }
  }
  // console.log(newImage)
  // console.log(image)
  // console.log(image?.File)
  // console.log('Dropzone disabled', editing == true ? true : false)
  return (
    <>
      <div aria-labelledby="max-width-dialog-title"
        style={{ paddingLeft: 30 }}>
        {/* {image? <Button icon="remove" onClick={() =>
          {setImage(null)
          setNewImage(false)}
          }>Change Pic</Button> : '' } */}
        {/* <P id="max-width-dialog-title">Upload your profile picture</P> */}

        <div className="dialog">
          <P>Click the image or Drag &amp; Drop your picture here</P>

          <Dropzone
            onDrop={handleDrop}
            noKeyboard
            disabled={editing}
            style={{ width: "250px", height: "250px" }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                {/* {console.log(getRootProps())} */}
                <AvatarEditor
                  ref={setEditorRef}
                  image={image ? image : null}
                  width={150}
                  height={150}
                  border={50}
                  onImageChange={() => ImageEditing()}
                  color={[230, 230, 230, 0.7]} // RGBA
                  scale={scale}
                  rotate={rotate}
                  borderRadius={125}
                />

                {<input {...getInputProps()} />}
              </div>
            )}
          </Dropzone>
          {image ? (
            <div>
              {image &&
                <SubTitle onClick={() => {
                  setImage(null)

                  setEditing(false)
                }}>Change Picture</SubTitle>}

              {newImage && <>
                <Box>
                  <Info>Set Zoom</Info>
                  <Slider
                    value={slider}
                    settings={settings}
                    aria-labelledby="continuous-slider"
                    style={{ width: 250, maxWidth: '80%' }}
                  />
                  <Rotate
                    onClick={() => setRotate(rotate + 90)}>
                    Rotate
                  </Rotate>
                </Box>
              </>}



              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Butt onClick={() => onClickSave()} color="grey">
                  Save Picture
                </Butt>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
