import React, { useState } from 'react';
import { Button, Header, Icon, Input, Modal, Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const ResourceModal = ({
    phaseIndex,
    open,
    onClose,
    resources,
    addResource,
    removeResource,
    updateResourceInfo,
  }) => {
    const [newResourceText, setNewResourceText] = useState('');
    const [newResourceUrl, setNewResourceUrl] = useState('');
  
    const handleAddResource = () => {
      addResource(phaseIndex, { text: newResourceText, url: newResourceUrl });
      setNewResourceText('');
      setNewResourceUrl('');
    };
  
    return (
      <Modal open={open} onClose={onClose} size="large">
        <Header icon="linkify" content="Manage Resources" />
        <Modal.Content scrolling>
  
          <Table celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={8}>Resource Text</Table.HeaderCell>
                <Table.HeaderCell width={5}>Resource URL</Table.HeaderCell>
                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {resources.map((resource, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Input
                      value={resource.text}
                      onChange={(e) =>
                        updateResourceInfo(phaseIndex, index, 'text', e.target.value)
                      }
                      style={{ width: '100%' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={resource.url}
                      onChange={(e) =>
                        updateResourceInfo(phaseIndex, index, 'url', e.target.value)
                      }
                      style={{ width: '100%' }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <IconButton
                      icon
                      title="Delete Task"
                      onClick={() => removeResource(phaseIndex, index)}
                    >
                      <Icon name="trash alternate" color="grey" />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell colSpan="4" textAlign="left">
                  <Button icon labelPosition="left" primary onClick={handleAddResource}>
                    <Icon name="plus" />
                    Add Resource
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
  
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Done</Button>
        </Modal.Actions>
      </Modal>
    );
  };
 
const IconButton = styled(Button)`
padding: 8px !important;
background-color: transparent !important;
box-shadow: none !important;
color: #d9534f !important;
`;
