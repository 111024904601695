import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table } from 'semantic-ui-react'
import { format } from 'date-fns'

const EmployeeLogValue = ({ rows }) => {
    const navigate = useNavigate()
    
    return (
        <>
            <Table selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>Record Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Value</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Created</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i} onClick={() => navigate(`/app/employee-record/details/${row?.id}`)} style={{cursor: 'pointer'}}>
                            <Table.Cell>{row?.employee_record_value?.name}</Table.Cell>
                            <Table.Cell>{row?.value}</Table.Cell>
                            <Table.Cell>{row?.created_at && format(new Date(row?.created_at), 'MMM. d, yyyy h:mm a')}</Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeLogValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeLogValue;

