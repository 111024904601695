import {
    GET_ONBOARDINGS,
    DELETE_ONBOARDING,
    ADD_ONBOARDING,
    FETCH_ONBOARDING,
    EDIT_ONBOARDING,
    CLEAR_ONBOARDING,
    CLEAR_ALL,
  } from "common/constants/actions";

  const initialState = {
    onboarding: [],
    pending: false,
  };

  export default function OnBoarding (state = initialState, action) {
    switch (action.type) {
      case GET_ONBOARDINGS:
        return {
          ...state,
          onboarding: action.payload[0],
          pending: false,
        };
      case FETCH_ONBOARDING:
        return { ...state,
          [action.payload.id]: action.payload,
          pending: false };

      case EDIT_ONBOARDING:

        // console.log(...state)
        // console.log(action.payload)

      return {
        ...state,
        onboarding: action.payload,
        pending: false,
      };

      case DELETE_ONBOARDING:
        return {
          ...state,
          onboarding: state.onboarding.filter(
            (row) => row.id !== action.payload.id
          ),
        };
      case ADD_ONBOARDING:
        return {
          ...state,
          onboarding: [...state.onboarding, action.payload],
        };
      case CLEAR_ONBOARDING:
        return {
          ...state,
          onboarding: [],
        };
      case CLEAR_ALL:
        return {
          ...state,
          onboarding: [],
        };
      default:
        return state;
    }
  }
