import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";

import { GPT_EMPLOYEE_STATUS } from "common/data/AdminData";
import {
  //   SG_DELETE_GPT_MODEL_MAIN,
  SG_ADD_GPT_MEMBER,
  SG_EDIT_GPT_MEMBER,
  SG_FETCH_GPT_MEMBER,
  SG_GET_EMPLOYEE_SELECT_LIST,
} from "common/constants/actions";

import { Button, Message, Header } from "semantic-ui-react";

const CreateEdit = ({ gptMemberID, reloadPage, setReloadPage }) => {
  const dispatch = useDispatch();

  const [pristine, setPristine] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [employee, setEmployee] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");

  const { get_gpt_members, get_employee_select_list } = useSelector(
    (state) => ({
      get_gpt_members: state.gpt_members,
      get_employee_select_list: state.employees.employee_select_list,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (gptMemberID) {
      dispatch({
        type: SG_FETCH_GPT_MEMBER,
        payload: { id: gptMemberID },
      });
    }
  }, [dispatch, gptMemberID]);
  // SG_GET_EMPLOYEE_SELECT_LIST
  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_SELECT_LIST,
      payload: "organization=1",
    });
  }, [dispatch]);

  useEffect(() => {
    if (gptMemberID) {
      setEmployeeStatus(get_gpt_members[gptMemberID]?.status);
      setEmployee(get_gpt_members[gptMemberID]?.employee?.id);
    }
  }, [get_gpt_members, gptMemberID]);

  function CreateEdit() {
    if (formErrors.length > 0) {
      setPristine(false);
      return;
    }
    setPristine(true);

    if (gptMemberID) {
      dispatch({
        type: SG_EDIT_GPT_MEMBER,
        payload: {
          id: gptMemberID,
          employee,
          status: employeeStatus,
        },
      });
      setReloadPage(Math.random);
    } else {
      dispatch({
        type: SG_ADD_GPT_MEMBER,
        payload: {
          employee,
          status: employeeStatus,
        },
      });
    }

    // setSortOrder(0);
  }

  useEffect(() => {
    const ErrorList = [];

    if (!employee) {
      ErrorList.push("Enter Employee");
    }
    if (!employeeStatus) {
      ErrorList.push("Select Status");
    }

    setFormErrors(ErrorList);
  }, [pristine, employeeStatus, employee]);

  return (
    <>
      {!gptMemberID && <Header size="medium">Add New Member</Header>}

      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}

      <Container>
        <LeftBox>
          <Select
            name="organization"
            options={get_employee_select_list}
            isClearable
            onChange={(e) => setEmployee(e.id)}
            getOptionLabel={(option) =>
              `${option?.first_name} ${option?.last_name} - ${option?.email}`
            }
            getOptionValue={(option) => `${option?.id}`}
            value={get_employee_select_list.filter((em) => em.id === employee)}
          />
        </LeftBox>
        <LeftBox2>
          <Select
            name="direction"
            options={GPT_EMPLOYEE_STATUS}
            onChange={(e) => setEmployeeStatus(e.value)}
            value={GPT_EMPLOYEE_STATUS.filter(
              (em) => em.value === employeeStatus
            )}
          />
        </LeftBox2>

        <RightBox>
          <Button onClick={CreateEdit}>
            {gptMemberID ? "Update" : "Save"}
          </Button>
        </RightBox>
      </Container>
    </>
  );
};

export default CreateEdit;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  display: flex;
`;

const LeftBox = styled.div`
  flex: 8 1 0;
`;
const LeftBox2 = styled.div`
  flex: 1 1 0;
`;
const RightBox = styled.div`
  flex: 1 1 0;
`;
