import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { Table } from 'semantic-ui-react'

import { format } from 'date-fns'


const RowCard = ({ rows }) => {
    const navigate = useNavigate()

    return (
        <>
            <Table selectable>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Created On</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Last Updated</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Id</Table.HeaderCell>                 
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {

                        return (<Table.Row key={i} style={{cursor: "pointer"}} 
                            onClick={() => navigate(`/app/report-templates/details/${row?.id}`)}>
                            <Table.Cell>{row?.name}</Table.Cell>
                            <Table.Cell>{row?.status_text}</Table.Cell>
                            <Table.Cell>
                                {row?.created_at && format(new Date(row?.created_at), 'MMM. d, yyyy h:mm a')}
                            </Table.Cell>
                            <Table.Cell>
                                {row?.created_at && format(new Date(row?.updated_at), 'MMM. d, yyyy h:mm a')}
                            </Table.Cell>
                            <Table.Cell>{row?.id}</Table.Cell>
                           
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

RowCard.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default RowCard;



const TableCell = styled.td`
    background-color: ${props => props.noResponse ? '#A8D5B7' : '#F0A8A8'}; // muted green and muted red
`;