import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  GET_LEADERSHIP_DATA,
  SG_GET_LEADERSHIP_DATA,
  FETCH_LEADERSHIP_DATA,
  SG_FETCH_LEADERSHIP_DATA,
  GET_LEADERSHIP_DATA_ORG,
  SG_GET_LEADERSHIP_DATA_ORG,
  ALL_ERRORS,
} from "common/constants/actions";

import * as api from "common/constants/api";

function* getCultureData(action) {
  try {
    const json = yield call(api.GET_LEADERSHIP_REPORTS, action.payload);
    yield put({ type: GET_LEADERSHIP_DATA, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadCultureData() {
  yield takeLatest(SG_GET_LEADERSHIP_DATA, getCultureData);
}

function* fetchCultureData(action) {
  try {
    const json = yield call(api.FETCH_LEADERSHIP_REPORT, action.payload);
    yield put({ type: FETCH_LEADERSHIP_DATA, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* cultureDataFetch() {
  yield takeLatest(SG_FETCH_LEADERSHIP_DATA, fetchCultureData);
}

function* getLeadershipOrg(action) {
  try {
    const json = yield call(api.GET_LEADERSHIP_REPORTS, action.payload);
    yield put({ type: GET_LEADERSHIP_DATA_ORG, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadLeadershipOrg() {
  yield takeLatest(SG_GET_LEADERSHIP_DATA_ORG, getLeadershipOrg);
}

export default function* index() {
  yield all([loadCultureData(),
    cultureDataFetch(),
    loadLeadershipOrg()]);
}
