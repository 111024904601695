import {
  PENDING_SURVEY_STRUCTURE,
  GET_SURVEY_STRUCTURES,
  UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER,
  COPY_SURVEY_STRUCTURE,
  SURVEY_STRUCTURE_SELECT,
  DELETE_SURVEY_STRUCTURE,
  FETCH_SURVEY_STRUCTURE,
  EDIT_SURVEY_STRUCTURE,
  CSV_FILE_SURVEY_STRUCTURE,
  ADD_SURVEY_STRUCTURE,
  CLEAR_SURVEY_STRUCTURE,
  SEARCH_SURVEY_STRUCTURE,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  survey_structure: [],
  pageNumber: 1,
  csv_file: null,
  survey_struture_select: [],
  survey_structure_copied: false,
  pending: false,
  search: "",
};

export default function FileUpload(state = initialState, action) {
  switch (action.type) {
    case PENDING_SURVEY_STRUCTURE:
      return {
        ...state,
        pending: true,
      }
    case SEARCH_SURVEY_STRUCTURE:
      return {
        ...state,
        search: action.payload,
      }
    case GET_SURVEY_STRUCTURES:
      return {
        ...state,
        survey_structure: action.payload,
        pending: false,
        survey_structure_copied: false
      };
    case UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case COPY_SURVEY_STRUCTURE:
      return {
        ...state,
        survey_structure_copied: action.payload,
        pending: false,
      };
    case SURVEY_STRUCTURE_SELECT:
        return {
          ...state,
          survey_struture_select: action.payload,
          pending: false,
        };
    case FETCH_SURVEY_STRUCTURE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };
    case EDIT_SURVEY_STRUCTURE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        survey_structure_copied: false,
        pending: false
      };
    case DELETE_SURVEY_STRUCTURE:
      return {
        ...state,
        survey_structure: state.survey_structure.filter(
          (row) => row.id !== action.payload.id
        ),
        pending: false,
      };
    case ADD_SURVEY_STRUCTURE:
      return {
        ...state,
        created: action.payload,
        [action.payload.id]: action.payload,
        survey_structure_copied: false,
        pending: false,
        // survey_structure: [...state.survey_structure, action.payload],
      };
    case CSV_FILE_SURVEY_STRUCTURE:
      return {
        ...state,
        csv_file: action.payload,
        pending: false,
      };
    case CLEAR_SURVEY_STRUCTURE:
      return {
        ...state,
          ...initialState
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}
