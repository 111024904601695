import React from "react";
import styled from "styled-components";
import Chart from 'assets/images/empty/graph.jpg'

const Container = styled.div`
    border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
    background-color: #ffffff;
    width: 100%;
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;

    @media (max-width: 768px) {
        margin-top: 20px;
        padding: 20px;
    }
`
const Title = styled.div`
    font-size: 28px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1;
`
const Text = styled.div`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #4a4a4a;
    font-family: "Red Hat Display", sans-serif;
    max-width: 538px;
    margin-bottom: 20px;
    line-height: 1.3;
`


const IMG = styled.img`
    width: 80%;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const ValidateEmail = () => {

    return (
        <Container>
            <Title>Please validate your email</Title>
            <Text>Please check your inbox to validate your email. An email from us should arrive momentarily.!</Text>
            <IMG src={Chart}/>
        </Container>
    )
}


export default ValidateEmail
