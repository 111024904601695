import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import Blank from "assets/images/empty/user.svg"

import Settings from "assets/images/nav/settings.svg";

const TopBar = () => {
	const [employee, setEmployee] = useState(false);

	const [selectedOrg, setSelectedOrg] = useState(false);
	const [pageTitle, setPageTitle] = useState(false);

	const { get_employee, get_auth, get_organizations, get_SelectedOrg } = useSelector(
			(state) => ({
				get_employee: state.employees,
				get_auth: state.auth,
				get_organizations: state.organizations,
				get_organization: state.organization,
				get_SelectedOrg: state.selectedOrg,
			}),
			shallowEqual
		);


	useEffect(() => {
		if (get_employee) {
			setEmployee(get_employee[get_auth?.employee_id]);
		}

		if (get_auth?.token) {
			setSelectedOrg(get_SelectedOrg)
			setPageTitle(get_auth?.page_title)
		}
	}, [get_employee, get_auth, get_organizations, get_SelectedOrg]);

	return (
		<Container>
			<div>
				<OrgHeader>
					{pageTitle || selectedOrg?.organization?.name}
				</OrgHeader>
			</div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{employee ?
					<EmployeeContainer>
						<Text>
							<Name>{employee.first_name + " " + employee.last_name} </Name>
						</Text>

						<Avatar
							src={employee?.picture ? employee.picture : Blank}
						/>
					</EmployeeContainer>
					: ""}
				<Line />

				<Link to="/settings/member">
					<Popup content='Profile Settings' trigger={<Image src={Settings} />} />
				</Link>

				<Link to="/account/logout">
					<Popup content='Log Out' trigger={<Icon name="log out" />} />
				</Link>
			</div>

		</Container>
	)
}

export default TopBar

const Container = styled.div`
	width:100%;
	position:fixed;
	top:0px;
	height:50px;
	border-bottom:1px solid  #e9e9e9;
	z-index: 998;
	background-color: white;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding-right:20px;
	padding-left:55px;
	font-size:20px;

	a{
		color:#a5a5a5;
	}
`

const Avatar = styled.img`
	height:40px;
	width:40px;
	border-radius:20px;
	
`
const Text = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-right:15px;
`

const Name = styled.div`
	font-size:14px;
	font-weight:bold;
	margin-bottom:-5px;
`

const EmployeeContainer = styled.div`
	display:flex;
	align-items:center;
	margin-right:20px;
`

const Line = styled.div`
	height:100%;
	width:1px;
	background-color:#a5a5a5;
	margin-right:20px;
`

const Image = styled.img`
	height:20px;
	margin-right:20px;
`

const OrgHeader = styled.p`
  padding: 1rem;
  font-size: 26px;
  font-weight: 900;
  padding: 15px 10px 15px 40px;
`;