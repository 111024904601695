import {
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTION_SELECT,
  GET_ALL_SURVEY_QUESTION_SELECT,
  UPDATE_SURVEY_QUESTION_PAGE_NUMBER,
  FETCH_SURVEY_QUESTION,
  EDIT_SURVEY_QUESTION,
  ADD_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  CLEAR_SURVEY_QUESTION,
  CLEAR_ALL,
  // ALL_ERRORS,
} from "common/constants/actions";

const initialState = {
  survey_question: [],
  survey_question_select: [],
  survey_question_all_select: [],
  created: false,
  deleted: false,
  pageNumber: 1,
  pending: false,
};

export default function SurveyQuestion (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS:
      return {
        ...state,
        survey_question: action.payload,
        pending: false,
        created: false,
        deleted: false,
      };
    case GET_SURVEY_QUESTION_SELECT:
      return {
        ...state,
        survey_question_select: action.payload,
        pending: false,
        created: false,
        deleted: false,
      };
    case GET_ALL_SURVEY_QUESTION_SELECT:
      return {
        ...state,
        survey_question_all_select: action.payload,
        pending: false,
        created: false,
        deleted: false,
      };
    case UPDATE_SURVEY_QUESTION_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      }
      case FETCH_SURVEY_QUESTION:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          created: false,
          deleted: false,
        };
      case EDIT_SURVEY_QUESTION:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          created: false,
          deleted: false,
        };
      case ADD_SURVEY_QUESTION:
        return {
          ...state,
          created: action.payload,
          deleted: false,
        };
    case DELETE_SURVEY_QUESTION:
      return {
        ...state,
        created: false,
        deleted: action.payload
      };

    case CLEAR_SURVEY_QUESTION:
      return {
        ...state,
        survey_question: [],
        survey_question_select: [],
        created: false,
        deleted: false,
        pageNumber: 1,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        survey_question: [],
        survey_question_select: [],
        created: false,
        deleted: false,
        pageNumber: 1,
        pending: false,
      };
    default:
      return state;
  }
}
