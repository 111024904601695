import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SG_GET_EMAIL_TEMPLATES,} from "common/constants/actions";
import Select from "react-select";
import {Button, Header, Icon, Label, List} from 'semantic-ui-react'
import {format} from 'date-fns'
import {RowObjectInputEdit} from "areas/email-templates/components/Components";
import {EMAIL_DEFINITIONS, SUB_DOMAIN_NAMES} from "common/data/AdminData"

const EmailTemplate = ({ organizationId }) => {
    const dispatch = useDispatch();
    const [templateDetails, setTemplateDetails] = useState(null);
    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [selectDef, setSelectDef] = useState(false)
    const [emailRef, setEmailRef] = useState({})
    const [selectedObject, setSelectedObject] = useState({})
    const [defaultTemplate, setDefaultTemplate] = useState(false)

    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_EMAIL_TEMPLATES,
                payload: `organization=${organizationId}`,
            });
        }
    }, [dispatch, organizationId]);

    function GetDefaultTemplates() {
        setDefaultTemplate(true)
        dispatch({
            type: SG_GET_EMAIL_TEMPLATES,
            payload: `default=yes&domain=${organizationDetails?.sub_domain}`,
        });
    }
    // domain=organization.sub_domain

    const { get_email_template,
        get_organization } = useSelector(
            (state) => ({
                get_email_template: state.emailTemplate,
                get_organization: state.organizations,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationDetails(get_organization[organizationId])
    }, [get_organization, organizationId]);

    useEffect(() => {
        setTemplateDetails(get_email_template?.email_templates?.results?.[0])
    }, [get_email_template]);

    useEffect(() => {
        setEmailRef(templateDetails?.email_ref)
        setSelectedObject({})
        if (selectDef?.value) {
            setEmailRef(templateDetails?.email_ref[`${selectDef.value}`])
            setSelectedObject(templateDetails?.email_ref[`${selectDef.value}`])
        }
    }, [templateDetails, selectDef]);

    return (
        <>
            <Header as='h2'>
                Details of Email Template used {templateDetails?.name}
            </Header>
            {!defaultTemplate &&
                <Button onClick={() => GetDefaultTemplates()}>Fetch Default Template</Button>}
            <SectionDiv>
                <Container>
                    <List horizontal>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Domain</List.Header>
                                    {templateDetails?.domain && SUB_DOMAIN_NAMES.filter(sy => sy.value === templateDetails?.domain)?.[0]?.label}
                                </ListContainer>
                            </List.Content>
                        </List.Item>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Name</List.Header>
                                    {templateDetails?.name}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Template</List.Header>
                                    {defaultTemplate? "Default" : "Custom"}
                                </ListContainer>
                            </List.Content>
                        </List.Item>



                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Created</List.Header>
                                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Last Updated</List.Header>
                                    {templateDetails?.updated_at && format(new Date(templateDetails?.updated_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                    </List>
                </Container>
            </SectionDiv>
            <SectionDiv>
                <SingleRowContent>
                    <SectionCell>
                        <Header as='h3'>Template List</Header>
                    </SectionCell>
                    <SectionCell>
                        {!selectDef?.value ?
                            <Select
                                name="def_list"
                                options={EMAIL_DEFINITIONS}
                                isClearable
                                onChange={setSelectDef}
                                value={selectDef}
                            />
                            :
                            <Label>
                                {selectDef?.value} {" "}
                                <Icon
                                    style={{
                                        cursor: "pointer",
                                        marginTop: "5px"
                                    }}
                                    onClick={() => setSelectDef(false)}
                                    name='close' />
                            </Label>
                        }
                    </SectionCell>
                </SingleRowContent>

            </SectionDiv>
            <SectionDiv>
                {selectDef?.value ?
                    <RowObjectInputEdit
                        selectedObject={selectedObject}
                        setSelectedObject={setSelectedObject}

                    />
                    :
                    <pre>{JSON.stringify(emailRef, null, 3)}</pre>
                }


            </SectionDiv>


        </>
    );
};

export default EmailTemplate;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`
