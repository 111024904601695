import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
    GET_DEPARTMENTS,
    LOAD_DEPARTMENTS,
    DELETE_DEPARTMENT,
    REMOVE_DEPARTMENT,
    PULL_DEPARTMENT,
    FETCH_DEPARTMENT,
    UPDATE_DEPARTMENT,
    EDIT_DEPARTMENT,
    SAVE_DEPARTMENT,
    ADD_DEPARTMENT,
    CLEAR_DEPARTMENT,
  ALL_ERRORS,
} from "common/constants/actions";



function* loaddeapartments(action) {
//   yield put({ type: GET_deapartmentS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_DEPARTMENTS, action.payload);
    yield put({ type: GET_DEPARTMENTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deapartmentLoad() {
  yield takeLatest(LOAD_DEPARTMENTS, loaddeapartments);
}

function* fetchdeapartments(action) {
  try {
    const json = yield call(api.FETCH_DEPARTMENT, action.payload);
    yield put({ type: FETCH_DEPARTMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deapartmentFetch() {
  yield takeLatest(PULL_DEPARTMENT, fetchdeapartments);
}

function* updatedeapartments(action) {
  try {
    const json = yield call(api.EDIT_DEPARTMENT, action.payload);
    yield put({ type: EDIT_DEPARTMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}



export function* deapartmentUpdate() {
  yield takeLatest(UPDATE_DEPARTMENT, updatedeapartments);
}

function* adddeapartments(action) {
  try {
    const json = yield call(api.ADD_DEPARTMENT, action.payload);
    yield put({ type: ADD_DEPARTMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deapartmentAdd() {
  yield takeLatest(SAVE_DEPARTMENT, adddeapartments);
}

function* deletedeapartments(action) {
  try {
    yield call(api.DELETE_DEPARTMENT, action.payload);
    yield put({ type: DELETE_DEPARTMENT, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deapartmentDelete() {
  yield takeLatest(REMOVE_DEPARTMENT, deletedeapartments);
}


export default function* index() {
  yield all([
    deapartmentLoad(),
    deapartmentFetch(),
    deapartmentUpdate(),
    deapartmentAdd(),
    deapartmentDelete(),

  ]);
}
