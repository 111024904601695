import {
  UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER,
  GET_GPT_RESPONSE_MAIN_PENDING,
  GET_GPT_RESPONSE_MAINS,
  GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
  BRIEF_AI_SUMMARY,
  DELETE_GPT_RESPONSE_MAIN,
  FETCH_GPT_RESPONSE_MAIN,
  EDIT_GPT_RESPONSE_MAIN,
  ADD_GPT_RESPONSE_MAIN,
  CLEAR_GPT_RESPONSE_MAIN,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  gpt_response_main: [],
  gpt_response_main_bulk: [],
  gpt_response_main_debrief_schedule: [],
  created: [],
  updated: [],
  deleted: [],
  pending: false,
  pageNumber: 1,
};

export default function GptResponseMain(state = initialState, action) {
  switch (action.type) {
    case GET_GPT_RESPONSE_MAIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_GPT_RESPONSE_MAINS:
      return {
        ...state,
        gpt_response_main: action.payload,
        pending: false,
      };
    case GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE:
      const updatedDebriefSchedule = state.gpt_response_main_debrief_schedule
     
      updatedDebriefSchedule[action.payload.brief_scheduled_id] =
        action.payload.data;
      return {
        ...state,
        gpt_response_main_debrief_schedule: updatedDebriefSchedule,
        pending: false,
      };

    case BRIEF_AI_SUMMARY:
      return {
        ...state,
        gpt_response_main: action.payload,
        gpt_response_main_bulk: action.payload,
        pending: false,
      };
    case FETCH_GPT_RESPONSE_MAIN:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_GPT_RESPONSE_MAIN:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };

    case DELETE_GPT_RESPONSE_MAIN:
      return {
        ...state,
        // gpt_response_main: state.gpt_response_main.filter((row) => row.id !== action.payload),
        deleted: action.payload,
        pending: false,
      };

    case ADD_GPT_RESPONSE_MAIN:
      return {
        ...state,
        // gpt_response_main: [...state.gpt_response_main, action.payload],
        created: action.payload,
        pending: false,
      };

    case CLEAR_GPT_RESPONSE_MAIN:
      return {
        ...state,
        gpt_response_main: [],
        created: [],
        updated: [],
        deleted: [],
        pending: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        gpt_response_main: [],
        created: [],
        updated: [],
        deleted: [],
        pending: false,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
