import {
  GET_TEAM_TYPES,
  DELETE_TEAM_TYPE,
  FETCH_TEAM_TYPE,
  EDIT_TEAM_TYPE,
  ADD_TEAM_TYPE,
  CLEAR_TEAM_TYPE,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  team_types: [],
  pending: false,
};

export default function TeamTypes(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_TYPES:
      return {
        ...state,
        team_types: action.payload,
        pending: false,
      };
    case FETCH_TEAM_TYPE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };

    case EDIT_TEAM_TYPE:
      if (state.team_types.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          team_types: state.team_types.filter(
            (rw) => rw.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        team_types: [...state.team_types, action.payload],
      };

    case DELETE_TEAM_TYPE:
      return {
        ...state,
        team_types: state.team_types.filter(
          (row) => row.id !== action.payload.id
        ),
      };
    case ADD_TEAM_TYPE:
      return {
        ...state,
        team_types: [...state.team_types, action.payload],
      };
    case CLEAR_TEAM_TYPE:
      return {
        ...state,
        team_types: [],
      };
    case CLEAR_ALL:
      return {
        ...state,
        team_types: [],
      };
    default:
      return state;
  }
}
