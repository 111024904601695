import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowSurvey from "./RowSurvey";


import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
    SG_GET_GPT_SUMMARY,
  UPDATE_GPT_SUMMARY_PAGE_NUMBER,
} from "common/constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Icon,
} from "semantic-ui-react";

const List = ({ noPagination = false, selectBriefSchedule, AddToSelectedSurveyList }) => {
  const dispatch = useDispatch();

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);


  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_GPT_SUMMARY,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm]);

  const { get_gpt_summary } = useSelector(
    (state) => ({
      get_gpt_summary: state.gpt_summary,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_summary?.gpt_summary?.results);
    setPage(get_gpt_summary?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_summary?.gpt_summary?.count /
          get_gpt_summary?.gpt_summary?.page_size
      )
    );
    if (
      get_gpt_summary?.gpt_summary?.count %
      get_gpt_summary?.gpt_summary?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_summary?.gpt_summary?.count /
            get_gpt_summary?.gpt_summary?.page_size
        ) + 1
      );
    }
  }, [get_gpt_summary, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_SUMMARY_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

//   console.log(get_gpt_summary);
//   console.log(results);

  return (
    <Container>
     <SectionDiv>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </SectionDiv>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => <RowSurvey
            row={result}
            key={result?.id}
            selectBriefSchedule={selectBriefSchedule}
            AddToSelectedSurveyList={AddToSelectedSurveyList} />)}
        </Card.Group>
      </Segment>

      {!noPagination && (
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      )}

    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
