import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {useParams} from "react-router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SG_FETCH_GPT_MODEL_MAIN} from "common/constants/actions";

import {Header, Icon} from "semantic-ui-react";

import BasicErrorMessage from "common/utils/ErrorMessages";
import ListOfSurveys from "./response-main/SurveyList";
import ListOfResponse from "./response-main/List";

const GptPromptBuild = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [reloadPage, setReloadPage] = useState(false);
  const [modelId, setModelId] = useState(id);


  const [selectBriefSchedule, setSelectBriefSchedule] = useState([]);

  const [gptMain, setGptMain] = useState();
  const [editModelMain, setEditModelMain] = useState(false);

  const [showSurveyList, setShowSurveyList] = useState(true);

  useEffect(() => {
    setModelId(id);
  }, [id]);

  useEffect(() => {
    // if the response is ok 200
    if (modelId) {
      dispatch({
        type: SG_FETCH_GPT_MODEL_MAIN,
        payload: { id: modelId },
      });
    }
  }, [dispatch, modelId]);

  const { get_gpt_model_main } = useSelector(
    (state) => ({
      get_gpt_model_main: state.gpt_model_main,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (modelId) {
      setGptMain(get_gpt_model_main[modelId]);
      return;
    }
    if (get_gpt_model_main?.created?.id) {
      setGptMain(get_gpt_model_main?.created);
      return;
    }
  }, [get_gpt_model_main, modelId]);

  function ResetCategoryList() {
    setEditModelMain(!editModelMain);
  }

  // console.log(selectBriefSchedule)
  function AddToSelectedSurveyList(newId) {
    const _selectedList = selectBriefSchedule;

    if (_selectedList.includes(newId)) {
      const _selectedList = selectBriefSchedule.filter(
        (item) => item !== newId
      );
      setSelectBriefSchedule(_selectedList);
    } else {
      _selectedList.push(newId);
      let uniqueItems = [...new Set(_selectedList)];
      setSelectBriefSchedule(uniqueItems);
    }
  }

  console.log(selectBriefSchedule);

  return (
    <>
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>


      <Header as="h3" block>
        Step 1 - Select a Survey
      </Header>
      {/* select the survey to create base for  */}
      <SectionDiv>

          <ContainerBox>
            <RightAlign>
              <ContainerFieldSelectable
                onClick={() => setShowSurveyList(!showSurveyList)}
              >
                {showSurveyList ? "Hide" : "Show"} Survey <Icon name="close" />
              </ContainerFieldSelectable>
            </RightAlign>

            <SectionHeader>
              Selected Survey's # <span>{selectBriefSchedule?.length || 0} Selected</span>
            </SectionHeader>


            {showSurveyList && (
              <ListOfSurveys
                selectBriefSchedule={selectBriefSchedule}
                AddToSelectedSurveyList={AddToSelectedSurveyList}
              />
            )}

            {/* {selectBriefSchedule?.id && ( */}
              <ListOfResponse
                selectBriefSchedule={selectBriefSchedule}
                modelID={gptMain?.id}
                setReloadPage={setReloadPage}
                reloadPage={reloadPage}
                modelMainID={gptMain?.id}
              />
            {/* )} */}
          </ContainerBox>

      </SectionDiv>
      <SectionDiv lastSection={true}></SectionDiv>
    </>
  );
};

export default GptPromptBuild;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SpacedOutAlign = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: space-between;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SectionSubHeader = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const SectionHeader = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

const SectionExplain = styled.span`
  font-weight: 400;
  font-size: 12px;
`;
