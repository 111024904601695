import {
    UPDATE_GPT_RESPONSE_SCORE_PAGE_NUMBER,
    GET_GPT_RESPONSE_SCORE_PENDING,
    GET_GPT_RESPONSE_SCORES,
    DELETE_GPT_RESPONSE_SCORE,
    FETCH_GPT_RESPONSE_SCORE,
    EDIT_GPT_RESPONSE_SCORE,
    ADD_GPT_RESPONSE_SCORE,
    CLEAR_GPT_RESPONSE_SCORE,
    CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
    gpt_response_score: [],
    created: [],
    updated: [],
    deleted: [],
    pending: false,
    pageNumber: 1,
  };
  
  export default function GptResponseScore (state = initialState, action) {
    switch (action.type) {
        case GET_GPT_RESPONSE_SCORE_PENDING:
            return {
                ...state,
                pending: true,
              };
        case UPDATE_GPT_RESPONSE_SCORE_PAGE_NUMBER:
                return {
                  ...state,
                  pageNumber: action.payload
                }
        case GET_GPT_RESPONSE_SCORES:
            return {
            ...state,
            gpt_response_score: action.payload,
            pending: false,
            };
        
        case FETCH_GPT_RESPONSE_SCORE:
            return { ...state, 
                [action.payload.id]: action.payload, 
                pending: false };
    
        case EDIT_GPT_RESPONSE_SCORE:
            return { ...state, 
                [action.payload.id]: action.payload,
                updated: action.payload,
                pending: false};
    
        case DELETE_GPT_RESPONSE_SCORE:
            return {
            ...state,
            // gpt_response_score: state.gpt_response_score.filter((row) => row.id !== action.payload),
            deleted: action.payload,
            pending: false
            };
    
        case ADD_GPT_RESPONSE_SCORE:
            return {
            ...state,
            // gpt_response_score: [...state.gpt_response_score, action.payload],
            created: action.payload,
            pending: false
            };
    
        case CLEAR_GPT_RESPONSE_SCORE:
            return {
            ...state,
            gpt_response_score: [],
            created: [],
            updated: [],
            deleted: [],
            pending: false,
            pageNumber: 1,
            };
    
        case CLEAR_ALL:
            return {
            ...state,
            gpt_response_score: [],
            created: [],
            updated: [],
            deleted: [],
            pending: false,
            pageNumber: 1,
            };
    
        default:
            return state;
    }
  }
