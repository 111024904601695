import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";


import {
  CHAT_GPT_PENDING,
  POST_CHAT_GPT,
  SG_POST_CHAT_GPT,
  GET_CHAT_GPT,
  SG_GET_CHAT_GPT,
  ALL_ERRORS,
} from "common/constants/actions";


function* loadmethods(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.POST_CHAT_GPT, action.payload);
    yield put({ type: POST_CHAT_GPT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_POST_CHAT_GPT, loadmethods);
}

function* getmethods(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_CHAT_GPT, action.payload);
    yield put({ type: GET_CHAT_GPT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodGet() {
  yield takeLatest(SG_GET_CHAT_GPT, getmethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodGet(),
  ]);
}
