import {
  CHAT_GPT_PENDING,
  POST_CHAT_GPT,
  GET_CHAT_GPT,
  CLEAR_CHAT_GPT,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  chat_gpt: [],
  updated: [],
  pending: false,
};

export default function ChatGpt(state = initialState, action) {
  switch (action.type) {
    case CHAT_GPT_PENDING:
      return {
        ...state,
        pending: true,
        updated: [],
      };

    case POST_CHAT_GPT:
      return {
        ...state,
        chat_gpt: action.payload,
        updated: action.payload,
        pending: false
      };
    case GET_CHAT_GPT:
      return {
        ...state,
        chat_gpt: action.payload,
        updated: [],
        pending: false
      };

    case CLEAR_CHAT_GPT:
      return {
        ...state,
        updated: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        chat_gpt: [],
      };

    default:
      return state;
  }
}
