import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  DEBRIEF_SCHEDULE_PENDING,
  GET_DEBRIEF_SCHEDULES,
  SG_GET_DEBRIEF_SCHEDULES,
  GET_DEBRIEF_SCHEDULES_SELECT_LIST,
  SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST,
  POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
  SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
  DELETE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE,
  SG_FETCH_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE_SUMMARY,
  SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY,
  EDIT_DEBRIEF_SCHEDULE,
  SG_EDIT_DEBRIEF_SCHEDULE,
  ADD_NEW_DEBRIEF_SCHEDULE,
  RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_ADD_DEBRIEF_SCHEDULE,
  GENERATE_DEBRIEF_TOKEN,
  SG_GENERATE_DEBRIEF_TOKEN,
  SEND_DEBRIEF_TOKEN_EMAIL,
  SG_SEND_DEBRIEF_TOKEN_EMAIL,
  MERGE_DEBRIEF_SCHEDULE,
  SG_MERGE_DEBRIEF_SCHEDULE,
  POST_GOOGLE_DEMO_SHEET,
  SG_POST_GOOGLE_DEMO_SHEET,
  ALL_ERRORS,
} from "common/constants/actions";

function* loadmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.GET_DEBRIEF_SCHEDULES, action.payload);
    yield put({ type: GET_DEBRIEF_SCHEDULES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_DEBRIEF_SCHEDULES, loadmethods);
}

function* loadSelectmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(
      api.GET_DEBRIEF_SCHEDULES_SELECT_LIST,
      action.payload
    );
    yield put({ type: GET_DEBRIEF_SCHEDULES_SELECT_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodSelectLoad() {
  yield takeEvery(SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST, loadSelectmethods);
}

function* loadExcelSummary(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(
      api.POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
      action.payload
    );
    yield put({ type: POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodExcelSummaryLoad() {
  yield takeEvery(SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY, loadExcelSummary);
}

function* generateDebriefTokens(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.GENERATE_DEBRIEF_TOKEN, action.payload);
    yield put({ type: GENERATE_DEBRIEF_TOKEN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* debriefTokenGenerate() {
  yield takeEvery(SG_GENERATE_DEBRIEF_TOKEN, generateDebriefTokens);
}

function* sendDebriefEmail(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.SEND_DEBRIEF_TOKEN_EMAIL, action.payload);
    yield put({ type: SEND_DEBRIEF_TOKEN_EMAIL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* debriefTokenSendEmail() {
  yield takeEvery(SG_SEND_DEBRIEF_TOKEN_EMAIL, sendDebriefEmail);
}

function* fetchmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.FETCH_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: FETCH_DEBRIEF_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_SCHEDULE, fetchmethods);
}

function* fetchSummarymethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.FETCH_DEBRIEF_SCHEDULE_SUMMARY, action.payload);
    yield put({ type: FETCH_DEBRIEF_SCHEDULE_SUMMARY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodSummaryFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY, fetchSummarymethods);
}

function* updatemethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.EDIT_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: EDIT_DEBRIEF_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_DEBRIEF_SCHEDULE, updatemethods);
}

function* addmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  // RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE
  yield put({ type: RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE });
  try {
    const json = yield call(api.ADD_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: ADD_NEW_DEBRIEF_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_DEBRIEF_SCHEDULE, addmethods);
}

function* deletemethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    yield call(api.DELETE_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: DELETE_DEBRIEF_SCHEDULE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_SCHEDULE, deletemethods);
}

function* mergeMethods(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  try {
    const json = yield call(api.MERGE_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: MERGE_DEBRIEF_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: MERGE_DEBRIEF_SCHEDULE, payload: e.response.data });
  }
}

export function* methodMerge() {
  yield takeEvery(SG_MERGE_DEBRIEF_SCHEDULE, mergeMethods);
}

function* demoGsheet(action) {
  yield put({ type: DEBRIEF_SCHEDULE_PENDING });
  // RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE
  try {
    const json = yield call(api.POST_GOOGLE_DEMO_SHEET, action.payload);
    yield put({ type: POST_GOOGLE_DEMO_SHEET, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDemoGsheet() {
  yield takeLatest(SG_POST_GOOGLE_DEMO_SHEET, demoGsheet);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodSelectLoad(),
    debriefTokenGenerate(),
    debriefTokenSendEmail(),
    methodSummaryFetch(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
    methodMerge(),
    methodDemoGsheet(),
    methodExcelSummaryLoad(),
  ]);
}
