import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import List from "./List.js";
import Details from "./Details.js";
import Create from "./Create.js";

//const URL = 'report-templates'
function CategoryStructure() {
  const [newResponse, setNewResponse] = useState(0)

  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details newResponse={newResponse} setNewResponse={setNewResponse} />} />
        <Route path="create" element={<Create newResponse={newResponse} setNewResponse={setNewResponse} />} />
      </Routes>
    </>
  );
}

export default CategoryStructure;

