import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Input from "./InputLogin";
import { REQUEST_PASSWORD_RESET, CLEAR_PASSWORD_DATA } from "common/constants/actions";
import Logo from "assets/images/nav/nuwave_black.png";
import NuWave from "assets/images/nav/nuwave_black.png";

import UserErrorMessages from "./UserErrorMessages";

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;
const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
const IMG = styled.img`
  height: 75px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  width: 100%;
`;


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
  }
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;

const email_validation =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { handleSubmit, control, errors } = useForm();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [domain] = useState(window.location.hostname);

  const { get_error } = useSelector(
    (state) => ({
      get_error: state.errors,
    }),
    shallowEqual
  );

  const onSubmit = (data) => {
    dispatch({
      type: REQUEST_PASSWORD_RESET,
      payload: { ...data },
    });
  };

  React.useEffect(() => {
    if (get_error?.message) {
      setErrorMessage(get_error?.message);
    }
    if (get_error?.message?.status === "OK") {
      navigate("/account/password-message");
      dispatch({
        type: CLEAR_PASSWORD_DATA,
      });
    }
  }, [get_error, navigate, dispatch]);

  React.useEffect(() => {
    if (errorMessage) {
      dispatch({
        type: CLEAR_PASSWORD_DATA,
      });
    }
  }, [dispatch, errorMessage]);

  // console.log(errorMessage);
  // console.log(errorMessage.status);
  // console.log(errorMessage.status === "OK");

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Banner>
            <IMG src={domain === "nu.innerlogic.com" ? NuWave : Logo} />
          </Banner>
          <UserErrorMessages />
          {/* {get_error?.errors?.data && (
                <Label basic color="red" pointing="below">
                  {/* {JSON.stringify(get_error?.errors)} */}
          {/* {JSON.stringify(get_error?.errors?.data)}
                </Label> */}
          {/* )} */}
          {errorMessage && (
            <Message
              error
              header={errorMessage.header}
              content={errorMessage.message}
            />
          )}
          <Title>Reset your password</Title>
          <Description>
            Please enter the email you use to login and we'll send instructions
            on how to reset your password to that email
          </Description>
          <Controller
            as={Input}
            name="email"
            placeholder="Email"
            type="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Please enter and email",
              pattern: {
                value: email_validation,
                message: "Please enter a valid email address",
              },
            }}
            error={errors.email ? errors.email.message : ""}
          />

          <Button type="submit">Reset Password</Button>

          <Bottom>
            <div>
              <Link to="/account/login">Login</Link>
            </div>
          </Bottom>
        </Box>
      </form>
    </Container>
  );
};

export default Login;
