import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Select from "react-select";

import { AI_MODEL_STATUS } from "common/data/AdminData";

import {
  //   SG_DELETE_GPT_MODEL_MAIN,
  SG_ADD_GPT_MODEL_MAIN,
  SG_EDIT_GPT_MODEL_MAIN,
  SG_FETCH_GPT_MODEL_MAIN,
} from "common/constants/actions";

import { Button, Input, Message, Header } from "semantic-ui-react";


const CreateEdit = ({ modelID, setReloadPage, setEditModelMain }) => {
  const dispatch = useDispatch();

  const [modelName, setModelName] = useState("");
  const [modelVersion, setModelVersion] = useState("");
  const [modelUrl, setModelUrl] = useState("");
  const [modelSortOrder, setModelSortOrder] = useState();

  const [pristine, setPristine] = useState(true);
  const [formErrors, setFormErrors] = useState([]);

  const { get_gpt_model_main } = useSelector(
    (state) => ({
      get_gpt_model_main: state.gpt_model_main,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (modelID) {
      dispatch({
        type: SG_FETCH_GPT_MODEL_MAIN,
        payload: { id: modelID },
      });
    }
  }, [dispatch, modelID]);

  useEffect(() => {
    if (modelID) {
      setModelVersion(get_gpt_model_main[modelID]?.status);
      setModelName(get_gpt_model_main[modelID]?.name);
      setModelUrl(get_gpt_model_main[modelID]?.url);
      setModelSortOrder(get_gpt_model_main[modelID]?.sort_order);
    } else {
      setModelName("")
      setModelVersion("")
    }
  }, [get_gpt_model_main, modelID]);

  function CreateEdit() {
    if (formErrors.length > 0) {
      setPristine(false);
      return;
    }
    setPristine(true);

    if (modelID) {
      dispatch({
        type: SG_EDIT_GPT_MODEL_MAIN,
        payload: {
          id: modelID,
          name: modelName,
          status: modelVersion,
          url: modelUrl,
          sort_order: modelSortOrder
        },
      });
      setEditModelMain(false);
    } else {
      dispatch({
        type: SG_ADD_GPT_MODEL_MAIN,
        payload: {
          name: modelName,
          status: modelVersion },
      });
    }
    setModelName("");
    setReloadPage(Math.random);
  }

  useEffect(() => {
    const ErrorList = [];
    if (!modelName) {
      ErrorList.push("Enter a Name");
    }
    setFormErrors(ErrorList);
  }, [modelName, pristine]);

  function ChangeResponseBy(e) {
    setModelVersion(e.value);
  }

  if (modelID) {
    return (
      <>
        <Header size="medium">Edit Model Details</Header>

        {formErrors.length > 0 && !pristine && (
          <Message
            negative
            //   onDismiss={clearErrors}
            header="We need these details!"
            list={formErrors}
          />
        )}
        <Container>
          <SectionDiv>

          <FormRow>
              <FormLabel>
                <LabelHead>Name</LabelHead>
              </FormLabel>
              <FormInput>
                <Input
                  fluid
                  placeholder="Name"
                  value={modelName || ""}
                  onChange={(e) => setModelName(e.target.value)}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>Status</LabelHead>
              </FormLabel>
              <FormInput>
                <Select
                  name="status"
                  options={AI_MODEL_STATUS}
                  onChange={(e) => ChangeResponseBy(e)}
                  value={AI_MODEL_STATUS.filter(
                    (ap) => ap.value === modelVersion
                  )}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>URL</LabelHead>
              </FormLabel>
              <FormInput>
                <Input
                  fluid
                  placeholder="URL"
                  value={modelUrl || ""}
                  onChange={(e) => setModelUrl(e.target.value)}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>Sort Order</LabelHead>
              </FormLabel>
              <FormInput>
                <Input
                  fluid
                  type="number"
                  placeholder="Sort Order"
                  value={modelSortOrder || ""}
                  onChange={(e) => setModelSortOrder(e.target.value)}
                />
              </FormInput>
            </FormRow>
          </SectionDiv>
        </Container>
        <ListContainer>
          <Button color="green" onClick={CreateEdit}>
            Update
          </Button>
        </ListContainer>
        <SectionDiv lastSection={true}></SectionDiv>
      </>
    );
  }
  return (
    <>
      <Header size="medium">Add New Model</Header>

      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}

      <Container>
        <LeftBox>
          <Input
            fluid
            placeholder="Name"
            value={modelName || ""}
            onChange={(e) => setModelName(e.target.value)}
          />
        </LeftBox>
        <LeftBox2>
          <Select
            name="status"
            options={AI_MODEL_STATUS}
            onChange={(e) => ChangeResponseBy(e)}
            value={AI_MODEL_STATUS.filter((ap) => ap.value === modelVersion)}
          />
        </LeftBox2>

        <RightBox>
          <Button onClick={CreateEdit}> {modelID ? "Update" : "Save"}</Button>
        </RightBox>
      </Container>
    </>
  );
};

export default CreateEdit;

const Container = styled.div`
  display: flex;
`;

const LeftBox = styled.div`
  flex: 8 1 0;
`;
const LeftBox2 = styled.div`
  flex: 1 1 0;
`;
const RightBox = styled.div`
  flex: 1 1 0;
`;
const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormRow = styled.div`
  display: flex;
  // justify-content: start;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  min-width: 200px;
  flex: 1 1 auto;
`;

const FormInput = styled.div`
  min-width: 600px;
  flex: 12 1 auto;
`;
