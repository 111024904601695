import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Confirm, Dimmer, Icon, List, Loader, Segment, Table,} from "semantic-ui-react";

import Select from "react-select";

import {CATEGORY_COMPLIANCE_HEADERS, PriorityList} from "common/data/AdminData"

import {SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT} from "common/constants/actions";

import {ColorPicker} from 'areas/categories/components/ColorPicker'

const Options = (props) => {
    const {
        optionItem,
        setShowOptions,
        setShowSubFactors,
        subfactorList,
        surveyStrucRow,
        setSurveyStrucRow,
        setUpdateJsonFile,
        loading,
    } = props

    const dispatch = useDispatch();

    // const [listName, setListName] = useState('')
    const [surveyOptions, setSurveyOptions] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(false)
    // const [updatedRows, setUpdatedRows] = useState(false)
    // const [color, setColor] = useState('#BCBCBC')
    const [prioritySelected, setPrioritySelected] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState(false)
    const [optionList, setOptionList] = useState([])
    const [selectedOptionList, setSelectedOptionList] = useState(false)

    useEffect(() => {
        setSurveyOptions(subfactorList?.subfactors || [])
    }, [subfactorList]);

    const { get_category_compliance } = useSelector(
        (state) => ({
            get_category_compliance: state.surveystructurecompliance.survey_struture_select,
        }),
        shallowEqual
    );

    // console.log(optionItem)

    useEffect(() => {
        setPrioritySelected(PriorityList.find(({ value }) => value === optionItem?.priority))
        setSelectedOptions(false)
        setOptionList([])
        setSelectedOptionList(false)
    }, [optionItem]);

    useEffect(() => {
        const PriorityId = CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.small === prioritySelected?.value)?.[0]?.value
        dispatch({
            type: SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT,
            payload: `priority=${PriorityId || ''}`
        });
    }, [dispatch, prioritySelected]);


    function ChangedOption(e) {
        setSelectedOptions(e)
        setOptionList(e.categories)
    }

    function ChangedOptionList(e) {
        setSelectedOptionList(e)
    }



    // console.log(surveyStrucRow)
    function AddAllToList() {
        ListAddition(true)
    }

    function AddToList() {
        ListAddition(false)
    }

    function ListAddition(EntireList = false) {
        setConfirmOpen(false)
        const _survey = surveyOptions
        if (EntireList) {
            optionList.map(o =>
                _survey.push(o)
            )
        } else {
            _survey.push(selectedOptionList)
        }

        setSurveyOptions(_survey)
        // setListName('')

        subfactorList.subfactors = _survey

        const ExcludedList = surveyStrucRow.filter(sc => sc.id !== optionItem.id)

        const NewList = []
        ExcludedList.map(ex =>
            NewList.push(ex)
        )
        NewList.push(optionItem)
        setSurveyStrucRow(NewList)
        setUpdateJsonFile(Math.random())

    }

    function RemoveFromList() {
        setConfirmOpen(false)
        const _survey = surveyOptions.filter(sc => sc.id !== itemToDelete.id)
        // console.log(itemToDelete)
        // console.log(surveyOptions.filter(sc => sc.id !== itemToDelete.id))
        setSurveyOptions(_survey)

        subfactorList.subfactors = _survey

        const ExcludedList = surveyStrucRow.filter(sc => sc.id !== optionItem.id)

        const NewList = []
        ExcludedList.map(ex =>
            NewList.push(ex)
        )
        NewList.push(optionItem)
        setSurveyStrucRow(NewList)
        setUpdateJsonFile(Math.random())
    }

    function PreviousPage() {
        setShowOptions(false)
        setShowSubFactors(false)
    }

    function OptionsPage() {
        setShowSubFactors(false)
    }

    function ConfirmDeleteItem(e) {
        setConfirmOpen(true)
        setItemToDelete(e)
    }

    function ResetDeleteItem() {
        setConfirmOpen(false)
        setItemToDelete(false)
    }

    return (
        <>
            <List horizontal>
                <List.Item>
                    <LinkBox onClick={PreviousPage}>
                        <List.Content>
                            <List.Header>{optionItem?.name} {'>'}</List.Header>

                            {optionItem?.id}
                        </List.Content>
                    </LinkBox>
                </List.Item>
                <List.Item>
                    <LinkBox onClick={OptionsPage}>
                        <List.Content>
                            <List.Header>{subfactorList?.name} {'>'}</List.Header>

                            {subfactorList?.id}
                        </List.Content>
                    </LinkBox>
                </List.Item>
                <List.Item>
                    <NonLinkBox>
                        <List.Content>
                            <List.Header>Sub Factors</List.Header>
                            This Page
                        </List.Content>
                    </NonLinkBox>
                </List.Item>
            </List>
            <Segment>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Subfactor Names</Table.HeaderCell>
                            <Table.HeaderCell>Subfactor French</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Color</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {surveyOptions && surveyOptions.map((item, i) =>
                            <Table.Row key={i}>
                                <Table.Cell>{item?.id}</Table.Cell>
                                <Table.Cell>{item?.name}</Table.Cell>
                                <Table.Cell>{item?.french}</Table.Cell>
                                <Table.Cell>{item?.description}</Table.Cell>
                                <Table.Cell>
                                    <ColorPicker
                                        color={item?.color}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        size='large'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => ConfirmDeleteItem(item)}
                                        name='trash alternate outline' />
                                    <Confirm
                                        header='Warning Irreversible decision'
                                        open={confirmOpen}
                                        content='This will delete this and its associated sub factors and cannot be reversed are you sure!'
                                        onCancel={ResetDeleteItem}
                                        onConfirm={RemoveFromList}
                                        size='large'
                                    />
                                </Table.Cell>

                            </Table.Row>
                        )}

                    </Table.Body>
                </Table>

                <FormContainer>
                    <Label>Select one Options List</Label>
                    <FullRowList>
                        <FormContainer width={'80%'}>
                            <Select
                                placeholder="Select Options"
                                onChange={ChangedOption}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.id}
                                value={selectedOptions}
                                options={get_category_compliance}
                            />
                        </FormContainer>
                        <FormContainer width={'18%'}>
                            <StyledButton onClick={AddAllToList}>Add All</StyledButton>
                        </FormContainer>
                    </FullRowList>

                </FormContainer>

                <FormContainer>

                    <Label>Add Options from List</Label>
                    <FullRowList>
                        <FormContainer width={'80%'}>
                            <Select
                                placeholder="Select Options"
                                onChange={ChangedOptionList}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.id}
                                value={selectedOptionList}
                                options={optionList}
                            />
                        </FormContainer>
                        <FormContainer width={'18%'}>
                            <StyledButton onClick={AddToList}>Add +</StyledButton>
                        </FormContainer>
                    </FullRowList>

                </FormContainer>
            </Segment>
        </>
    );
};

export default Options;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(props) => (props.disabled ? "#dcdcdc" : "#2d50e2")};
    border: none;
    outline: none;
    padding: 0px 30px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
        margin-right: 3px;
    }
`;


const NonLinkBox = styled.div`
    margin-top: 20px;
    border-style: solid;
    border-color: gray;
    border-width: thin;
    padding: 10px;
    min-width: 100px;
`

const LinkBox = styled.div`
    margin-top: 20px;
    border-style: solid;
    border-color: coral;
    border-width: thin;
    cursor: pointer;
    padding: 10px;
    min-width: 100px;
`

const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const FullRowList = styled.div`
    display: flex;
    justify-content: space-between;
`


const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`


const FormContainer = styled.div`
    width: ${props => props.width ? props.width : '100%'};
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;
`
