import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';

/* 
Here are some comments that were inheritied with the software. I'm not exactly sure how to parse them, but they may provide some clues. Add them to the subheader
below when they can be clarified in a meaningful way - Travis

    The external name is what the system would look for
    and if a match is found would map it with the name in [Name] field
    If both the names are same just use the name field alone, which would
    be the fallback check if no match is found in external name
    works for API and CSV upload
*/

function EmployeeRecordValue() {
    return (
        <>
                <Routes>
                    <Route path="/" element={<List />} />
                    <Route path="new" element={<AddEdit />} />
                    <Route path="details/:id" element={<Details />} />
                </Routes>
        </>
    );
}

export default EmployeeRecordValue;
