import {
    UPDATE_LOGIN_HISTORY_PAGE_NUMBER,
    GET_LOGIN_HISTORY_PENDING,
    GET_LOGIN_HISTORY,
    DELETE_LOGIN_HISTORY,
    FETCH_LOGIN_HISTORY,
    EDIT_LOGIN_HISTORY,
    ADD_LOGIN_HISTORY,
    CLEAR_LOGIN_HISTORY,
    CLEAR_ALL,
} from "common/constants/actions";



const initialState = {
    login_history: [],
    pageNumber: 1,
    pending: false,
    created: false,
};

export default function LoginHistory(state = initialState, action) {
    switch (action.type) {
        case GET_LOGIN_HISTORY_PENDING:
            return {
                ...state,
                pending: true,
                created: false,
            };
        case UPDATE_LOGIN_HISTORY_PAGE_NUMBER:
                return {
                  ...state,
                  pageNumber: action.payload
                }
        case GET_LOGIN_HISTORY:
            return {
                ...state,
                login_history: action.payload,
                pending: false,
                created: false,
            };

        case FETCH_LOGIN_HISTORY:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false,
                created: false,

            };
        case EDIT_LOGIN_HISTORY:
            if (state.login_history.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                    ...state,
                    login_history: state.login_history.filter(
                        (rw) => rw.id !== action.payload.id
                    ),
                };
            }
            return {
                ...state,
                created: false,
                login_history: [...state.login_history, action.payload],
            };

        case DELETE_LOGIN_HISTORY:
            // console.log(action.payload)
            return {
                ...state,
                login_history: state.login_history.filter(
                    (row) => row.id !== action.payload.id
                ),
            };

        case ADD_LOGIN_HISTORY:
            return {
                ...state,
                created: action.payload,
                login_history: [...state.login_history, action.payload],
            };

        case CLEAR_LOGIN_HISTORY:
            return {
                ...state,
                ...initialState
            };

        case CLEAR_ALL:
            return {
                ...state,
                ...initialState
            };

        default:
            return state;
    }
}
