import React from 'react';
import styled from "styled-components";
import { Table, Icon } from 'semantic-ui-react';

const TableList = ({ rows, deleteRow, EditSelectRow }) => {
    return (
        <Table selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}>Color</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={4}>French</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Description</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {rows && rows.map((result, i) => {
                    return (
                        <Table.Row key={i} onClick={() => EditSelectRow(i, result)}>
                            <Table.Cell><DivColor color={result.color} /></Table.Cell>
                            <Table.Cell>{result.name}</Table.Cell>
                            <Table.Cell>{result?.fr}</Table.Cell>
                            <Table.Cell>{result?.description}</Table.Cell>
                            <Table.Cell>
                                <SelectIcon
                                    onClick={(e) => deleteRow(result.id, e)}
                                    name='trash' />
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    );
};

export default TableList;

const DivColor = styled.div`
    background-color:${props => props.color};
    height: 20px;
    width: 20px;
`

const SelectIcon = styled(Icon)`
    cursor: pointer;
`
