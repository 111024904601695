import React, { useState, useEffect } from "react";
import { Icon, Button, Modal, Header, Tab } from "semantic-ui-react";
import ReactJson from "react-json-view";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_EDIT_SURVEY_STRUCTURE } from "common/constants/actions";

export const ViewJson = ({
  surveyStructureId,
  openEditJson,
  setOpenEditJson,
  openViewSummary,
  setOpenViewSummary,
}) => {
  const dispatch = useDispatch();

  const [surveyStrucRow, setSurveyStrucRow] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [fieldMapEdit, setFieldMapEdit] = useState(false);

  const { get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure,
    }),
    shallowEqual
  );

  function SaveSurveyStructure() {
    setOpenEditJson(false);
    if (surveyStructureId) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          categories: surveyStrucRow,
        },
      });
    }
  }

  useEffect(() => {
    if (surveyStructureId) {
      // this should run once
      setSurveyStrucRow(get_survey_structure?.[surveyStructureId]?.categories);
      setSurveyQuestions(
        get_survey_structure?.[surveyStructureId]?.survey_questions
      );
      setFieldMapEdit(get_survey_structure?.[surveyStructureId]?.field_map);
    }
  }, [get_survey_structure, surveyStructureId]);

  const handleJsonEdit = (edit) => {
    // Handle JSON edits here
    setSurveyStrucRow(edit.updated_src)
  };

  // Function to handle node addition
  function handleNodeAdd(data) {
    setSurveyStrucRow(data.updated_src);
  }
  // Function to handle node deletion
  const handleNodeDelete = (path) => {
    setSurveyStrucRow(path.updated_src);
  };

  const panes = [
    {
      menuItem: "Category Structure",
      render: () => (
        <Tab.Pane attached={false}>
          <pre>{JSON.stringify(surveyStrucRow, null, 3)}</pre>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Survey Question (Auto Generated)",
      render: () => (
        <Tab.Pane attached={false}>
          <pre>{JSON.stringify(surveyQuestions, null, 3)}</pre>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Field Map (Auto Generated)",
      render: () => (
        <Tab.Pane attached={false}>
          <pre>{JSON.stringify(fieldMapEdit, null, 3)}</pre>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Modal open={openEditJson} onClose={() => setOpenEditJson(false)}>
        <Modal.Header>
          <Header as="h1">
            Category Summary
            <Header.Subheader>Edit the JSON summary file</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <ReactJson
              id="a_unique_id"
              src={surveyStrucRow}
              onEdit={handleJsonEdit}
              onDelete={handleNodeDelete}
              onAdd={handleNodeAdd}
              height="800px"
              width="100%"
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={SaveSurveyStructure} color="violet">
            Save and Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={openViewSummary} onClose={() => setOpenViewSummary(false)}>
        <Modal.Header>
          <Header as="h1">
            Category Summary
            <Header.Subheader>
              Detailed Summary of JSON file, this is how the information gets
              processed
            </Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Tab menu={{ pointing: true }} panes={panes} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenViewSummary(false)} color="violet">
            Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ViewJson;
