import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ModelMainList from "./models/List";
import CreateEditModel from "./models/CreateEdit";

import { Grid, Image } from "semantic-ui-react";

const GptDetail = () => {
  return (
    <>
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Grid.Column>
          <Grid.Column>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Grid.Column>
          <Grid.Column>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Grid.Column>
          <Grid.Column>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default GptDetail;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
