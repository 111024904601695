import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
    GET_SURVEY_STANDARD_PENDING,
    GET_SURVEY_STANDARDS,
    SG_GET_SURVEY_STANDARDS,
    DELETE_SURVEY_STANDARD,
    SG_DELETE_SURVEY_STANDARD,
    FETCH_SURVEY_STANDARD,
    SG_FETCH_SURVEY_STANDARD,
    EDIT_SURVEY_STANDARD,
    SG_EDIT_SURVEY_STANDARD,
    ADD_SURVEY_STANDARD,
    SG_ADD_SURVEY_STANDARD,
    ALL_ERRORS,
} from "common/constants/actions";


function* loadmethods(action) {
    yield put({ type: GET_SURVEY_STANDARD_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_SURVEY_STANDARDS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_SURVEY_STANDARDS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_SURVEY_STANDARDS, loadmethods);
}


function* fetchmethods(action) {
    yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.FETCH_SURVEY_STANDARD, action.payload);
    yield put({ type: FETCH_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_SURVEY_STANDARD, fetchmethods);
}



function* updatemethods(action) {
    yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.EDIT_SURVEY_STANDARD, action.payload);
    yield put({ type: EDIT_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_EDIT_SURVEY_STANDARD, updatemethods);
}

function* addmethods(action) {
    yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.ADD_SURVEY_STANDARD, action.payload);
    yield put({ type: ADD_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_SURVEY_STANDARD, addmethods);
}

function* deletemethods(action) {
    yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    yield call(api.DELETE_SURVEY_STANDARD, action.payload);
    yield put({ type: DELETE_SURVEY_STANDARD, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_SURVEY_STANDARD, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
