import React, { useState, useEffect } from "react";

import { Header, Tab, List } from 'semantic-ui-react'

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_GET_SURVEY_STRUCTURES,
} from "common/constants/actions";

import Select from "react-select";

// const PriorityList = [
//     { value: 'primary', label: "Primary" },
//     { value: 'secondary', label: "Secondary" },
//     { value: 'tertiary', label: "Tertiary" },
// ]

function SurveyQuestion(surveyQuestions) {
    return (
        <>
            <Header sub>Question</Header>
            <span>{surveyQuestions?.[0]?.question}</span>
            <Select
                placeholder={`Id ${surveyQuestions?.[0]?.id}`}
                options={surveyQuestions?.[0]?.answers}
                getOptionLabel={(answers) => answers.name}
                getOptionValue={(answers) => answers.name}
            />
        </>
    )
}

function QuestionsMap(surveyQuestions) {

    return (
        <>
            <Header sub>File Upload Columns</Header>
            <span>{surveyQuestions?.[0]?.question}</span>
            <List celled horizontal>
                {surveyQuestions?.fields.map(item => (
                    <List.Item key={item}>{item}</List.Item>
                ))}
            </List>

            <Header sub>Primary field name</Header>
            <span>{Object.keys(surveyQuestions?.db_column_name)?.[0]}</span>


            <Header sub>Primary (also used in validating primary field)</Header>
            <Select
                placeholder={`Id ${surveyQuestions?.primary?.[0]?.id}`}
                options={surveyQuestions?.primary?.[0]?.answers}
                getOptionLabel={(answers) => answers.name}
                getOptionValue={(answers) => answers.name}
            />
            {/* <pre>{JSON.stringify(surveyQuestions, null, 3)}</pre> */}
        </>
    )
}

export const OnBoardingCategory = ({ organizationId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_STRUCTURES,
            payload: `organization=${organizationId}`,
        });
    }, [dispatch, organizationId]);

    const [fieldMapEdit, setFieldMapEdit] = useState(false)
    const [surveyQuestions, setSurveyQuestions] = useState(false)

    const { get_survey_structure } = useSelector(
        (state) => ({
            get_survey_structure: state.surveystructure,
        }),
        shallowEqual
    );


    useEffect(() => {
        setSurveyQuestions(get_survey_structure?.survey_structure?.results?.[0]?.survey_questions);
        setFieldMapEdit(get_survey_structure?.survey_structure?.results?.[0]?.field_map);
    }, [get_survey_structure]);



    const panes = [
        {
            menuItem: 'Survey Question',
            render: () => <Tab.Pane attached={false}>
                {SurveyQuestion(surveyQuestions)}
            </Tab.Pane>,
        },
        {
            menuItem: 'Field Map',
            render: () => <Tab.Pane attached={false}>
                {QuestionsMap(fieldMapEdit)}

            </Tab.Pane>,
        },
    ]

    return (
        <>

            <Header as='h1'>
                Category Summary
                <Header.Subheader>
                    Detailed Summary of JSON file, this is how the information gets processed
                </Header.Subheader>
            </Header>

            <Tab menu={{ pointing: true }} panes={panes} />

        </>

    );
};

export default OnBoardingCategory;


