import {
    GET_PRICES,
    FETCH_PRICE,
    CLEAR_PRICE,
    CLEAR_ALL,
  } from "common/constants/actions";
  
  const initialState = {
    price: [],
    pending: false,
  };
  
  export default function Price (state = initialState, action) {
    switch (action.type) {
      case GET_PRICES:
        return {
          ...state,
          price: action.payload,
          pending: false,
        };

      case FETCH_PRICE:
        return { 
            ...state,
            [action.payload.id]: action.payload, 
            pending: false 
        };

      case CLEAR_PRICE:
        return {
          ...state,
          price: [],
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          price: [],
        };
  
      default:
        return state;
    }
  }
