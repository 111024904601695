import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_DEBRIEF_TOKENS, DEBRIEF_TOKEN_PAGE_NUMBER } from "common/constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment, Message } from 'semantic-ui-react'

const TokenList = ({ deBriefId, employeeId, isDemo, DelayedLoad, searchTerm }) => {
    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        // if the response is ok 200
        if (deBriefId) {
            dispatch({
                type: SG_GET_DEBRIEF_TOKENS,
                payload: `page=${page}&debrief_schedule=${deBriefId}${searchTerm ? `&survey_token=${searchTerm}` : ''}`,
            });
        }

        if (employeeId) {
            dispatch({
                type: SG_GET_DEBRIEF_TOKENS,
                payload: `page=${page}${employeeId ? `&employee=${employeeId}` : ''}`,
            });
        }
        setLoading(true)
    }, [dispatch, page, deBriefId, employeeId, searchTerm]);

    const { get_debrief_tokens } = useSelector(
        (state) => ({
            get_debrief_tokens: state.debrief_token,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_debrief_tokens?.debrief_token?.results);
        setPage(get_debrief_tokens?.pageNumber)
        setTotalPages(Math.ceil(get_debrief_tokens?.debrief_token?.count / get_debrief_tokens?.debrief_token?.page_size))
        if (get_debrief_tokens?.debrief_token?.count % get_debrief_tokens?.debrief_token?.page_size) {
            setTotalPages(Math.ceil(get_debrief_tokens?.debrief_token?.count / get_debrief_tokens?.debrief_token?.page_size))
        }
    }, [get_debrief_tokens, page]);


    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: DEBRIEF_TOKEN_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (
        <>
            <Dimmer active={loading}>
                <Loader />
            </Dimmer>
            <Segment id="results" >
                <Card.Group>
                    {results?.length > 0 ? results.map((result) => (
                        <RowCard
                            row={result}
                            key={result?.id}
                            isDemo={isDemo}
                            DelayedLoad={DelayedLoad}
                        />
                    )) :
                        <p style={{ padding: '20px' }}>No survey tokens</p>
                    }
                </Card.Group>
            </Segment>
            {totalPages > 1 ? <SectionDiv><Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={page || 0}
                totalPages={totalPages || 0}
                onPageChange={handlePageChange}
            /></SectionDiv> : <></>}
        </>
    );
};

export default TokenList;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`
