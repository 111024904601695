import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_ACCOUNTS, UPDATE_ACCOUNT_PAGE_NUMBER } from "common/constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment, Checkbox } from 'semantic-ui-react'

const List = ({ organizationId, employeeId }) => {

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [showStaff, setShowStaff] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_ACCOUNTS,
            payload: `page=${page}${showStaff ? `&is_staff=${showStaff}`:''}${organizationId ? `&organization_id=${organizationId}` : ''}${employeeId ? `&employee_id=${employeeId}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}`,
        });

        setLoading(true)
    }, [dispatch, page, searchTerm, organizationId, employeeId, showStaff]);

    const { get_accounts } = useSelector(
        (state) => ({
            get_accounts: state.account,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_accounts?.accounts?.results);
        setPage(get_accounts?.pageNumber)
        setTotalPages(Math.ceil(get_accounts?.accounts?.count / get_accounts?.accounts?.page_size))
        if (get_accounts?.accounts?.count % get_accounts?.accounts?.page_size) {
            setTotalPages(Math.ceil(get_accounts?.accounts?.count / get_accounts?.accounts?.page_size))
        }

    }, [get_accounts, page]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_ACCOUNT_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <>
            {!employeeId &&
                <SectionDiv>
                    <SearchBar
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm} />

                    <div>
                        <label>Only Staff can login to admin app</label>
                    </div>
                    <Checkbox
                        slider
                        checked={showStaff}
                        label={`${showStaff ? "Hide" : "Show"} Staff Accounts currently ${showStaff ? " showing only Staff logins" : " showing all logins"}`}
                        onClick={() => setShowStaff(!showStaff)}
                    />
                </SectionDiv>
            }

            <Segment id="results" >
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results?.length > 0 ? results.map((result, i) => (
                        <RowCard row={result} key={i} />
                    )):
                    <p style={{ padding: '20px'}}>No login events</p>
            }
                </Card.Group>
            </Segment>
            {!employeeId &&
                <SectionDiv>

                    <Pagination
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        activePage={page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </SectionDiv>
            }


        </>

    );
};

export default List;


const Container = styled.div`
  padding: 0px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`
