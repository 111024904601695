import React, { useState } from 'react';
import { Form, Icon, Input, Label, Segment } from 'semantic-ui-react';

export const ListItemsInput = ({ label, items, setItems }) => {
    const [inputValue, setInputValue] = useState('');
  
    const handleAddItem = () => {
      if (inputValue.trim() !== '') {
        setItems([...items, inputValue.trim()]);
        setInputValue('');
      }
    };
  
    const handleRemoveItem = (index) => {
      setItems(items.filter((_, i) => i !== index));
    };
  
    return (
      <Form.Field>
        <label>{label}</label>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          action={{
            icon: 'add',
            onClick: handleAddItem,
          }}
        />
        {items.length > 0 && (
          <Segment>
            {items.map((item, index) => (
              <Label key={index} style={{ marginBottom: '5px' }}>
                {item}
                <Icon name="delete" onClick={() => handleRemoveItem(index)} />
              </Label>
            ))}
          </Segment>
        )}
      </Form.Field>
    );
  };
  
  