import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  WEB_API_LOG_PENDING,
GET_WEB_API_LOGS,
SG_GET_WEB_API_LOGS,
DELETE_WEB_API_LOG,
SG_DELETE_WEB_API_LOG,
FETCH_WEB_API_LOG,
SG_FETCH_WEB_API_LOG,
EDIT_WEB_API_LOG,
SG_EDIT_WEB_API_LOG,
ADD_WEB_API_LOG,
SG_ADD_WEB_API_LOG,
ALL_ERRORS,
} from "common/constants/actions";


function* loadTemplate(action) {
  try {
    yield put({ type: WEB_API_LOG_PENDING });
    const json = yield call(api.GET_WEB_API_LOGS, action.payload);
    yield put({ type: GET_WEB_API_LOGS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoad() {
  yield takeLatest(SG_GET_WEB_API_LOGS, loadTemplate);
}

function* fetchTemplate(action) {
  try {
    yield put({ type: WEB_API_LOG_PENDING });
    const json = yield call(api.FETCH_WEB_API_LOG, action.payload);
    yield put({ type: FETCH_WEB_API_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionFetch() {
  yield takeLatest(SG_FETCH_WEB_API_LOG, fetchTemplate);
}

function* updateTemplate(action) {
  try {
    yield put({ type: WEB_API_LOG_PENDING });
    const json = yield call(api.EDIT_WEB_API_LOG, action.payload);
    yield put({ type: EDIT_WEB_API_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionUpdate() {
  yield takeLatest(SG_EDIT_WEB_API_LOG, updateTemplate);
}

function* addTemplates(action) {
  try {
    yield put({ type: WEB_API_LOG_PENDING });
    const json = yield call(api.ADD_WEB_API_LOG, action.payload);
    yield put({ type: ADD_WEB_API_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAdd() {
  yield takeLatest(SG_ADD_WEB_API_LOG, addTemplates);
}


function* deleteTemplate(action) {
  try {
    yield put({ type: WEB_API_LOG_PENDING });
    const json = yield call(api.DELETE_WEB_API_LOG, action.payload);
    yield put({ type: DELETE_WEB_API_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionDelete() {
  yield takeLatest(SG_DELETE_WEB_API_LOG, deleteTemplate);
}


export default function* index() {
  yield all([
    functionLoad(),
    functionFetch(),
    functionUpdate(),
    functionAdd(),
    functionDelete(),
  ]);
}
