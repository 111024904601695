import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import styled from "styled-components";
import {Confirm, Dimmer, Icon, Input, List, Loader, Segment, Table,} from "semantic-ui-react";

import {ColorPicker, stringToHexColor} from 'areas/categories/components/ColorPicker'

const Options = (props) => {
    const {
            // surveyStructureId,
            optionItem,
            setShowOptions,
            setSubfactorList,
            setShowSubFactors,
            surveyStrucRow,
            setSurveyStrucRow,
            loading,
            setUpdateJsonFile,
         } = props

    const [listName, setListName] = useState('')
    const [surveyOptions, setSurveyOptions] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(false)
    // const [loading, setLoading] = useState(false)

    // const [updatedRows, setUpdatedRows] = useState(false)
    const [color, setColor] = useState('#BCBCBC')

    useEffect(() => {
        setSurveyOptions(optionItem?.options)
    }, [optionItem]);

    function UpdatedOptionItem(e) {
        setListName(e.target.value)
        setColor(stringToHexColor(e.target.value, 50, 60))
    }

    function AddToList() {
        // setLoading(true)
        const _survey = surveyOptions
        if (listName.length > 0) {
            _survey.push({
                id: uuidv4(),
                name: listName,
                color: color,
                subfactors: []
            })

        }
        setSurveyOptions(_survey)
        setListName('')

        const ExcludedList = surveyStrucRow.filter(sc => sc.id !== optionItem.id)
        optionItem.options = _survey
        ExcludedList.push(optionItem)

        setSurveyStrucRow(ExcludedList)
        setUpdateJsonFile(Math.random())
    }

    console.log(optionItem)
    // {
    //     "id": "7269967e-0526-4b91-a78b-f49c75f9abcb",
    //     "name": "Gender",
    //     "input": "ask_if_not_found",
    //     "options": [

    //     "priority": "tertiary",
    //     "question": "What is your gender? Gender refers to current gender which may be different from sex assigned at birth and may be different from what is indicated on legal documents",
    //     "subtitle": "Gender",
    //     "subfactors": [],
    //     "visibility": "private"
    // }

    function RemoveFromList() {
        // console.log(itemToDelete)
        setConfirmOpen(false)
        // setLoading(true)
        const _survey = surveyOptions.filter(sc => sc.id !== itemToDelete.id)
        setSurveyOptions(_survey)

        const ExcludedList = surveyStrucRow.filter(sc => sc.id !== optionItem.id)
        optionItem.options = _survey
        ExcludedList.push(optionItem)

        setSurveyStrucRow(ExcludedList)
        setUpdateJsonFile(Math.random())
    }

    function PreviousPage() {
        setShowOptions(false)
    }

    function SetSubFactor(items) {
        // console.log(items)
        setSubfactorList(items)
        setShowSubFactors(true)
    }

    function ConfirmDeleteItem(e) {
        // console.log(e)
        setConfirmOpen(true)
        setItemToDelete(e)
    }

    function ResetDeleteItem() {
        setConfirmOpen(false)
        setItemToDelete(false)
    }
    return (
        <>
            <List horizontal>

                <List.Item>
                    <LinkBox onClick={PreviousPage}>
                        <List.Content>
                            <List.Header>{optionItem?.name} {'>'}</List.Header>

                            {optionItem?.id}
                        </List.Content>
                    </LinkBox>
                </List.Item>
                <List.Item>
                    <NonLinkBox>
                        <List.Content>
                            <List.Header>Options</List.Header>
                            This Page
                        </List.Content>
                    </NonLinkBox>
                </List.Item>
            </List>
            <Segment>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>


                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Option Names</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {surveyOptions && surveyOptions.map((item, i) =>
                            <Table.Row key={i}>
                                <Table.Cell>{item?.id}</Table.Cell>
                                <Table.Cell>{item?.name}</Table.Cell>

                                <Table.Cell>


                                    <StyledButton onClick={() => SetSubFactor(item)}>
                                        Subfactors
                                    </StyledButton>
                                </Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        size='large'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => ConfirmDeleteItem(item)}
                                        name='trash alternate outline' />
                                    <Confirm
                                        header='Warning Irreversible decision'
                                        open={confirmOpen}
                                        content='This will delete this and its associated sub factors and cannot be reversed are you sure!'
                                        onCancel={ResetDeleteItem}
                                        onConfirm={RemoveFromList}
                                        size='large'
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    {/* <Button
                                    content="Edit"
                                    onClick={() => {
                                        SelectRow(item);
                                    }}
                                /> */}
                                </Table.Cell>
                            </Table.Row>
                        )}

                    </Table.Body>
                </Table>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>
                                <Input
                                fluid
                                placeholder='New Options Name'
                                name="name"
                                value={listName}
                                onChange={(e) => UpdatedOptionItem(e)}
                            /></Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                                <ColorPicker
                                    color={color}
                                    setColor={(c) => setColor(c.hex)}
                                />
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}>
                                <StyledButton onClick={AddToList}>
                                    Add
                                </StyledButton>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>
            </Segment>
        </>
    );
};

export default Options;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(props) => (props.disabled ? "#dcdcdc" : "#2d50e2")};
    border: 1px solid #2d50e2;
    outline: none;
    padding: 0px 30px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;


const NonLinkBox = styled.div`
    margin-top: 20px;
    border-style: solid;
    border-color: gray;
    border-width: thin;
    padding: 10px;
    min-width: 100px;
`

const LinkBox = styled.div`
    margin-top: 20px;
    border-style: solid;
    border-color: coral;
    border-width: thin;
    cursor: pointer;
    padding: 10px;
    min-width: 100px;
`
