import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
GET_WEB_API_CREDENTIALS,
WEB_API_CREDENTIAL_PENDING,
SG_GET_WEB_API_CREDENTIALS,
DELETE_WEB_API_CREDENTIAL,
SG_DELETE_WEB_API_CREDENTIAL,
FETCH_WEB_API_CREDENTIAL,
SG_FETCH_WEB_API_CREDENTIAL,
EDIT_WEB_API_CREDENTIAL,
SG_EDIT_WEB_API_CREDENTIAL,
ADD_WEB_API_CREDENTIAL,
SG_ADD_WEB_API_CREDENTIAL,
SG_GET_WEB_API_CRED_LIST,
GET_WEB_API_CRED_LIST,
ALL_ERRORS,
} from "common/constants/actions";


function* loadTemplate(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.GET_WEB_API_CREDENTIALS, action.payload);
    yield put({ type: GET_WEB_API_CREDENTIALS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoad() {
  yield takeLatest(SG_GET_WEB_API_CREDENTIALS, loadTemplate);
}

function* selectTemplate(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.GET_WEB_API_CRED_LIST, action.payload);
    yield put({ type: GET_WEB_API_CRED_LIST, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionSelect() {
  yield takeLatest(SG_GET_WEB_API_CRED_LIST, selectTemplate);
}


function* fetchTEmplate(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.FETCH_WEB_API_CREDENTIAL, action.payload);
    yield put({ type: FETCH_WEB_API_CREDENTIAL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionFetch() {
  yield takeLatest(SG_FETCH_WEB_API_CREDENTIAL, fetchTEmplate);
}

function* updateTemplate(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.EDIT_WEB_API_CREDENTIAL, action.payload);
    yield put({ type: EDIT_WEB_API_CREDENTIAL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionUpdate() {
  yield takeLatest(SG_EDIT_WEB_API_CREDENTIAL, updateTemplate);
}

function* addEmailTemplates(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.ADD_WEB_API_CREDENTIAL, action.payload);
    yield put({ type: ADD_WEB_API_CREDENTIAL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAdd() {
  yield takeLatest(SG_ADD_WEB_API_CREDENTIAL, addEmailTemplates);
}


function* deleteToken(action) {
  try {
    yield put({ type: WEB_API_CREDENTIAL_PENDING});
    const json = yield call(api.DELETE_WEB_API_CREDENTIAL, action.payload);
    yield put({ type: DELETE_WEB_API_CREDENTIAL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionDelete() {
  yield takeLatest(SG_DELETE_WEB_API_CREDENTIAL, deleteToken);
}


export default function* index() {
  yield all([
    functionLoad(),
    functionSelect(),
    functionFetch(),
    functionUpdate(),
    functionAdd(),
    functionDelete(),
  ]);
}
