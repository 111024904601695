import React from 'react';
import PageTitle, { Container } from "common/layouts/AuthLayout/PageTitle"
import { RecommendationListTable } from "../RecommendationListTable"

const OrganizationalGoalList = ({ templateType }) => {
  return (
    <Container>
      <PageTitle newPageTitle={"Organizational Goals"} />
      {RecommendationListTable(templateType)}
    </Container>
  );
};

export default OrganizationalGoalList;