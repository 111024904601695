import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";


import {
    // UPDATE_GPT_MEMBER_PAGE_NUMBER,
    GET_GPT_MEMBER_PENDING,
    GET_GPT_MEMBERS,
    SG_GET_GPT_MEMBERS,
    GET_GPT_MEMBER_SELECT,
    SG_GET_GPT_MEMBER_SELECT,
    DELETE_GPT_MEMBER,
    SG_DELETE_GPT_MEMBER,
    FETCH_GPT_MEMBER,
    SG_FETCH_GPT_MEMBER,
    EDIT_GPT_MEMBER,
    SG_EDIT_GPT_MEMBER,
    ADD_GPT_MEMBER,
    SG_ADD_GPT_MEMBER,
    // CLEAR_GPT_MEMBER,
    ALL_ERRORS,
} from "common/constants/actions";


function* loadmethods(action) {
    yield put({ type: GET_GPT_MEMBER_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_GPT_MEMBERS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_GPT_MEMBERS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_GPT_MEMBERS, loadmethods);
}

function* loadSelectmethods(action) {
  yield put({ type: GET_GPT_MEMBER_PENDING });
// console.log('action.payload', action.payload)
try {
  const json = yield call(api.GET_GPT_MEMBER_SELECT, action.payload);
  // console.log(json.data)
  yield put({ type: GET_GPT_MEMBER_SELECT, payload: json.data });

} catch (e) {
  yield put({ type: ALL_ERRORS, payload: e.response });
}
}

export function* methodSelectLoad() {
yield takeEvery(SG_GET_GPT_MEMBER_SELECT, loadSelectmethods);
}

function* fetchmethods(action) {
    yield put({ type: GET_GPT_MEMBER_PENDING });
  try {
    const json = yield call(api.FETCH_GPT_MEMBER, action.payload);
    yield put({ type: FETCH_GPT_MEMBER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_GPT_MEMBER, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_GPT_MEMBER, action.payload);
    yield put({ type: EDIT_GPT_MEMBER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_GPT_MEMBER, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_GPT_MEMBER
  try {
    const json = yield call(api.ADD_GPT_MEMBER, action.payload);
    yield put({ type: ADD_GPT_MEMBER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_GPT_MEMBER, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_GPT_MEMBER, action.payload);
    yield put({ type: DELETE_GPT_MEMBER, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_GPT_MEMBER, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodSelectLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
