import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import Toolbar from "./Toolbar";
import ImageCard from "./ImageCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_WEB_IMAGES, UPDATE_WEB_IMAGE_NUMBER } from "common/constants/actions";

import { Dimmer, Loader, Grid, Pagination, Segment } from 'semantic-ui-react'

const BlogList = () => {

    const apiDispatch = useDispatch();
    const [webImages, setWebImages] = useState([]);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [reLoad, setReLoad] = useState(false)

    useEffect(() => {
        // if the response is ok 200
        setWebImages([])
        apiDispatch({
            type: SG_GET_WEB_IMAGES,
            payload: `page=${page}`,
        });
        apiDispatch({
            type: UPDATE_WEB_IMAGE_NUMBER,
            payload: page,
        });
        setLoading(true)

    }, [apiDispatch, page]);

    const { get_Webimages } = useSelector(
        (state) => ({
            get_Webimages: state.webimages,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (reLoad) {
            setLoading(true)
            const timer1 = setTimeout(() => {
                setLoading(false)
                setWebImages([])
                apiDispatch({
                    type: SG_GET_WEB_IMAGES,
                    payload: `page=${page}`,
                });
                apiDispatch({
                    type: UPDATE_WEB_IMAGE_NUMBER,
                    payload: page,
                });
                setReLoad(false)
            }, 2000);
            return () => clearTimeout(timer1);
        }

    }, [reLoad, apiDispatch, page]);

    useEffect(() => {
        setWebImages(get_Webimages?.webimage.results);
        setCount(get_Webimages?.webimage.count);
        setPageSize(get_Webimages?.webimage?.page_size);
        setPage(get_Webimages?.pageNumber)
    }, [get_Webimages, reLoad]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
    }

    useEffect(() => {
        //
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <Container>
            <Toolbar />
            <Segment basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Grid relaxed columns={4}>
                    {webImages &&
                        webImages.map((blog) => (
                            <Grid.Column key={blog.id}>

                                <ImageCard blog={blog}
                                    setReLoad={setReLoad} />

                            </Grid.Column>
                        ))}
                </Grid>
            </Segment>
            <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={page ? page : 1}
                totalPages={count / pageSize > 0 ? count / pageSize : 1}
                onPageChange={handlePageChange}
            />
        </Container >

    );
};

export default BlogList;


const Container = styled.div`
  min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;
