import {
    EMPLOYEE_CATEGORY_PENDING,
    GET_EMPLOYEE_CATEGORIES,
    DELETE_EMPLOYEE_CATEGORY,
    FETCH_EMPLOYEE_CATEGORY,
    EDIT_EMPLOYEE_CATEGORY,
    ADD_EMPLOYEE_CATEGORY,
    CLEAR_EMPLOYEE_CATEGORY,
    CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
    employee_category: [],
    pending: false,
    created: false,
};

export default function EmployeeCategories(state = initialState, action) {

    switch (action.type) {
        case EMPLOYEE_CATEGORY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_EMPLOYEE_CATEGORIES:
            return {
                ...state,
                employee_category: action.payload,
                pending: false,
                created: false,
            };

        case FETCH_EMPLOYEE_CATEGORY:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false,
                created: false,

            };

        case EDIT_EMPLOYEE_CATEGORY:
            if (state.employee_category.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                    ...state,
                    employee_category: state.employee_category.filter(
                        (rw) => rw.id !== action.payload.id
                    ),
                };
            }
            return {
                ...state,
                created: false,
                employee_category: [...state.employee_category, action.payload],
            };

        case DELETE_EMPLOYEE_CATEGORY:
            // console.log(action.payload)
            return {
                ...state,
                employee_category: state.employee_category.filter(
                    (row) => row.id !== action.payload.id
                ),
            };

        case ADD_EMPLOYEE_CATEGORY:
            console.log("New State", state.employee_category)
            const newState = state.employee_category
            newState.push(action.payload)
            console.log("New State", newState)

            return {
                ...state,
                created: action.payload,
                employee_category: [...state.employee_category, action.payload],
            };

        case CLEAR_EMPLOYEE_CATEGORY:
            return {
                ...state,
                employee_category: [],
                created: false,
            };

        case CLEAR_ALL:
            return {
                ...state,
                employee_category: [],
                created: false,
            };

        default:
            return state;
    }
}
