import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {Dimmer, Loader} from "semantic-ui-react";
import Logo from 'assets/images/nav/nuwave_black.png';
import {shallowEqual, useSelector} from "react-redux";
import ValidateEmail from "./ValidateEmail";
import {RootState} from "../../../store";
import Employee from "../../../common/types/Employee";

const OnBoarding = () => {
  const [employee, setEmployee] = useState<Employee | undefined>();
  const [employeeId, setEmployeeId] = React.useState("");
  const [loading, setLoading] = useState(true)

  const {
    getAuth,
    getEmployees,
    getOrganizations
  } = useSelector(
      (state: RootState) => ({
        getAuth: state.auth,
        getEmployees: state.employees,
        getOrganizations: state.organizations
      }),
      shallowEqual
    );

  useEffect(() => {
    if (getAuth?.token) {
      setEmployeeId(getAuth.employee_id);
    }
  }, [getAuth, getOrganizations]);

  useEffect(() => {
    if (employeeId) {
      // @ts-ignore
      setEmployee(getEmployees[employeeId]);
      setLoading(false);
    }
  }, [getEmployees, employeeId]);

  if (employee?.email_status === 9) {
    return (
      <Container>
        <ValidateEmail />
      </Container>
    )
  }

  return (
      <Container>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <TopBanner>
            <Left>
              <Title>nuLogic Admin</Title>
              <Description>Welcome to nuLogic, the platform that drives positive organizational change through cultural and social innovation.</Description>
            </Left>
            <Right>
              <Image src={Logo} />
            </Right>
          </TopBanner>
        </Dimmer.Dimmable>
      </Container>
  )
}

export default OnBoarding

const Container = styled.div`
	width:100%;
  margin-top:30px;

   @media(max-width:768px){
    margin-top:0;
   }
`

const TopBanner = styled.div`
	width:100%;
	display:flex;
	justify-content:center;
	flex-direction:row;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
	background-color:rgba(0, 176, 240,0.1);
    flex-wrap:wrap;
    height:auto;
`

const Left = styled.div`
	width:50%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding:40px;

  @media(max-width:768px){
    width:100%;
  }
`

const Title = styled.div`
	font-size:30px;
	font-weight:bold;
  line-height:1;

  @media(max-width:768px){
    font-size:28px;
  }
`

const Description = styled.div`
	margin-top:30px;
	max-width:800px;
	font-size:18px;
	line-height:1.3;
`

const SubTitle = styled.div`
	color:#545454;
	margin-bottom:20px;
	font-size:16px;
`

const Image = styled.img`
	height:150px;
`

const Right = styled.div`
	width:50%;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;

  @media(max-width:768px){
   display:none;
  }
`
