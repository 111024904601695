import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import List from './List.js';
import Details from './Detail.js';

function CategoryStructure() {
    const navigate = useNavigate();
    function BackArrow() {
        navigate('/app/category-compliance/')
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="details/:id" element={<Details />} />
                <Route path="create" element={<Details />} />
            </Routes>
        </>
    );
}

export default CategoryStructure;
