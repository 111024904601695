import Home from "assets/images/navbar/mingcute--home-3-line.svg";
import Organizations from "assets/images/navbar/fluent--organization-12-regular.svg";
import Calendar from "assets/images/navbar/ph--calendar-bold.svg";

import Categories from "assets/images/navbar/ph--square-logo-bold.svg";
import OrganizationCategories from "assets/images/navbar/tabler--category-2.svg";

import Employees from "assets/images/navbar/charm--people.svg";
import RecordValues from "assets/images/navbar/majesticons--data-line.svg";
import UploadEmployees from "assets/images/navbar/mingcute--upload-3-line.svg";

import Questions from "assets/images/navbar/gravity-ui--file-question.svg";
import EmailTemplates from "assets/images/navbar/mi--email.svg";
import PdfTemplate from "assets/images/navbar/mingcute--pdf-line.svg";
import RecommendationTemplate from "assets/images/navbar/ph--chat-dots-bold.svg";
import ChatGPTRecommendations from "assets/images/navbar/ph--chat-dots-bold.svg";

import ChatGPT from "assets/images/navbar/ph--chat-text-bold.svg";
import ChatGPTCopilot from "assets/images/navbar/lucide--plane.svg";

import Log from "assets/images/navbar/carbon--report.svg";
import WebImages from "assets/images/navbar/material-symbols--imagesmode-outline.svg";

import SettingsNav from "assets/images/navbar/tabler--settings.svg";

import Management from "assets/images/nav/management.svg";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/settings/management",
    title: "Team Management",
    image: Management,
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },

];
export const OtpNav = [];
export const NavData = [
  {
    header: "Main Menu",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/dashboard",
    title: "Dashboard",
    image: Home,
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/organizations",
    title: "Organizations",
    image: Organizations,
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    link: "/app/brief-schedule",
    title: "Survey Schedules",
    image: Calendar,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    header: "Categories",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/category-compliance",
    title: "Categories",
    image: Categories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/categories",
    title: "Organization Categories",
    image: OrganizationCategories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    header: "Employees",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    image: Employees,
    link: "/app/employees",
    title: "Employees",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: RecordValues,
    link: "/app/employee-record-value",
    title: "Employee Records",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },

  {
    link: "/app/user-uploads",
    title: "Upload Employees",
    image: UploadEmployees,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    header: "Templates",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    image: Questions,
    link: "/app/questions",
    title: "Survey",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: EmailTemplates,
    link: "/app/email-templates",
    title: "Email",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: PdfTemplate,
    link: "/app/pdf-templates",
    title: "PDF",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: RecommendationTemplate,
    link: "/app/recommendation-templates/org-goals",
    title: "Organizational Goals",
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    image: RecommendationTemplate,
    link: "/app/recommendation-templates/team-recs",
    title: "Team Recommendations",
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    header: "ChatGPT",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    image: ChatGPT,
    link: "/app/gpt",
    title: "Prompts and Scoring",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: ChatGPTRecommendations,
    link: "/app/gpt-recommendation",
    title: "Recommendations",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: ChatGPTCopilot,
    link: "/app/gpt-web-chat",
    title: "Copilot",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },

  {
    header: "Utilities",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/activity-log",
    title: "Activity Log",
    image: Log,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/web-image",
    title: "Web Images",
    image: WebImages,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    header: "Settings",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/settings",
    title: "Account Settings",
    image: SettingsNav,
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },

];

export default NavData;
