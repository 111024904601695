import {
  GET_BLOGS,
  UPDATE_BLOG_PAGE_NUMBER,
  DELETE_BLOG,
  FETCH_BLOG,
  EDIT_BLOG,
  UPDATE_BLOG_PIC,
  ADD_BLOG_TAG,
  ADD_BLOG,
  CLEAR_BLOG,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  blogs: [],
  created: false,
  pageNumber: 1,
  // blog_pics: [],
  pending: false,
};

export default function blog(state = initialState, action) {
  switch (action.type) {
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
        pending: false,
      };
    case UPDATE_BLOG_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
    case UPDATE_BLOG_PIC:
      let _created = false;

      if (state.created) {
        _created = { ...state.created, image: action.payload.image };
      }
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          image: action.payload.image,
        },
        created: _created,
        pending: false,
      };

    case ADD_BLOG_TAG:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          tags: action.payload.tags,
        },
        pending: false,
      };

    case FETCH_BLOG:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_BLOG:
      return { ...state, [action.payload.id]: action.payload };

    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((row) => row.id !== action.payload),
      };

    case ADD_BLOG:
      return {
        ...state,
        blogs: [...state.blogs, action.payload],
        created: action.payload,
      };

    case CLEAR_BLOG:
      return {
        ...state,
        blogs: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        blogs: [],
      };

    default:
      return state;
  }
}
