import React from 'react'
import {Route, Routes} from 'react-router-dom';

import SelectOrg from "areas/authentication/components/SelectOrg"

import {Header} from 'semantic-ui-react'

function OrganizationLogin() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Select Different Org
        <Header.Subheader>
          List of All Organization you can login in into.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<SelectOrg />} />
        <Route path="/select-org" element={<SelectOrg  userInputRequired={true} />} />
      </Routes>
    </>
  );
}

export default OrganizationLogin;
