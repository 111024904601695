import {
    UPDATE_WEB_API_LOG_PAGE_NUMBER,
    WEB_API_LOG_PENDING,
    GET_WEB_API_LOGS,
    DELETE_WEB_API_LOG,
    FETCH_WEB_API_LOG,
    EDIT_WEB_API_LOG,
    ADD_WEB_API_LOG,
    CLEAR_WEB_API_LOG,
    CLEAR_ALL,
    } from "common/constants/actions";
  
  const initialState = {
    web_api_log: [],
    pageNumber: 1,
    created: false,
    updated: false,
    pending: false,
  };
  
  export default function AppUrlList(state = initialState, action) {
    switch (action.type) {
      case UPDATE_WEB_API_LOG_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
      case WEB_API_LOG_PENDING:
          return {
            ...state,
            pending: true,
          }
      case GET_WEB_API_LOGS:
        return {
          ...state,
          web_api_log: action.payload,
          updated: false,
          pending: false,
        };
      case FETCH_WEB_API_LOG:
        return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false 
        };
      case EDIT_WEB_API_LOG:
        return {
          ...state,
          [action.payload.id]: action.payload,
          updated: action.payload,
          pending: false,
        };
      case DELETE_WEB_API_LOG:
        return {
          ...state,
          web_api_log: state.web_api_log.filter((row) => row.id !== action.payload),
          updated: true,
          pending: false,
        };
      case ADD_WEB_API_LOG:
        return {
          ...state,
          created: action.payload,
          updated: action.payload,
          pending: false,
        };
      case CLEAR_WEB_API_LOG:
        return {
          ...state,
          web_api_log: [],
          pageNumber: 1,
          created: false,
          updated: false,
          pending: false,
        };
      case CLEAR_ALL:
        return {
          ...state,
          web_hook_log: [],
          pageNumber: 1,
          created: false,
          updated: false,
          pending: false,
        };
      default:
        return state;
    }
  }
