import { Button, Icon, Table, Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { format } from 'date-fns'

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SG_DELETE_REPORT_TEMPLATE } from 'common/constants/actions';
import { RecommendationType } from "common/constants/global";

export function RecommendationListTable(templateType) {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [checklists, setRecommendationTemplates] = useState([]);

    const typeName = templateType === RecommendationType.ORGANIZATIONAL_GOALS ? 'Goal' : 'Recommendation'
    const detailPath = templateType === RecommendationType.ORGANIZATIONAL_GOALS ? 'org-goals' : 'team-recs'

    const { get_report_template } = useSelector(
        (state) => ({
            get_report_template: state.report_template,
        }),
        shallowEqual
    );

    const navigateToDetails = (checklistId = null) => {
      if (checklistId) {
        navigate(`/app/recommendation-templates/${detailPath}/details/${checklistId}`);
      } else {
        navigate(`/app/recommendation-templates/${detailPath}/details/create`);
      }
    };

    useEffect(() => {
        let useData = get_report_template?.report_template?.results

        if (useData) {
            const mappedChecklists = useData
                .filter(item => item.type_of === templateType)
                .map(item => ({
                    id: item.id,
                    title: item.template.title,
                    description: item.template.description,
                    lastModified: item.updated_at,
                }));
            setRecommendationTemplates(mappedChecklists);
        }
    }, [get_report_template, templateType]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [checklistToDelete, setDeleteId] = useState(null);

    const handleDelete = (checklistId) => {
        setDeleteId(checklistId);
        setConfirmOpen(true);
    };

    const confirmDelete = () => {
        setRecommendationTemplates(checklists.filter((c) => c.id !== checklistToDelete));
        setConfirmOpen(false);

        dispatch({
            type: SG_DELETE_REPORT_TEMPLATE,
            payload: { id: checklistToDelete },
        });

        setDeleteId(null);
    };

    return <>
        <Table celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}>Title</Table.HeaderCell>
                    <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Last Modified</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign="center">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {checklists
                    .slice()
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((checklist) => (
                        <Table.Row key={checklist.id} onClick={() => navigateToDetails(checklist.id)} style={{ cursor: 'pointer' }}>
                            <Table.Cell>{checklist.title}</Table.Cell>
                            <Table.Cell title={checklist.description}>
                                {checklist.description.length > 150
                                    ? `${checklist.description.substring(0, 150)}...`
                                    : checklist.description}
                            </Table.Cell>
                            <Table.Cell>{checklist?.lastModified && format(new Date(checklist?.lastModified), 'MMM. d, yyyy h:mm a')}</Table.Cell>
                            <Table.Cell textAlign="center">
                                <IconButton
                                    icon
                                    title={"Delete " + typeName}
                                    onClick={(e) => {
                                        e.stopPropagation(); // prevent row's onClick from firing
                                        handleDelete(checklist.id);
                                    }}>
                                    <Icon name="trash alternate" color="grey" />
                                </IconButton>
                            </Table.Cell>
                        </Table.Row>
                    ))}
            </Table.Body>
        </Table>
        <Button primary onClick={() => navigateToDetails()} style={{ marginBottom: '20px' }}>
            <Icon name="plus" />
            {"Create " + typeName }
        </Button>

        <Confirm
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={confirmDelete}
            content={"Are you sure you want to delete this " + typeName.toLowerCase() + "?"}
        />
    </>
}

const IconButton = styled(Button)`
  padding: 8px !important;
  background-color: transparent !important;
  box-shadow: none !important;
`;
