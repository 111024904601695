import React, { useState, useEffect } from "react";
import { Button, Modal, Header } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_EDIT_SURVEY_STRUCTURE } from "common/constants/actions";

const JSONEditorModal = ({
  surveyStructureId,
  openNewJson,
  setOpenNewJson,
}) => {
  const dispatch = useDispatch();
  const [surveyStrucRow, setSurveyStrucRow] = useState([]);
  const [jsonValue, setJsonValue] = useState("");
  const [validationSummary, setValidationSummary] = useState("");
  const [isValid, setIsValid] = useState("");

  const { get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (surveyStructureId) {
      // this should run once
      if (get_survey_structure?.[surveyStructureId]?.categories) {
        setSurveyStrucRow(
          JSON.stringify(
            get_survey_structure?.[surveyStructureId]?.categories,
            null,
            2
          )
        );
        // setJsonValue(get_survey_structure?.[surveyStructureId]?.categories);
      }
    }
  }, [get_survey_structure, surveyStructureId]);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    // Validate and save the JSON after a delay
    setTimeout(() => {
        setSurveyStrucRow(pastedData);
    }, 2000); // Adjust the delay as needed
  };

  const validateJson = (data) => {
    setSurveyStrucRow(data);
    try {
      JSON.parse(data);
      setValidationSummary("JSON is valid!");
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
      console.error("Error parsing pasted JSON:", error);
      setValidationSummary(`JSON is not valid: ${error.message}`);
    }
  };

  function onSave() {
    setOpenNewJson(false);
    if (surveyStructureId && isValid) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          categories: JSON.parse(surveyStrucRow),
        },
      });
    }
  }

  function onClose() {
    setOpenNewJson(false);
  }

  return (
    <Modal open={openNewJson} onClose={onClose}>
      <Modal.Header>
        <Header as="h1">
          JSON Editor
          <Header.Subheader>
            Create or Edit the entire JSON Category file
          </Header.Subheader>
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <textarea
            placeholder="Paste your JSON here..."
            value={surveyStrucRow}
            onPaste={handlePaste}
            onChange={(e) => validateJson(e.target.value)}
            style={{ width: "100%", height: "100%", minHeight: "400px" }} // Set styles for full screen
          />

          {validationSummary && (
            <div style={{ color: isValid ? "green" : "red" }}>
              {validationSummary}
            </div>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onSave} color="violet" disabled={!isValid}>
          Save and Close
        </Button>
        <Button onClick={onClose} color="red">
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default JSONEditorModal;
