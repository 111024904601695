import {
    UPDATE_ADMIN_DEFAULT_PAGE_NUMBER,
    GET_ADMIN_DEFAULTS,
    FETCH_ADMIN_DEFAULT,
    EDIT_ADMIN_DEFAULT,
    ADD_ADMIN_DEFAULT,
    DELETE_ADMIN_DEFAULT,
    CLEAR_ADMIN_DEFAULT,
    CLEAR_ALL,
  } from "common/constants/actions";

  const initialState = {
    admin_default: [],
    pending: false,
    pageNumber: 1,
  };

  export default function SurveyCompliance(state = initialState, action) {
    switch (action.type) {
      case GET_ADMIN_DEFAULTS:
        return {
          ...state,
          admin_default: action.payload,
          pending: false,
        };
      case UPDATE_ADMIN_DEFAULT_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
      case FETCH_ADMIN_DEFAULT:
        return { ...state, [action.payload.id]: action.payload, pending: false };

      case EDIT_ADMIN_DEFAULT:
        return { ...state, [action.payload.id]: action.payload, pending: false };

      case DELETE_ADMIN_DEFAULT:

        return {
          ...state,
          admin_default: state.admin_default.filter(
            (row) => row.id !== action.payload.id
          ),
        };

      case ADD_ADMIN_DEFAULT:
        return {
          ...state,
          admin_default: [...state.admin_default, action.payload],
        };

      case CLEAR_ADMIN_DEFAULT:
        return {
          ...state,
          admin_default: [],
          pageNumber: 1,
        };

      case CLEAR_ALL:
        return {
          ...state,
          admin_default: [],
          pageNumber: 1,
        };

      default:
        return state;
    }
  }
