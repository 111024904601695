import React from 'react';
import { Button, Form, Icon, Input, Segment, Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const PhaseTab = ({
  phaseIndex,
  addActivity,
  phaseData,
  updateActivityTitle,
  updateTaskInfo,
  addTask,
  removeTask,
  removeActivity,
  openResourceModal
}) => {
  return <>
    {phaseData.activities.map((activityData, activityIndex) => (
      <ActivityContainer key={activityIndex} style={{ backgroundColor: '#f7f7f7', padding: '10px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Form style={{ padding: '5px' }}>
          <Form.Field>
            <label >Activity Title</label>
            <Input
              fluid
              value={activityData.activityTitle}
              onChange={(e) => updateActivityTitle(phaseIndex, activityIndex, e.target.value)}
              style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            />
          </Form.Field>
          <Table celled structured style={{ borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={7}>Task</Table.HeaderCell>
                <Table.HeaderCell width={4}>Completion Metric</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">Resources</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {activityData.tasks.map((task, taskIndex) => (
                <Table.Row key={taskIndex}>
                  <Table.Cell>
                    <Form.TextArea
                      value={task.task}
                      onChange={(e) => updateTaskInfo(phaseIndex, activityIndex, taskIndex, 'task', e.target.value)}
                      style={{ width: '100%', height: '40px', padding: '5px' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={task.metric}
                      onChange={(e) => updateTaskInfo(phaseIndex, activityIndex, taskIndex, 'metric', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      basic
                      content={`Manage (${task.resources.length})`}
                      onClick={() => openResourceModal(phaseIndex, activityIndex, taskIndex, task.resources)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <IconButton
                      icon
                      title="Delete Task"
                      onClick={
                        (e) => {
                          e.stopPropagation();         // prevent row's onClick from firing
                          removeTask(phaseIndex, activityIndex, taskIndex)
                        }
                      }>
                      <Icon name="trash alternate" color="grey" />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell colSpan="4" textAlign="left">
                  <Button icon labelPosition="left" primary onClick={() => addTask(phaseIndex, activityIndex)}>
                    <Icon name="plus" />
                    Add Task
                  </Button>
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button
            icon
            labelPosition="left"
            onClick={() => { removeActivity(phaseIndex, activityIndex); }}
            style={{ marginLeft: '10px', display: 'flex', justifyContent: 'right' }}
          >
            Delete Activity
            <Icon name="trash alternate" />
          </Button>
        </div>

      </ActivityContainer>
    ))}
    <Button icon labelPosition="left" primary onClick={() => addActivity(phaseIndex)}>
      <Icon name="plus" />
      Add Activity
    </Button>
  </>
};

const ActivityContainer = styled(Segment)`
    margin-bottom: 20px !important;
`;

const IconButton = styled(Button)`
    padding: 8px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #d9534f !important;
`;