import React from "react";
import { Navigate } from "react-router-dom";

import AuthLayout from "common/layouts/AuthLayout";
import HomeLayout from "common/layouts/HomeLayout";
import Lost from "areas/404/components";
import NoAccess from "areas/404/components/NoAccess";
import Login from "areas/authentication/components/Login";
import Logout from "areas/authentication/components/Logout";
import SwitchOrg from "areas/authentication/components/SwitchOrg";
import Account from "areas/authentication/components";
import Dashboard from "areas/dashboard/components";
import ActivityLog from "areas/activity-log/components";
import Organizations from "areas/organizations/components";
import Employees from "areas/employees/components";
import EmployeeRecord from "areas/employees/components/employee-record";
import EmployeeLog from "areas/employees/components/employee-log";
import EmployeeRecordValue from "areas/employee-record-value/components";
import Questions from "areas/questions/components";
import Categories from "areas/categories/components";
import CategoryCompliance from "areas/category-compliance/components";
import UserUpload from "areas/user-upload/components"
import EmailTemplate from "areas/email-templates/components";
import PdfTemplate from "areas/pdf-templates/components";
import Settings from "areas/settings/components";
import BriefScheduled from "areas/survey-schedules/components";
import WebImage from "areas/web-image/components";
import Gpt from "areas/gpt/components";
import GptRecommendation from "areas/gpt-recommendations/components";
import GptWebChat from "areas/gpt-web-chat/components";

import ReportTemplates from "areas/report-templates/components"
import OrganzationalIndex from "areas/recommendation-templates/org-goals"
import TeamIndex from "areas/recommendation-templates/team-recs"
import MemberSettings from "../areas/settings/components/user-settings";

const routes = [
  {
    path: "app",
    element: <AuthLayout />,
    children: [
      { path: "dashboard/*", element: <Dashboard /> },
      { path: "activity-log/*", element: <ActivityLog /> },
      { path: "organizations/*", element: <Organizations /> },
      { path: "employees/*", element: <Employees /> },
      { path: "employee-record/*", element: <EmployeeRecord /> },
      { path: "employee-log/*", element: <EmployeeLog /> },
      { path: "employee-record-value/*", element: <EmployeeRecordValue /> },
      { path: "questions/*", element: <Questions /> },
      { path: "categories/*", element: <Categories /> },
      { path: "gpt/*", element: <Gpt /> },
      { path: "user-uploads/*", element: <UserUpload /> },
      { path: "settings/*", element: <Settings /> },
      { path: "email-templates/*", element: <EmailTemplate /> },
      { path: "pdf-templates/*", element: <PdfTemplate /> },
      { path: "brief-schedule/*", element: <BriefScheduled /> },
      { path: "category-compliance/*", element: <CategoryCompliance /> },
      { path: "web-image/*", element: <WebImage /> },
      { path: "gpt-recommendation/*", element: <GptRecommendation /> },
      { path: "gpt-web-chat/*", element: <GptWebChat /> },
      { path: "report-templates/*", element: <ReportTemplates /> },
      { path: "recommendation-templates/org-goals/*", element: <OrganzationalIndex /> },
      { path: "recommendation-templates/team-recs/*", element: <TeamIndex /> },
      { path: "404", element: <Lost /> },
      { path: "switch-org", element: <SwitchOrg /> },
      { path: "no-access", element: <NoAccess /> },
      { path: "*", element: <Navigate to="/app/404" /> },
    ],
  },
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "", element: <Login /> },
      { path: "logout", element: <Logout /> },
      { path: "account/*", element: <Account /> },
      { path: "404", element: <Lost /> },
      { path: "no-access", element: <NoAccess /> },
      // { path: '*', element: <Navigate to="/404" /> }
    ],
  },
  {
    path: "/settings",
    element: <AuthLayout/>,
    children: [
      {
        path: "member", // profile page
        element: <MemberSettings/>,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ]
  }
];

export default routes;
