import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Form,  Input, Tab, TextArea } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'

import PageTitle, { Container } from "common/layouts/AuthLayout/PageTitle"
import { RecommendationType } from 'common/constants/global';
import { ResourceModal } from '../ResourceModal'
import { PhaseTab } from '../PhaseTab'

import { useDispatch, useSelector } from 'react-redux';
import {
  SG_ADD_REPORT_TEMPLATE,
  SG_CLEAR_REPORT_TEMPLATE,
  SG_EDIT_REPORT_TEMPLATE,
  SG_FETCH_REPORT_TEMPLATE,
  SG_GET_SURVEY_QUESTIONS,
} from 'common/constants/actions';

const generateFactorsList = (questions) => {
  const factors = questions.factors;
  const factorsList = factors.map((factor) => {
    return {
      key: factor.id,
      text: factor.title,
      value: { id: factor.id, title: factor.title }
    };
  });
  return factorsList;
}

const TeamRecommendation = ({ templateType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: templateId } = useParams();
  const { addToast } = useToasts();

  const initialState = {
    title: '',
    description: '',
    phases: [
      { activities: [] },
      { activities: [] },
      { activities: [] }
    ],
    associations: '',
    context: {
      whyRecommendation: '',
      focusAreas: '',
      recommendationQuestion: '',
      organizationQuestions: [],
      effortRequires: [],
      involvedAreas: '',
      desiredOutcome: '',
      timeframe: '',
      cost: '',
      policyChange: '',
      cultureChange: '',
      dataCollection: '',
      communityConnections: '',
      primaryStakeholder: '',
    },
  };

  const { get_report_template, get_questions } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
      get_questions: state.surveyquestion,
    })
  );

  const [checklist, setChecklist] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentResources, setCurrentResources] = useState([]);
  const [currentactivityIndex, setCurrentactivityIndex] = useState(null);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(null);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);
  const [questionStructure, setQuestionStructure] = useState([]);

  useEffect(() => {
    if (get_questions?.survey_question && get_questions.survey_question?.results?.[0]?.questions) {
      setQuestionStructure(generateFactorsList(get_questions.survey_question?.results?.[0]?.questions));
    }
  }, [get_questions]);


  useEffect(() => {
    if (templateId && get_report_template?.[templateId]) {
      const item = get_report_template[templateId];
      setChecklist({
        title: item.name,
        description: item.template.description,
        phases: item.template.phases,
        associations: item.template.associations,
        context: item.template.context,
      });
    }
  }, [templateId, get_report_template]);

  useEffect(() => {
    dispatch({
      type: SG_CLEAR_REPORT_TEMPLATE,
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `status=2&survey_type=7`
    });
  }, [dispatch]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_REPORT_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const saveChecklistTemplate = () => {
    const updateId = templateId || null; 
    const templatePayload = {
      id: updateId || null,  
      name: checklist.title,
      status: 9, 
      template: checklist, 
      type_of: templateType,
    };

    if (updateId) {
      dispatch({
        type: SG_EDIT_REPORT_TEMPLATE,
        payload: templatePayload,
      });
    } else {
      dispatch({
        type: SG_ADD_REPORT_TEMPLATE,
        payload: templatePayload,
      });
    }

    addToast("Team Recommendation Saved", {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 1500
    });
    navigate('/app/recommendation-templates/team-recs');
  };

  const addActivity = (phaseIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities.push({
      activityTitle: '',
      tasks: [],
    });
    setChecklist({
      ...checklist,
      phases: updatedPhases
    });
  };
  const removeActivity = (phaseIndex, activityIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities = checklist.phases[phaseIndex].activities.filter((_, i) => i !== activityIndex);
    setChecklist({ ...checklist, phases: updatedPhases, });
  };

  const addTask = (phaseIndex, activityIndex) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks.push({ task: '', metric: '', resources: [] });
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const removeTask = (phaseIndex, activityIndex, taskIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks = updatedPhases[phaseIndex].activities[activityIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const openResourceModal = (phaseIndex, activityIndex, taskIndex, resources) => {
    // This could be modal props
    setCurrentPhaseIndex(phaseIndex);
    setCurrentactivityIndex(activityIndex);
    setCurrentTaskIndex(taskIndex);
    setCurrentResources(resources);
    setModalOpen(true);
  };

  const addResource = (phaseIndex, newResource) => {
    const updatedResources = [...currentResources, newResource];
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const removeResource = (phaseIndex, resourceIndex) => {
    const updatedResources = currentResources.filter((_, i) => i !== resourceIndex);
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updateResourceInfo = (phaseIndex, resourceIndex, field, value) => {
    const updatedResources = [...currentResources];
    updatedResources[resourceIndex][field] = value;
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updateActivityTitle = (phaseIndex, activityIndex, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].activityTitle = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const updateTaskInfo = (phaseIndex, activityIndex, taskIndex, field, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks[taskIndex][field] = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };


  // Define the panes with individual phase tabs
  const panes = [
    // Phase 1 Tab
    {
      menuItem: 'Discover',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={0}
            addActivity={addActivity}
            phaseData={checklist.phases[0]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Phase 2 Tab
    {
      menuItem: 'Design',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={1}
            addActivity={addActivity}
            phaseData={checklist.phases[1]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Phase 3 Tab
    {
      menuItem: 'Deploy',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={2}
            addActivity={addActivity}
            phaseData={checklist.phases[2]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Context Fields Tab
    {
      menuItem: 'Context Fields',
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Field>
              <label>Why make this recommendation?</label>
              <TextArea
                value={checklist.context.whyRecommendation}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      whyRecommendation: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Focus Areas</label>
              <TextArea
                value={checklist.context.focusAreas}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      focusAreas: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Tab.Pane>
      ),
    },
    // Associations Tab (updated)
    {
      menuItem: 'Associations',
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Field>
              <label>Parent Org. Goal</label>
              <Dropdown
                fluid
                selection
                options={get_report_template?.report_template?.results
                  .filter(item => item.type_of === RecommendationType.ORGANIZATIONAL_GOALS)
                  .map(option => ({
                    key: option.id,
                    text: option.name,
                    value: JSON.stringify({ id: option.id, name: option.name })
                  }))}

                value={JSON.stringify(checklist.associations.organizationalGoal)}
                onChange={(e, { value }) =>
                  setChecklist({
                    ...checklist,
                    associations: {
                      ...checklist.associations,
                      organizationalGoal: JSON.parse(value),
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Factor</label>
              <Dropdown
                fluid
                selection
                options={questionStructure.map(option => ({
                  key: option.id,
                  text: option.text,
                  value: JSON.stringify(option.value),
                }))}
                value={JSON.stringify(checklist.associations.factor)}
                onChange={(e, { value }) =>
                  setChecklist({
                    ...checklist,
                    associations: {
                      ...checklist.associations,
                      factor: JSON.parse(value),
                    },
                  })}
              />
            </Form.Field>
            <Form.Field>
              <label>Release with Parent Org. Goal Phase</label>
              <Dropdown
                fluid
                selection
                options={[
                  { key: 'discover', text: 'Discover', value: 'discover' },
                  { key: 'design', text: 'Design', value: 'design' },
                  { key: 'deploy', text: 'Deploy', value: 'deploy' },
                ]}
                value={checklist.associations.releaseOnParentGoal}
                onChange={(e, { value }) =>
                  setChecklist({
                    ...checklist,
                    associations: {
                      ...checklist.associations,
                      releaseOnParentGoal: value,
                    },
                  })}
              />
            </Form.Field>
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  function onResourceModalClosed() {
    setModalOpen(false)
  }

  return (
    <Container>
      <PageTitle newPageTitle="Team Recommendation"/>

      <StyledForm>
        <Form.Field>
          <label>Title</label>
          <Input
            value={checklist.title}
            onChange={(e) => setChecklist({ ...checklist, title: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <TextArea
            value={checklist.description}
            onChange={(e) => setChecklist({ ...checklist, description: e.target.value })}
          />
        </Form.Field>
      </StyledForm>

      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />

      <ResourceModal
        phaseIndex={currentPhaseIndex}
        open={modalOpen}
        onClose={onResourceModalClosed}
        resources={currentResources}
        addResource={addResource}
        removeResource={removeResource}
        updateResourceInfo={updateResourceInfo}
      />

      <SaveContainer>
        <Button onClick={() => navigate('/app/recommendation-templates/team-recs')}>
          Cancel
        </Button>
        <Button primary onClick={saveChecklistTemplate} disabled={!checklist.title}>
          Save Recommendation
        </Button>
      </SaveContainer>
    </Container>
  );
};

export default TeamRecommendation;

const StyledForm = styled(Form)`
    margin-bottom: 20px;
`;

const SaveContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
