import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";



import {
    SG_LOGIN_GOOGLE_AUTH,
    SG_REGISTER_GOOGLE_AUTH,
    SG_REGISTER_GOOGLE_AUTH_TOKEN,


} from "common/constants/actions";

import { DomainContext } from 'common/layouts/LayoutContext';

function GoogleAuth({
    login = false,
    organizationName = false,
    signUpToken = false }) {
    const dispatch = useDispatch();
    const Domain = useContext(DomainContext);

    useEffect(() => {
        if (window.google) {
            // need this to initialuze google for linter to ignore
            /* global google */
            google.accounts.id.initialize({
                client_id: Domain.googleClientId,
                callback: onSuccess
            })

            google.accounts.id.renderButton(
                document.getElementById("googleSignInDiv"),
                {
                    theme: "outline",
                    size: "large"
                }
            )
            // google.accounts.id.prompt(); // also display the One Tap dialog
        }

    });

    const onSuccess = (res) => {
        // console.log('success:', res);
        const GToken = res?.credential

        googleAuthServerSignIn(GToken)

    };

    const [loginData, setLoginData] = useState({});
    // LoadGoogleScripts()

    const { get_auth } = useSelector(
        (state) => ({
            get_auth: state.auth
        }),
        shallowEqual
    );




    async function googleAuthServerSignIn(token) {
        // simple login
        if (login) {
            dispatch({
                type: SG_LOGIN_GOOGLE_AUTH,
                payload: {
                    token: token
                },
            });
        }
        // register new org
        if (organizationName) {
            dispatch({
                type: SG_REGISTER_GOOGLE_AUTH,
                payload: {
                    token: token,
                    company_name: organizationName
                },
            });
        }
        // emp registration
        if (signUpToken) {
            dispatch({
                type: SG_REGISTER_GOOGLE_AUTH_TOKEN,
                payload: {
                    token: token,
                    employee_token: signUpToken
                },
            });
        }

    };



    useEffect(() => {
        setLoginData(get_auth)
        // console.log(get_auth)
    }, [get_auth]);



    return (
        <div>
            {loginData?.access_token ? (
                <div>
                    <h3>
                        {loginData?.employee?.first_name} logged in as {loginData?.employee?.email}
                    </h3>

                    {/* <GoogleLogout
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText={`Logout ${loginData?.employee?.email}`}
                        onLogoutSuccess={handleLogout}
                    >
                    </GoogleLogout> */}
                    <div id="googleSignInDiv">
                    </div>

                </div>
            ) : (
                <>
                    <div id="googleSignInDiv">
                    </div>

                    {/*
                    <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText="Sign in with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                        // isSignedIn={true}
                        prompt="select_account"
                    /> */}
                </>

            )}
        </div>

    );
}

export default GoogleAuth;
