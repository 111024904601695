import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Card, Grid, Header, Icon} from "semantic-ui-react";
import PageTitle, {Container} from "common/layouts/AuthLayout/PageTitle"

import {format} from "date-fns";

import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SG_FETCH_DEBRIEF_SCHEDULE, SG_GET_CHAT_GPT, SG_GET_ORGANIZATION_SELECT,} from "common/constants/actions";

import EmpList from "./EmpList";
import ExtendSurvey from "./ExtendSurvey";
import BasicErrorMessage from "common/utils/ErrorMessages";
import ResponseList from "common/components/brief-response/List";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [deBriefId, setDebriefId] = useState(null);
  const [debriefDetails, setDebriefDetails] = useState(null);
  const [empUpdateRow, setEmpUpdateRow] = useState(true);
  const [searchTerm, setSearchTerm] = useState(false);

  function Back() {
    navigate(-1);
  }
  useEffect(() => {
    setDebriefId(id);
  }, [id]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (deBriefId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: { id: deBriefId },
      });
      dispatch({
        type: SG_GET_CHAT_GPT,
        payload: `debrief-schedule-id=${deBriefId}`,
      });
    }
  }, [dispatch, deBriefId]);

  const { get_brief_schedules, get_organization_select, getchat_gpt } =
    useSelector(
      (state) => ({
        get_brief_schedules: state.debrief_schedule,
        get_organization_select: state.organizations.organization_select,
        getchat_gpt: state.chat_gpt,
      }),
      shallowEqual
    );

  useEffect(() => {
    setSearchTerm(get_brief_schedules.search_term);
  }, [get_brief_schedules]);

  useEffect(() => {
    setDebriefDetails(get_brief_schedules?.[deBriefId]);
  }, [get_brief_schedules, deBriefId]);

  return (
    <Container>
      <PageTitle
        newPageTitle="Survey Schedule Details"
      />

      <Grid>
        <Grid.Row>
          <Grid.Column floated="left" width={13}>
            <Header as="h2">
              <Icon style={{ cursor: "pointer" }} onClick={Back} name="arrow left" />
              {debriefDetails?.name}{" - "}
              {debriefDetails?.scheduled_for &&
                format(
                  new Date(debriefDetails?.scheduled_for),
                  "MMM. d, yyyy"
                )}
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" width={3}>
            {debriefDetails?.status > 3 && (
              <ExtendSurvey debriefDetails={debriefDetails} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <BasicErrorMessage />

      <Card fluid key={debriefDetails?.id}>
        <Card.Content>
          <CardContainer>
            <ListContainer>
              <HeaderDiv>Organization</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.organization &&
                  get_organization_select.filter(
                    (org) => org.id === debriefDetails?.organization
                  )?.[0]?.name}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Survey Questions</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.brief_question?.name} (
                {debriefDetails?.brief_question?.sort_order}){" - "}
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/app/questions/details/${debriefDetails?.brief_question?.id}`
                    )
                  }
                  color="grey"
                  name="external alternate"
                />
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Survey Type</HeaderDiv>
              <ContentDiv>{debriefDetails?.type_of_text}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Time Zone</HeaderDiv>
              <ContentDiv>{debriefDetails?.time_zone}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status</HeaderDiv>
              <ContentDiv>{debriefDetails?.status_text}</ContentDiv>
            </ListContainer>
          </CardContainer>
          <CardContainer>
            <ListContainer>
              <HeaderDiv>Scheduled Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.scheduled_for &&
                  format(
                    new Date(debriefDetails?.scheduled_for),
                    "MMM. d, yyyy hh:mm a"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Close Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.close_at &&
                  format(
                    new Date(debriefDetails?.close_at),
                    "MMM. d, yyyy hh:mm a"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Invitations</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_requests}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Reponses</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_responses}</ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.created_at &&
                  format(
                    new Date(debriefDetails?.created_at),
                    "MMM. d, yyyy hh:mm a"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Survey Id</HeaderDiv>
              <ContentDiv>{debriefDetails?.id}</ContentDiv>
            </ListContainer>
          </CardContainer>
        </Card.Content>
      </Card>

      <ResponseList
        deBriefId={deBriefId}
        empUpdateRow={empUpdateRow}
        setEmpUpdateRow={setEmpUpdateRow}
        searchTerm={searchTerm}
      />

      <CardSectionDiv>
        {!searchTerm && (
          <ContentSplitRow>
            <ContentSplitCol>
              <Header sub>Members included and completed status</Header>
              <EmpList
                rows={debriefDetails?.request_list}
                completed={debriefDetails?.compliance}
              />
            </ContentSplitCol>
          </ContentSplitRow>
        )}
      </CardSectionDiv>
    </Container>
  );
};

export default Details;

const CardContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CardSectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
`;
const ContentSplitRow = styled.div`
  display: flex;
`;

const ContentSplitCol = styled.div`
  flex: 1;

  &:first-child {
    width: 10%;
  }

  &:last-child {
    width: 90%;
  }
`;
