import {
  UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER,
  WEB_API_CREDENTIAL_PENDING,
  GET_WEB_API_CREDENTIALS,
  GET_WEB_API_CRED_LIST,
  DELETE_WEB_API_CREDENTIAL,
  FETCH_WEB_API_CREDENTIAL,
  EDIT_WEB_API_CREDENTIAL,
  ADD_WEB_API_CREDENTIAL,
  CLEAR_WEB_API_CREDENTIAL,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  web_api_credential: [],
  web_api_cred_list: [],
  pageNumber: 1,
  created: false,
  updated: false,
  pending: false,
};

export default function AppUrlList(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    case WEB_API_CREDENTIAL_PENDING:
      return {
        ...state,
        pending: true,
      }
    case GET_WEB_API_CREDENTIALS:
      return {
        ...state,
        web_api_credential: action.payload,
        updated: false,
        pending: false,
      };
    case GET_WEB_API_CRED_LIST:
      return {
        ...state,
        web_api_cred_list: action.payload,
        updated: false,
        pending: false,
      };
    case FETCH_WEB_API_CREDENTIAL:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_WEB_API_CREDENTIAL:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };
    case DELETE_WEB_API_CREDENTIAL:
      return {
        ...state,
        web_api_credential: state.web_api_credential.filter((row) => row.id !== action.payload),
        updated: true,
        pending: false,
      };

    case ADD_WEB_API_CREDENTIAL:
      return {
        ...state,
        created: action.payload,
        pending: false,
      };

    case CLEAR_WEB_API_CREDENTIAL:
      return {
        ...state,
        web_api_credential: [],
        pageNumber: 1,
        created: false,
        updated: false,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        web_api_credential: [],
        pageNumber: 1,
        created: false,
        updated: false,
        pending: false,
      };

    default:
      return state;
  }
}
