import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Select from "react-select";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message } from 'semantic-ui-react'

import {
  SG_GET_ORGANIZATION_SELECT,
  PULL_ORGANIZATION,
  GET_AM_ORGANIZATION,
} from "common/constants/actions";

const QUERY_PARAMS = "organization"

const OrganizationSelect = ({ setResetPageLoad }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  let organization_id = searchParams.get(QUERY_PARAMS);

  const newQueryParameters = new URLSearchParams();

  const [organizationId, setOrganizationId] = useState(false)
  const [organizationSelect, setOrganizationSelect] = useState([])
  const [organizationSelected, setOrganizationSelected] = useState([])
  const [organizationDetails, setOrganizationDetails] = useState(null);

  const { get_selectedOrg,
    get_organization_select, } = useSelector(
      (state) => ({
        get_organization_select: state.organizations.organization_select,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT
    })
    if (organizationId > 0) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationId }
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (organization_id) {
      setOrganizationId(organization_id)
    } else {
      setOrganizationId(get_selectedOrg?.organization?.id)
    }
    setOrganizationDetails(get_selectedOrg?.organization)

  }, [organization_id, get_selectedOrg]);

  useEffect(() => {
    setOrganizationSelect(get_organization_select)
    if (organizationId !== organizationSelected) {
      setOrganizationSelected(get_organization_select.filter(
        org => org.id === Number(organizationId))?.[0])
    }
  }, [get_organization_select, organizationId, organizationSelected]);

  /*   TP: todo: redunant?
    useEffect(() => {
      setOrganizationDetails(get_selectedOrg?.organization)
    }, [get_selectedOrg]);
   */

  function ResetOrganization() {
    dispatch({
      type: GET_AM_ORGANIZATION,
      payload: null
    });
    navigate(`${window.location.pathname}`)
    setResetPageLoad(Math.round((new Date()).getTime() / 1000))
  }

  function ChangedOrganization(e) {
    dispatch({
      type: GET_AM_ORGANIZATION,
      payload: e
    });
    newQueryParameters.set(QUERY_PARAMS, e.id)
    setSearchParams(newQueryParameters)
    setResetPageLoad(Math.round((new Date()).getTime() / 1000))
  }

  return (
    <Container>
      {organizationDetails?.id ?
        <Message style={{ height: '40px', padding: '12px', backgroundColor: 'lightBlue' }}
          onDismiss={ResetOrganization}
          header={`${organizationDetails?.name}`}
        /> :
        <Select
          placeholder='Select Organization...'
          style={{ zIndex: 999 }}
          name="survey_preference"
          options={organizationSelect}
          onChange={ChangedOrganization}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option.id}`}
          value={organizationSelected}
        />
      }

    </Container>
  );
};

export default OrganizationSelect;

const Container = styled.div`
  margin-bottom: 10px;
  z-index: 999;
`;
