import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Form, Icon, Input, Label, Modal, Tab, Table, TextArea } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'

import PageTitle, { Container } from "common/layouts/AuthLayout/PageTitle"
import { ResourceModal } from '../ResourceModal'
import { ListItemsInput } from '../ListItemsInput'
import { PhaseTab } from '../PhaseTab'

import { useDispatch, useSelector } from 'react-redux';
import {
  SG_ADD_REPORT_TEMPLATE,
  SG_CLEAR_REPORT_TEMPLATE,
  SG_EDIT_REPORT_TEMPLATE,
  SG_FETCH_REPORT_TEMPLATE,
  SG_GET_SURVEY_QUESTIONS,
} from 'common/constants/actions';

const generateFactorsList = (questions) => {
  const factors = questions.factors;
  const factorsList = factors.map((factor) => {
    return {
      key: factor.id,
      text: factor.title,
      value: { id: factor.id, title: factor.title }
    };
  });
  return factorsList;
}

const OrganizationalGoal = ({ templateType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: templateId } = useParams();
  const { addToast } = useToasts();

  const initialState = {
    title: '',
    description: '',
    phases: [
      { activities: [] },
      { activities: [] },
      { activities: [] }
    ],
    associations: [],
    context: {
      whyRecommendation: '',
      focusAreas: '',
      recommendationQuestion: '',
      organizationQuestions: [],
      effortRequires: [],
      involvedAreas: '',
      desiredOutcome: '',
      timeframe: '',
      cost: '',
      policyChange: '',
      cultureChange: '',
      dataCollection: '',
      communityConnections: '',
      primaryStakeholder: '',
    },
  };

  const { get_report_template, get_questions } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
      get_questions: state.surveyquestion,
    })
  );

  const [checklist, setChecklist] = useState(initialState);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentResources, setCurrentResources] = useState([]);
  const [currentactivityIndex, setCurrentactivityIndex] = useState(null);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(null);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  const [associationModalOpen, setAssociationModalOpen] = useState(false);
  const [associationToEdit, setAssociationToEdit] = useState(null);
  const [questionStructure, setQuestionStructure] = useState([]);

  const openAssociationModal = (association = null) => {
    setAssociationToEdit(association);
    setAssociationModalOpen(true);
  };

  const closeAssociationModal = () => {
    setAssociationModalOpen(false);
    setNewAssociationFactor('');
    setNewAssociationPriority('');
    setAssociationSummary('');
    setAssociationMoreInfo([]);
    setAssociationToEdit(null);
  };

  const [newAssociationFactor, setNewAssociationFactor] = useState('');
  const [newAssociationPriority, setNewAssociationPriority] = useState('');
  const [associationSummary, setAssociationSummary] = useState('');
  const [associationMoreInfo, setAssociationMoreInfo] = useState([]);

  useEffect(() => {
    if (get_questions?.survey_question && get_questions.survey_question?.results?.[0]?.questions) {
      setQuestionStructure(generateFactorsList(get_questions.survey_question?.results?.[0]?.questions));
    }
  }, [get_questions]);

  useEffect(() => {
    if (templateId && get_report_template?.[templateId]) {
      const item = get_report_template[templateId];
      setChecklist({
        title: item.name,
        description: item.template.description,
        phases: item.template.phases,
        associations: item.template.associations,
        context: item.template.context,
      });
    }
  }, [templateId, get_report_template]);

  useEffect(() => {
    dispatch({
      type: SG_CLEAR_REPORT_TEMPLATE,
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `status=2&survey_type=7`
    });
  }, [dispatch]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_REPORT_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const saveChecklistTemplate = () => {
    const updateId = templateId || null;
    const templatePayload = {
      id: updateId || null,
      name: checklist.title,
      status: 9,
      template: checklist,
      type_of: templateType,
    };

    if (updateId) {
      dispatch({
        type: SG_EDIT_REPORT_TEMPLATE,
        payload: templatePayload,
      });
    } else {
      dispatch({
        type: SG_ADD_REPORT_TEMPLATE,
        payload: templatePayload,
      });
    }

    addToast("Organizational Goal Saved", {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 1500
    });
    navigate('/app/recommendation-templates/org-goals');
  };

  const handleSaveGoalAssociation = () => {
    if (newAssociationFactor && newAssociationPriority) {
      const updatedAssociation = {
        factor: newAssociationFactor,
        priority: newAssociationPriority,
        summaryText: associationSummary,
        moreInfo: associationMoreInfo,
      };

      if (associationToEdit) {
        const updatedAssociations = checklist.associations.map((assoc) =>
          assoc === associationToEdit ? updatedAssociation : assoc
        );
        setChecklist({ ...checklist, associations: updatedAssociations });
      } else {
        setChecklist({
          ...checklist,
          associations: [...checklist.associations, updatedAssociation],
        });
      }
      closeAssociationModal();
    }
  };

  const removeAssociation = (index) => {
    const updatedAssociations = checklist.associations.filter((_, i) => i !== index);
    setChecklist({ ...checklist, associations: updatedAssociations });
  };

  const getFactorTextByValue = (value) => {
    const factor = questionStructure?.find((f) => f.value.id === value?.id);
    return factor ? factor.text : value?.title;
  };

  const addActivity = (phaseIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities.push({
      activityTitle: '',
      tasks: [],
    });
    setChecklist({
      ...checklist,
      phases: updatedPhases
    });
  };

  const removeActivity = (phaseIndex, activityIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities = checklist.phases[phaseIndex].activities.filter((_, i) => i !== activityIndex);
    setChecklist({ ...checklist, phases: updatedPhases, });
  };

  const addTask = (phaseIndex, activityIndex) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks.push({ task: '', metric: '', resources: [] });
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const removeTask = (phaseIndex, activityIndex, taskIndex) => {
    let updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks = updatedPhases[phaseIndex].activities[activityIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const openResourceModal = (phaseIndex, activityIndex, taskIndex, resources) => {
    setCurrentPhaseIndex(phaseIndex);
    setCurrentactivityIndex(activityIndex);
    setCurrentTaskIndex(taskIndex);
    setCurrentResources(resources);
    setModalOpen(true);
  };

  const addResource = (phaseIndex, newResource) => {
    const updatedResources = [...currentResources, newResource];
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const removeResource = (phaseIndex, resourceIndex) => {
    const updatedResources = currentResources.filter((_, i) => i !== resourceIndex);
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updateResourceInfo = (phaseIndex, resourceIndex, field, value) => {
    const updatedResources = [...currentResources];
    updatedResources[resourceIndex][field] = value;
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[currentactivityIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updateActivityTitle = (phaseIndex, activityIndex, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].activityTitle = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const updateTaskInfo = (phaseIndex, activityIndex, taskIndex, field, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].activities[activityIndex].tasks[taskIndex][field] = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  // When editing an association, pre-fill the form fields
  useEffect(() => {
    if (associationToEdit) {
      setNewAssociationFactor(associationToEdit.factor);
      setNewAssociationPriority(associationToEdit.priority);
      setAssociationSummary(associationToEdit.summaryText || '');
      setAssociationMoreInfo(associationToEdit.moreInfo || []);
    } else {
      // Clear fields when adding new association
      setNewAssociationFactor('');
      setNewAssociationPriority('');
      setAssociationSummary('');
      setAssociationMoreInfo([]);
    }
  }, [associationToEdit]);

  function onResourceModalClosed() {
    setModalOpen(false)
  }

  const panes = [
    // Phase 1 Tab
    {
      menuItem: 'Discover',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={0}
            addActivity={addActivity}
            phaseData={checklist.phases[0]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Phase 2 Tab
    {
      menuItem: 'Design',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={1}
            addActivity={addActivity}
            phaseData={checklist.phases[1]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Phase 3 Tab
    {
      menuItem: 'Deploy',
      render: () => (
        <Tab.Pane attached={false}>
          <PhaseTab
            phaseIndex={2}
            addActivity={addActivity}
            phaseData={checklist.phases[2]}
            updateActivityTitle={updateActivityTitle}
            updateTaskInfo={updateTaskInfo}
            addTask={addTask}
            removeTask={removeTask}
            removeActivity={removeActivity}
            openResourceModal={openResourceModal}
          />
        </Tab.Pane>
      ),
    },
    // Context Fields Tab
    {
      menuItem: 'Context Fields',
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Field>
              <label>Why make this recommendation?</label>
              <TextArea
                value={checklist.context.whyRecommendation}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      whyRecommendation: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Focus Areas</label>
              <TextArea
                value={checklist.context.focusAreas}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      focusAreas: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>This recommendation aims to answer the question</label>
              <TextArea
                value={checklist.context.recommendationQuestion}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      recommendationQuestion: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <ListItemsInput
              label="Questions that we need to answer about the organization"
              items={checklist.context.organizationQuestions}
              setItems={(newItems) =>
                setChecklist({
                  ...checklist,
                  context: {
                    ...checklist.context,
                    organizationQuestions: newItems,
                  },
                })
              }
            />
            <ListItemsInput
              label="This effort will require us to..."
              items={checklist.context.effortRequires}
              setItems={(newItems) =>
                setChecklist({
                  ...checklist,
                  context: {
                    ...checklist.context,
                    effortRequires: newItems,
                  },
                })
              }
            />
            <Form.Field>
              <label>Areas of the organization that will need to be involved</label>
              <TextArea
                value={checklist.context.involvedAreas}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      involvedAreas: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Desired Outcomes</label>
              <TextArea
                value={checklist.context.desiredOutcome}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      desiredOutcome: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Timeframe</label>
                <Input
                  value={checklist.context.timeframe}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        timeframe: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Input
                  value={checklist.context.cost}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        cost: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Policy Changes</label>
                <Input
                  value={checklist.context.policyChange}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        policyChange: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Cultural Changes</label>
                <Input
                  value={checklist.context.cultureChange}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        cultureChange: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Data Collection</label>
              <Input
                value={checklist.context.dataCollection}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      dataCollection: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Community Connections</label>
              <Input
                value={checklist.context.communityConnections}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      communityConnections: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Primary Stakeholder</label>
              <Input
                value={checklist.context.primaryStakeholder}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      primaryStakeholder: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Tab.Pane>
      ),
    },
    // Associations Tab (updated)
    {
      menuItem: 'Associations',
      render: () => (
        <Tab.Pane attached={false}>
          {checklist.associations.length > 0 && (
            <Table celled selectable style={{ borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Factor</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Summary</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">Priority</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {checklist.associations.map((association, index) => (
                  <Table.Row key={index} onClick={() => openAssociationModal(association)} style={{ cursor: 'pointer' }}>
                    <Table.Cell>{getFactorTextByValue(association.factor)}</Table.Cell>
                    <Table.Cell title={association.summaryText}>{association.summaryText.length > 150
                      ? `${association.summaryText.substring(0, 150)}...`
                      : association.summaryText}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Label color={association.priority === 'primary' ? 'blue' : 'grey'}>
                        {association.priority.charAt(0).toUpperCase() + association.priority.slice(1)}
                      </Label>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <IconButton
                        icon
                        title="Delete Association"
                        onClick={
                          (e) => {
                            e.stopPropagation();         // prevent row's onClick from firing
                            removeAssociation(index)
                          }
                        }
                      >
                        <Icon name="trash alternate" color="grey" />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
          <Button icon labelPosition="left" primary onClick={() => openAssociationModal()}>
            <Icon name="plus" />
            Add Association
          </Button>

          <Modal open={associationModalOpen} onClose={closeAssociationModal}>
            <Modal.Header>
              {associationToEdit ? 'Edit Association' : 'Add Association'}
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>Factor</label>
                  <Dropdown
                    fluid
                    selection
                    options={questionStructure.map(option => ({
                      key: option.id,
                      text: option.text,
                      value: JSON.stringify(option.value),
                    }))}
                    value={newAssociationFactor ? JSON.stringify(newAssociationFactor) : undefined}  // Use stringified object as value
                    onChange={(e, { value }) => {
                      setNewAssociationFactor(JSON.parse(value));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Priority</label>
                  <Dropdown
                    fluid
                    selection
                    options={[
                      { key: 'primary', text: 'Primary', value: 'primary' },
                      { key: 'secondary', text: 'Secondary', value: 'secondary' },
                    ]}
                    value={newAssociationPriority}
                    onChange={(e, { value }) => setNewAssociationPriority(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Summary Text</label>
                  <TextArea
                    value={associationSummary}
                    onChange={(e) => setAssociationSummary(e.target.value)}
                  />
                </Form.Field>
                <ListItemsInput
                  label="More Info"
                  items={associationMoreInfo}
                  setItems={setAssociationMoreInfo}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={closeAssociationModal}>Cancel</Button>
              <Button
                primary
                onClick={handleSaveGoalAssociation}
                disabled={!newAssociationFactor || !newAssociationPriority}
              >
                {associationToEdit ? 'Save Changes' : 'Add Association'}
              </Button>
            </Modal.Actions>
          </Modal>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container>
      <PageTitle newPageTitle="Organizational Goal" />

      <StyledForm>
        <Form.Field>
          <label>Title</label>
          <Input
            value={checklist.title}
            onChange={(e) => setChecklist({ ...checklist, title: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <TextArea
            value={checklist.description}
            onChange={(e) => setChecklist({ ...checklist, description: e.target.value })}
          />
        </Form.Field>
      </StyledForm>

      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />

      <ResourceModal
        phaseIndex={currentPhaseIndex}
        open={modalOpen}
        onClose={onResourceModalClosed}
        resources={currentResources}
        addResource={addResource}
        removeResource={removeResource}
        updateResourceInfo={updateResourceInfo}
      />

      <SaveContainer>
        <Button onClick={() => navigate('/app/recommendation-templates/org-goals')}>
          Cancel
        </Button>
        <Button primary onClick={saveChecklistTemplate} disabled={!checklist.title}>
          Save Goal
        </Button>
      </SaveContainer>
    </Container>
  );
};

export default OrganizationalGoal;

const StyledForm = styled(Form)`
    margin-bottom: 20px;
`;

const IconButton = styled(Button)`
    padding: 8px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #d9534f !important;
`;

const SaveContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
