import {
  GET_GPT_PROCESS_PENDING,
  CLEAR_GPT_PROCESS,
  POST_GPT_PROCESS,
  CLEAR_ALL,
} from "common/constants/actions";


const initialState = {
  gpt_web_chat_proces: [],
  pending: false,
  created: false,
};

export default function GptMember(state = initialState, action) {
  switch (action.type) {
    case GET_GPT_PROCESS_PENDING:
      return {
        ...state,
        pending: true,
      };

    case POST_GPT_PROCESS:
      return {
        ...state,
        gpt_web_chat_proces: action.payload,
        created: action.payload,
        pending: false,
      };


    case CLEAR_GPT_PROCESS:
      return {
        ...state,
        gpt_web_chat_proces: [],
        pending: false,
        created: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        gpt_web_chat_proces: [],
        pending: false,
        created: false,
      };

    default:
      return state;
  }
}
