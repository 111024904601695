import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  GET_SURVEY_SETTING_LIST,
  SG_GET_SURVEY_SETTING_LIST,
  ALL_ERRORS,
} from "common/constants/actions";


function* loadSurveySettingListk(action) {
  try {
    const json = yield call(api.GET_SURVEY_SETTING_LIST, action.payload);

    yield put({ type: GET_SURVEY_SETTING_LIST, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* SurveySettingListLoad() {
  yield takeLatest(SG_GET_SURVEY_SETTING_LIST, loadSurveySettingListk);
}

export default function* index() {
    yield all([
        SurveySettingListLoad(),
    ]);
  }
