import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  GET_ADMIN_DEFAULTS,
  SG_GET_ADMIN_DEFAULTS,
  FETCH_ADMIN_DEFAULT,
  SG_FETCH_ADMIN_DEFAULT,
  EDIT_ADMIN_DEFAULT,
  SG_EDIT_ADMIN_DEFAULT,
  ADD_ADMIN_DEFAULT,
  SG_ADD_ADMIN_DEFAULT,
  DELETE_ADMIN_DEFAULT,
  SG_DELETE_ADMIN_DEFAULT,
  ALL_ERRORS,
} from "common/constants/actions";


function* loadmethods(action) {
  //   yield put({ type: GET_methodS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ADMIN_DEFAULTS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_ADMIN_DEFAULTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_ADMIN_DEFAULTS, loadmethods);
}


function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_ADMIN_DEFAULT, action.payload);
    yield put({ type: FETCH_ADMIN_DEFAULT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_ADMIN_DEFAULT, fetchmethods);
}


function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_ADMIN_DEFAULT, action.payload);
    yield put({ type: EDIT_ADMIN_DEFAULT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_EDIT_ADMIN_DEFAULT, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE
  try {
    const json = yield call(api.ADD_ADMIN_DEFAULT, action.payload);
    yield put({ type: ADD_ADMIN_DEFAULT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_ADMIN_DEFAULT, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_ADMIN_DEFAULT, action.payload);
    yield put({ type: DELETE_ADMIN_DEFAULT, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_ADMIN_DEFAULT, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
