import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import { useParams } from "react-router";
import RowCard from "./RowCard";
import CreateEditModel from "./CreateEdit";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_GPT_MODEL_MAINS,
  SG_FETCH_GPT_MODEL_MAIN,
  UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
} from "common/constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Icon,
} from "semantic-ui-react";

const List = ({ noPagination = false }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [reloadPage, setReloadPage] = useState(false);
  const [modelId, setModelId] = useState(id);

  const [selectBriefSchedule, setSelectBriefSchedule] = useState([]);

  const [gptMain, setGptMain] = useState();
  const [editModelMain, setEditModelMain] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_GPT_MODEL_MAINS,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reloadPage]);

  const { get_gpt_model_main } = useSelector(
    (state) => ({
      get_gpt_model_main: state.gpt_model_main,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_model_main?.gpt_model_main?.results);
    setPage(get_gpt_model_main?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_model_main?.gpt_model_main?.count /
          get_gpt_model_main?.gpt_model_main?.page_size
      )
    );
    if (
      get_gpt_model_main?.gpt_model_main?.count %
      get_gpt_model_main?.gpt_model_main?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_model_main?.gpt_model_main?.count /
            get_gpt_model_main?.gpt_model_main?.page_size
        ) + 1
      );
    }
  }, [get_gpt_model_main, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);



  useEffect(() => {
    setModelId(id);
  }, [id]);

  useEffect(() => {
    // if the response is ok 200
    if (modelId) {
      dispatch({
        type: SG_FETCH_GPT_MODEL_MAIN,
        payload: { id: modelId },
      });
    }
  }, [dispatch, modelId]);

  useEffect(() => {
    if (modelId) {
      setGptMain(get_gpt_model_main[modelId]);
      return;
    }
    if (get_gpt_model_main?.created?.id) {
      setGptMain(get_gpt_model_main?.created);
      return;
    }
  }, [get_gpt_model_main, modelId]);

  function ResetCategoryList() {
    setEditModelMain(!editModelMain);
  }

  return (
    <Container>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) =>

            <RowCard row={result} key={result?.id}
            setEditModelMain={setEditModelMain}
            />)}
        </Card.Group>
      </Segment>
      {!noPagination && (
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      )}

      <SectionDiv>
        <RightAlign>
        {editModelMain?.id && (
            <ContainerFieldSelectable onClick={ResetCategoryList}>
              Cancel Edit <Icon name="close" />
            </ContainerFieldSelectable>
          )}
        </RightAlign>

          {editModelMain?.id ? (
            <CreateEditModel
              setReloadPage={setReloadPage}
              modelID={editModelMain?.id}
              setEditModelMain={setEditModelMain}
            />
          ) : (
            <CreateEditModel setReloadPage={setReloadPage} />
          )}

      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SectionHeader = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

const SectionExplain = styled.span`
  font-weight: 400;
  font-size: 12px;
`;
