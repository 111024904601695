import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';

function BriefScheduled() {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default BriefScheduled;
