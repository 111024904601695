import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Button, Header, Message, } from 'semantic-ui-react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    PULL_ORGANIZATION,
    UPDATE_ORGANIZATION,
    SG_GET_SURVEY_STRUCTURES,
    SG_GET_SURVEY_QUESTION_SELECT,
    SG_GET_ALL_SURVEY_QUESTION_SELECT,
    SG_GET_ORGANIZATION_STYLE,
} from "common/constants/actions";

import { RowEditList, SurveySequenceList, } from "../Components";


const OrganizationSurveys = ({ organizationId }) => {
    const dispatch = useDispatch();

    const { get_organizations,
        get_survey_question_select,
        get_survey_question_all_select,
        get_survey_structure, } = useSelector(
            (state) => ({
                get_organizations: state.organizations,
                get_survey_question_select: state.surveyquestion.survey_question_select,
                get_survey_question_all_select: state.surveyquestion.survey_question_all_select,
                get_survey_structure: state.surveystructure.survey_structure,
            }),
            shallowEqual
        );


    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [serviceList, setServiceList] = useState(get_organizations?.[organizationId]?.services_enabled)
    const [updateValue, setUpdateValue] = useState(false)
    const [stylingList, setStylingList] = useState([])

    const [stylingMsg, setStylingMsg] = useState(false)

    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: PULL_ORGANIZATION,
                payload: { id: organizationId }
            });
            dispatch({
                type: SG_GET_SURVEY_QUESTION_SELECT,
            });
           dispatch({
                type: SG_GET_ALL_SURVEY_QUESTION_SELECT,
            });
            dispatch({
                type: SG_GET_SURVEY_STRUCTURES,
                payload: `organization=${organizationId}`,
            });
        }
    }, [dispatch, organizationId]);

   useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_ORGANIZATION_STYLE,
            });
        }
    }, [dispatch, organizationId, stylingMsg]);


    useEffect(() => {
        setOrganizationDetails(get_organizations?.[organizationId])
        setServiceList(get_organizations?.[organizationId]?.services_enabled)
        setStylingList(get_organizations?.[organizationId]?.styling)
    }, [get_organizations, organizationId]);

/*    useEffect(() => {
        setResults(get_survey_structure?.results);
    }, [get_survey_structure]);
*/
    function UpdateDetails() {
        setUpdateValue(false)
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    services_enabled: serviceList,
                    styling: stylingList
                }
            });
        }
    }

/*
    function UpdateCurrentStyling() {
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    styling: stylingList,
                }
            });
            setStylingMsg({
                header: "Styling Changed",
                content: JSON.stringify(stylingList)

            })
        }
    }

*/
    return (
        <>
            <Header as='h2'>
                Survey Configuration
            </Header>
            <Header.Subheader>
                    Specify survey and outcome questions for this organization
            </Header.Subheader>

            <SectionDiv>
                <RowEditList
                    serviceList={serviceList}
                    setServiceList={setServiceList}
                    setUpdateValue={setUpdateValue}
                    get_survey_question_select={get_survey_question_select}
                />
            </SectionDiv>

            {stylingMsg && (
                <Message
                    positive
                    onDismiss={() => setStylingMsg(false)}
                    header={stylingMsg.header}
                    content={stylingMsg.content}
                />
            )}

            <SectionDiv>
                <Header as='h3'>Survey Sequence</Header>
                <Header.Subheader>
                    Survey structure and sequence for demographics, questions, feedback, and outcomes
                </Header.Subheader>

                <SectionDiv>
                    <SurveySequenceList
                        stylingList={stylingList}
                        setStylingList={setStylingList}
                        setUpdateValue={setUpdateValue}
                        get_survey_question_all_select={get_survey_question_all_select}
                    />
                    {stylingList?.survey_sequence &&
                        <Button
                            color='violet'
                            onClick={UpdateDetails}
                            disabled={!updateValue}>Update</Button>
                    }
                </SectionDiv>
            </SectionDiv>
        </>
    );
};

export default OrganizationSurveys;

const SectionDiv = styled.div`
  padding: 0px 0px;
  margin:10px 0px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  margin-top: 30px;
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};
`
