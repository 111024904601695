import React, { useState, useEffect } from 'react';
import { Icon, Checkbox, Button, Input, Message } from 'semantic-ui-react'
import styled from "styled-components";

import Select from "react-select";

import ServicesEnabled from "common/data/ServicesEnabled.json"
import { ValidEntry } from "./ValidationRules"

export function RowCheckList(services) {
  return (
    <>
      {services?.invite &&
        <div key={services?.invite?.id}>
          <SectionHeader>
            {services?.invite?.name}
          </SectionHeader>
          {services?.invite?.active ?
            <Icon color='green' name='checkmark' size='small' /> :
            <Icon color='red' name='close' size='small' />}
          <SectionHeader>
            {services?.invite?.postmark_template_id}
          </SectionHeader>
        </div>
      }
      {services?.survey &&
        <div key={services?.survey?.id}>
          <SectionHeader>
            {services?.survey?.name}
          </SectionHeader>
          {services?.survey?.active ?
            <Icon color='green' name='checkmark' size='small' /> :
            <Icon color='red' name='close' size='small' />}
          <SectionHeader>
            {services?.survey?.postmark_template_id}
          </SectionHeader>
        </div>
      }
    </>
  )


}


export function RowObjectList(services) {
  const [objectList, setObjectList] = useState([])
  useEffect(() => {
    setObjectList(Object.entries(services))
  }, [services]);

  return (
    <>
      <SectionHeader>Styling</SectionHeader>
      {objectList.map((item, i) => {
        return <div key={i}>
          <RowData>
            <RowLine> {item[0]}</RowLine>
            <RowLine> {item[1]}</RowLine>

          </RowData>
        </div>
      })
      }
    </>
  )
}

export function RowObjectInputEdit({ selectedObject,
  setSelectedObject, setUpdateValue }) {
  const [objectList, setObjectList] = useState([])

  const [errorList, SetErrorList] = useState([])
  const [isDirty, SetIsDirty] = useState(false)
  const [refreshPage, SetRefreshPage] = useState(false)


  useEffect(() => {
    if (selectedObject) {
      setObjectList(Object.entries(selectedObject))
    }
  }, [selectedObject, refreshPage]);


  function DismissError() {
    SetErrorList(false)
    SetIsDirty(false)
  }


  function UpdateRow(key, value) {
    let _newList = selectedObject
    _newList[key] = value
    setSelectedObject(_newList)

    SetRefreshPage(Math.random())
    setUpdateValue(`${Math.random()} + ${true}`)
  }

  return (
    <>
      {errorList?.length > 0 && isDirty &&
        <Message
          color='red'
          onDismiss={DismissError}
          header={"There were some errors"}
          content={JSON.stringify(errorList)}
        />}

      {objectList.map((item, i) => {
        return <BorderedSectionDiv key={i} >
          <RowData>
            <RowInputLine>
              {item[0]}
            </RowInputLine>
            <RowInputLine>
              <CellRows>
                 <Input value={item[1] || ''}
                 disabled={ValidEntry[item[0]]?.disabled}
                onChange={(e) => UpdateRow(item[0], e.target.value)}
              />
              </CellRows>
              <InputExplain>
                {ValidEntry[item[0]]?.sample}
              </InputExplain>
            </RowInputLine>
            {item[0].includes('template_id') &&
              <a href={`https://account.postmarkapp.com/servers/6252012/templates/${item[1]}/edit`}
                target="_blank"
                rel="noreferrer"
                style={{
                  marginTop: "15px",
                  backgroundColor: "#D3D3D3",
                  padding: "10px 5px",
                  borderRadius: "5px"
                }}
              >
                View Template
              </a>
            }
          </RowData>
          <InputExplain color={ValidEntry[item[0]]?.color}>
                {ValidEntry[item[0]]?.description}
              </InputExplain>
        </BorderedSectionDiv>
      })
      }

    </>
  )


}

export function RowEditList({
  serviceList, setServiceList, setUpdateValue,
  get_survey_question_select }) {
  const ServiceListing = ['Teamtool', 'EDI', 'Audit', 'OTP',]

  function SelectQuestionList({ sr, sl }) {
    // console.log(sr)
    // console.log(sl)
    // console.log(get_survey_question_select.filter(sqs => sqs.survey_type_text === sl))
    const [questionSelected, setQuestionSelected] = useState([])
    const [questionSelect, setQuestionSelect] = useState([])

    useEffect(() => {
      const _SelectList = [{ value: 0, label: "Default Question" }]
      get_survey_question_select.filter(sqs => sqs.survey_type_text === sl).map(sr => {
        _SelectList.push({
          value: sr.sort_order,
          label: `${sr.name} (${sr.sort_order})`
        })
        return null
      })
      setQuestionSelect(_SelectList)
    }, [sl]);


    useEffect(() => {
      setQuestionSelected(questionSelect.filter(slt => slt.value === sr.question_sort_order))
    }, [questionSelect, sr]);

    function ChangedQuestionSort(e) {
      const _old_srList = serviceList.filter(ss => ss.name !== sr.name)
      const _srList = serviceList.filter(ss => ss.name === sr.name)[0]
      _srList.question_sort_order = e.value

      setQuestionSelected(e)
      _old_srList.push(_srList)
      setServiceList(_old_srList)
      setUpdateValue(`${e} + ${true}`)
    }

    return (
      <Select
        style={{ zIndex: 999 }}
        name="survey_preference"
        options={questionSelect}
        onChange={ChangedQuestionSort}
        value={questionSelected}
      />
    )
  }


  function ChangeSelection(sr) {
    let _srList = serviceList.filter(ss => ss.name !== sr.name)
    sr['enabled'] = !sr.enabled
    _srList.push(sr)
    _srList.sort((a, b) => (a.id - b.id))
    setServiceList(_srList)
    setUpdateValue(`${sr} + ${true}`)
  }

  function AddService(e) {
    let _srList = serviceList
    if (!_srList.filter(ss => ss.name === e)?.[0]) {
      _srList.push(ServicesEnabled.filter(se => se.name === e)?.[0])
    }
    setServiceList(_srList)
    setUpdateValue(`${e} + ${true}`)
  }

  // return (<p>nothing</p>)
  if (!serviceList) {
    return null
  }
  return (
    // serviceList?.length > 0 && serviceList.map((sr, i) => {
    ServiceListing.map((sl, i) => {
      const sr = serviceList.filter(sr => sr?.name === sl)?.[0]
      if (sr) {
        return (
          <SectionDiv key={i}>
            <Checkbox
              onClick={() => ChangeSelection(sr)}
              toggle
              checked={sr?.enabled === true}
            />
            <SectionHeader>
              {sr.name}
            </SectionHeader>

            <SectionHeader>
              <SelectQuestionList sr={sr} sl={sl} />
            </SectionHeader>
          </SectionDiv>
        )
      }
      return (
        <SectionDiv key={i}>
          <Button onClick={() => AddService(sl)}>{`${sl} Not Found Click to Add`}</Button>
        </SectionDiv>
      )

    })

  )
}

const SectionDiv = styled.div`
  padding: 5px;
  margin: 5px;
`

const BorderedSectionDiv = styled.div`
  padding: 5px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #CFCFCF;
`

const SectionHeader = styled.span`
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`

const RowInputLine = styled.span`
  min-width: 300px;
  font-size: 110%;
  margin: 10px;
  padding-top:10px;
  display: inline-block;
`
const RowLine = styled.span`
  max-width: 300px;
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`

const CellRows = styled.div`
  display: flex;
  flex-direction: column;
`

const InputExplain = styled.span`
  font-size: 90%;
  margin: 0 10px;
  color: ${(props) => (props.color ? props.color : "#0F46EF")};
`
