import {
    UPDATE_APP_LIST_URL_PAGE_NUMBER,
    APP_LIST_URL_PENDING,
    GET_APP_LIST_URLS,
    DELETE_APP_LIST_URL,
    FETCH_APP_LIST_URL,
    EDIT_APP_LIST_URL,
    ADD_APP_LIST_URL,
    CLEAR_APP_LIST_URL,
    CLEAR_ALL,
    } from "common/constants/actions";

  const initialState = {
    app_url_list: [],
    pageNumber: 1,
    pending: false,
    created: false,
    updated: false,
    deleted: false,

  };

  export default function AppUrlList(state = initialState, action) {
    switch (action.type) {
      case UPDATE_APP_LIST_URL_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
      case APP_LIST_URL_PENDING: {
        return {
          ...state,
          pending: true
        }
      }
      case GET_APP_LIST_URLS:
        return {
          ...state,
          app_url_list: action.payload,
          updated: false,
          pending: false,
        };

      case FETCH_APP_LIST_URL:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false
         };

      // case EDIT_EMPLOYEE:

      //   return { ...state,
      //     [action.payload.id]: action.payload,
      //     pending: false };

      case EDIT_APP_LIST_URL:

        return {
          ...state,
          [action.payload.id]: action.payload,
          updated: action.payload,
          pending: false,
        };
      case DELETE_APP_LIST_URL:
        return {
          ...state,
          app_url_list: state.app_url_list.filter((row) => row.id !== action.payload),
          deleted: true,
          pending: false,
        };

      case ADD_APP_LIST_URL:
        return {
          ...state,
          created: action.payload,
          pending: false,
        };

      case CLEAR_APP_LIST_URL:
        return {
          ...state,
          app_url_list: [],
          pageNumber: 1,
          created: false,
          updated: false,
          deleted: false,
          pending: false,
        };

      case CLEAR_ALL:
        return {
          ...state,
          app_url_list: [],
          pageNumber: 1,
          created: false,
          updated: false,
          deleted: false,
          pending: false,
        };

      default:
        return state;
    }
  }
