import {
  GET_PERSONALITY_TEAMS,
  DELETE_PERSONALITY_TEAM,
  FETCH_PERSONALITY_TEAM,
  EDIT_PERSONALITY_TEAM,
  ADD_PERSONALITY_TEAM,
  CLEAR_ALL,
  CLEAR_PERSONALITY_TEAM,
} from "common/constants/actions";

const initialState = {
  personality_teams: [],
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PERSONALITY_TEAMS:
      return {
        ...state,
        personality_teams: action.payload,
        pending: false,
      };

    case FETCH_PERSONALITY_TEAM:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_PERSONALITY_TEAM:
      const schedule = state.personality_teams.filter(
        (row) => row.id !== action.payload.id
      );
      schedule.push(action.payload);
      return { ...state, personality_teams: schedule };

    case DELETE_PERSONALITY_TEAM:
      return {
        ...state,
        personality_teams: state.personality_teams.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_PERSONALITY_TEAM:
      return {
        ...state,
        personality_teams: [...state.personality_teams, action.payload],
      };

    case CLEAR_PERSONALITY_TEAM:
      return {
        ...state,
        personality_teams: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        personality_teams: [],
      };

    default:
      return state;
  }
}
