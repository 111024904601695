import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_POST_GPT_PROCESS } from "common/constants/actions";
import NewChat from "./NewChat"
import { BasicErrorMessage } from "common/utils/ErrorMessages"

import {
  Button,
  Modal,
  Dimmer, Loader,
  Input,
} from "semantic-ui-react";

const ReProcessChat = ({ webChatId, question }) => {
  const dispatch = useDispatch();
  const [portalOpen, setPortalOpen] = useState(false);
  const [message, setMessage] = useState(question || "");
  const [loaderStart, setLoaderStart] = useState(false);

  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);


  useEffect(() => {
    if (loaderStart) {
      intervalRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      // clearInterval(intervalRef.current);
      // setElapsedTime(0); // Reset the timer
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [loaderStart]);

  const { get_gpt_web_chat_process } = useSelector(
    (state) => ({
      get_gpt_web_chat_process: state.gpt_web_chat_process,
    }),
    shallowEqual
  );

  function ReProcess() {
    const newUUID = uuidv4();
    if (webChatId) {
      dispatch({
        type: SG_POST_GPT_PROCESS,
        payload: {
          web_chat_id: webChatId,
          // token: session_id,
          web_chat_message_id: newUUID,
          web_chat_message_text: message,
        },
      });
      // setElapsedTime(0);
      // setLoaderStart(true)
      // navigate(`/app/gpt-recommendation?reload=true`)
    }
  }

  useEffect(() => {
      if (get_gpt_web_chat_process?.pending)
      {
        setElapsedTime(0);
      }
      setLoaderStart(get_gpt_web_chat_process?.pending)

  }, [get_gpt_web_chat_process])


  return (
    <>
      <Button
        floated="right"
        color={portalOpen ? "red" : "green"}
        onClick={() => setPortalOpen(!portalOpen)}
      >
        Try Something
      </Button>

      <Modal
        open={portalOpen}
      >
        <Container>

        <BasicErrorMessage />
        {!loaderStart && elapsedTime > 0 && <ElapsedTime>{elapsedTime} seconds</ElapsedTime>}
        <Dimmer active={loaderStart}>
        <Loader>{elapsedTime} seconds</Loader>
      </Dimmer>
        <Modal.Content  scrolling>

          <Title>Try something with this Chat Data - Nothing is saved</Title>

          <Label>Question</Label>
          <Input
           style={{ width: '100%' }}
            placeholder='Enter your question...'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          {/* Placeholder for response and other details */}
          <ResponsePlaceholder>
            <NewChat data={get_gpt_web_chat_process?.created} />
          </ResponsePlaceholder>

          <ButtonGroup>
            <Button color={"green"} onClick={() => setPortalOpen(!portalOpen)}>
              {portalOpen ? "Close" : "Open"}
            </Button>
            <Button color="red" onClick={ReProcess}>
            Try Something
            </Button>
          </ButtonGroup>
        </Modal.Content>
      </Container>
      </Modal>
    </>
  );
};

export default ReProcessChat;

const Container = styled.div`
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Label = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
`;

const ResponsePlaceholder = styled.div`
  font-size: 16px;
  color: #777;
  margin: 20px 0;
  min-height: 100px; // adjust as needed
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const ElapsedTime = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding: 10px;
  display: block;
`;

