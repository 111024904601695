import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Card } from 'semantic-ui-react'

import { format } from 'date-fns'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_DEBRIEF_RESPONSES } from "common/constants/actions";
import ReactJson from "react-json-view";
//
//
const Details = (
  { briefToken }
) => {
  const dispatch = useDispatch();
  const [briefResponse, setBriefResponse] = useState(null);

  useEffect(() => {
    if (briefToken) {
      dispatch({
        type: SG_GET_DEBRIEF_RESPONSES,
        payload: `survey_token=${briefToken}`
      });
    }
  }, [dispatch, briefToken]);

  const { get_brief_responses } = useSelector(
    (state) => ({
      get_brief_responses: state.debrief_response.debrief_response,
    }),
    shallowEqual
  );

  useEffect(() => {
    setBriefResponse(get_brief_responses?.results?.[0])
  }, [get_brief_responses]);


  return (
    <>
      <Card fluid >
        <Card.Content>
          <Container>
            <ListContainer>
              <HeaderDiv>Employee Id
              </HeaderDiv>
              <ContentDiv>
                {briefResponse?.employee}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status
              </HeaderDiv>
              <ContentDiv>
                {briefResponse?.status}
              </ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {briefResponse?.created_at && format(new Date(briefResponse?.created_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
          </Container>
          <Container>
            <ListContainer>
              <HeaderDiv>Response</HeaderDiv>
              <ContentDiv>
                {/* <pre>
                  {JSON.stringify(briefResponse?.response, null, 2)}
                </pre> */}
                <ReactJson src={briefResponse?.response} />
              </ContentDiv>
            </ListContainer>
          </Container>
          <Container>

          </Container>
        </Card.Content>
      </Card>

    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`
