import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_SURVEY_QUESTION,
  SG_EDIT_SURVEY_QUESTION,
  SG_ADD_SURVEY_QUESTION,
  SG_DELETE_SURVEY_QUESTION,
} from "common/constants/actions";

import { Icon, Message, Button, Header, List, Input, Card, Confirm } from "semantic-ui-react";
import { format } from "date-fns";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import ReactJson from "react-json-view";

import { SURVEY_TYPE, STATUS } from "common/data/AdminData";

import Select from "react-select";
import { BasicErrorMessage } from "common/utils/ErrorMessages";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [questionId, setQuestionId] = useState(false);

  const [questionDetails, setQuestionDetails] = useState({
    name: "",
    sort_order: "",
  });

  const [loading, setLoading] = useState(false);
  const [questionJson, setQuestionJson] = useState();

  const [statusSelected, setStatusSelected] = useState({});
  const [surveyTypeSelected, setSurveyTypeSelected] = useState({});

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setQuestionId(id);
    setLoading(true);
  }, [id]);

  useEffect(() => {
    if (questionId) {
      dispatch({
        type: SG_FETCH_SURVEY_QUESTION,
        payload: { id: questionId },
      });
    }
  }, [dispatch, questionId]);

  const { get_questions } = useSelector(
    (state) => ({
      get_questions: state.surveyquestion,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_questions?.[questionId]) {
      setQuestionDetails(get_questions?.[questionId]);
      setQuestionJson(get_questions?.[questionId]?.questions);
      setStatusSelected(
        STATUS.filter(
          (sy) => sy.value === get_questions?.[questionId]?.status
        )?.[0]
      );
      setSurveyTypeSelected(
        SURVEY_TYPE.filter(
          (sy) => sy.value === get_questions?.[questionId]?.survey_type
        )?.[0]
      );
    }
  }, [get_questions, questionId]);

  useEffect(() => {
    if (get_questions?.created?.id) {
      navigate(`/app/questions/details/${get_questions?.created?.id}`);
    }
  }, [get_questions, navigate]);

  function EditQuestion(e) {
    setQuestionJson(e?.jsObject);
  }

  function DeleteQuestion() {
    if (questionId) {
      dispatch({
        type: SG_DELETE_SURVEY_QUESTION,
        payload: {
          id: questionId,
        },
      });
      navigate(`/app/questions?reload=true`);
    }
  }
  function CancelEdit() {
    if (questionId) {
      navigate(`/app/questions/details/${questionId}`)
    } else {
      navigate(`/app/questions`);
    }
  }

  function UpdateQuestions() {
    setPristine(false);
    if (formErrors.length === 0) {
      if (questionId) {
        dispatch({
          type: SG_EDIT_SURVEY_QUESTION,
          payload: {
            id: questionId,
            status: statusSelected?.value,
            survey_type: surveyTypeSelected?.value,
            sort_order: questionDetails?.sort_order,
            name: questionDetails?.name,
            url_location: questionDetails?.url_location,
          },
        });
        navigate(`/app/questions/details/${questionId}`);
      } else {
        dispatch({
          type: SG_ADD_SURVEY_QUESTION,
          payload: {
            status: statusSelected?.value,
            survey_type: surveyTypeSelected?.value,
            sort_order: questionDetails?.sort_order,
            name: questionDetails?.name,
            url_location: questionDetails?.url_location,
          },
        });
      }
      setPristine(true);
    }
  }

  function UpdateQuestionJson() {
    if (!questionJson) {

      const ErrorList = formErrors;
      ErrorList.push("Enter Question in JSON format");
      setFormErrors(ErrorList);
      return;
    }
    if (questionId) {
      dispatch({
        type: SG_EDIT_SURVEY_QUESTION,
        payload: {
          id: questionId,
          questions: questionJson,
        },
      });
      // navigate(`/app/questions/detail/${questionId}`);
    }
  }


  useEffect(() => {
    const ErrorList = [];
    if (!questionDetails?.sort_order) {
      ErrorList.push("Enter an ID");
    }
    if (!questionDetails?.name) {
      ErrorList.push("Enter a Name");
    }
    if (!questionDetails?.url_location) {
      ErrorList.push("Enter a URL Location");
    }
    if (!surveyTypeSelected?.value) {
      ErrorList.push("Select a Survey Type");
    }
    if (!statusSelected?.value) {
      ErrorList.push("Select a Status");
    }
    setFormErrors(ErrorList);
  }, [questionDetails, statusSelected, surveyTypeSelected, valueChanged]);

  function ChangeFormValues(e, name) {
    let _ques = questionDetails;
    _ques[`${name}`] = e.target.value;
    setQuestionDetails(_ques);
    setValueChanged(e.target.value + name);
  }

  /*   const handleJsonEdit = (edit) => {
      setQuestionJson(edit.updated_src);
    };

    function handleNodeAdd(data) {
      setQuestionJson(data.updated_src);
    }

    const handleNodeDelete = (path) => {
      setQuestionJson(path.updated_src);
    };
   */


  return (
    <>
      <SectionDiv>
        <Header as="h2">
          {questionId
            ? `${questionDetails?.name}`
            : "New Survey Template"}
        </Header>
      </SectionDiv>

      <Message
        color='orange'
        icon='warning sign'
        header='Do not modify before you read this'
        content={
          `This data is crucial for surveys to function properly.
          Do NOT change or add anything to this entity without proper training and knowledge of the implications.
          Please note even if there are no errors reported with changes that customers' surveys may break if you
          change this file. Contact the support team for help, if needed. 
        `}
      />


      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <BasicErrorMessage />

      <Card fluid>
        <Card.Content>

          <List horizontal>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>ID</List.Header>
                  <SectionCell>

                    <Input
                      onChange={(e) => ChangeFormValues(e, "sort_order")}
                      value={questionDetails?.sort_order}
                      placeholder="sort order"
                    />
                  </SectionCell>
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Name</List.Header>
                  <SectionCell>

                    <Input
                      onChange={(e) => ChangeFormValues(e, "name")}
                      value={questionDetails?.name}
                      placeholder="name"
                    />
                  </SectionCell>
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>URL Location</List.Header>
                  <SectionCell>
                    <Input
                      onChange={(e) => ChangeFormValues(e, "url_location")}
                      value={questionDetails?.url_location}
                      placeholder="name"
                    />
                  </SectionCell>
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Survey Type</List.Header>
                  <SectionCell>
                    <Select
                      name="survey_type"
                      options={SURVEY_TYPE}
                      isClearable
                      onChange={setSurveyTypeSelected}
                      // getOptionLabel={(option) => `${option.name}`}
                      // getOptionValue={(option) => `${option.id}`}
                      value={surveyTypeSelected}
                    />
                  </SectionCell>
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Survey Status</List.Header>
                  <SectionCell>
                    <Select
                      name="status"
                      options={STATUS}
                      isClearable
                      onChange={setStatusSelected}
                      value={statusSelected}
                    />
                  </SectionCell>
                </ListContainer>
              </List.Content>
            </List.Item>
          </List>

          <SectionDiv>
            <Button primary floated="right" onClick={UpdateQuestions}>
              {questionId ? "Update Survey Template" : "Create Survey Template"}
            </Button>
            <Button floated="right" color="grey" onClick={CancelEdit}>
              {"Cancel"}
            </Button>
          </SectionDiv>

        </Card.Content>
      </Card>

      {questionId && (
        <>
          {/*            <Button floated="right" color="green" onClick={UpdateQuestionJson}>
              Update Json
            </Button>
             <SectionDiv>
              <ReactJson
                src={questionJson}
                onEdit={handleJsonEdit}
                onDelete={handleNodeDelete}
                onAdd={handleNodeAdd}
              />
            </SectionDiv>
             <SectionDiv lastSection={true}>
              {questionId && (
                <>
                  <Button
                    floated="left"
                    color="red"
                    onClick={() => setConfirmOpen(true)}
                  >
                    Delete Question
                  </Button>
                  <Confirm
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={DeleteQuestion}
                  />
                </>
              )}

              <Button floated="right" color="green" onClick={UpdateQuestionJson}>
                Update Json
              </Button>
            </SectionDiv>
 */}
          <Header as='h3'>Survey Template JSON Editor</Header>

          <JSONInput
            id="a_unique_id"
            placeholder={questionDetails?.questions}
            onChange={EditQuestion}
            locale={locale}
            height="500px"
            width="100%"
          />
          <SectionDiv lastSection={true}>
            {questionId && (
              <>
                <Button
                  floated="left"
                  color="red"
                  onClick={() => setConfirmOpen(true)}
                >
                  Delete Survey Template
                </Button>
                <Confirm
                  open={confirmOpen}
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={DeleteQuestion}
                />
              </>
            )}

            <Button floated="right" color="green" onClick={UpdateQuestionJson}>
              Update Survey Template JSON
            </Button>
            <Button floated="right" color="grey" onClick={CancelEdit}>
              {"Cancel"}
            </Button>
          </SectionDiv>
        </>)}
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 20px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
  clear: both;
`;

const SectionCell = styled.div`
  padding: 10px 0px;
  min-width: 150px;
`;

const ListContainer = styled.div`
  margin: 0px 15px;
`;
