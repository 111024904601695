import React, {useEffect, useState} from "react";
import { Loader, Message, } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Domain, Environment } from "WebConfig"
import { CLEAR_ALL, LOGOUT_REQUEST } from "common/constants/actions";

const Logout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
    }),
    shallowEqual
  );

  const [success] = useState("Logged out sucessfully, thanks for using nuLogic!");

  useEffect(() => {
    if (get_auth?.token) {
      // console.log("Logged out")
      dispatch({ type: LOGOUT_REQUEST });
    }
    dispatch({ type: CLEAR_ALL });

    //  window.location.reload(true);
    // navigate("/account/login")
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (Environment === "Development") {
      setTimeout(
        () => navigate("/account/login"),
        500
      );
    } else {
      setTimeout(
        () => (window.location = Domain.homePage),
        3000
      );
    }
  })

  return (
    <div style={{ height: '100%', paddingTop: '25vh' }}>
      {success && (<Loader active inline='centered' />)}

      <div style={{
        height: '50%', width: '50%',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}>
        {success && (
          <>
            <Loader />
            <Message
              success
              // header='Action Forbidden'
              content={success}
            />
          </>
        )}

      </div>


      {/* <LoginForm message={"Logged Out Sucessfully"}  /> */}

    </div>
  );
};

export default Logout;
