import {
    GET_AM_ORGANIZATION,
    // GET_AM_TEAM,
    // GET_AM_EMPLOYEE,
    // GET_AM_ROLE,
    // GET_AM_ACCOUNT,
    // SET_AM_MENU,
    CLEAR_AM,
    CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
    organization: [],
    team: [],
    employee: [],
    role: 5,
    account: 5,
    pending: false,
    top_menu: []
};

export default function SelectedOrg(state = initialState, action) {
    switch (action.type) {
        case GET_AM_ORGANIZATION:
            return {
                ...state,
                organization: action.payload,
                pending: false,
            };
        case CLEAR_AM:
            return {
                ...state,
                price: [],
            };

        case CLEAR_ALL:
            return {
                ...state,
                price: [],
            };

        default:
            return state;
    }
}
