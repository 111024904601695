import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  GET_TEAM_ACCESSS,
  SG_GET_TEAM_ACCESSS,
  DELETE_TEAM_ACCESS,
  SG_DELETE_TEAM_ACCESS,
  FETCH_TEAM_ACCESS,
  SG_FETCH_TEAM_ACCESS,
  EDIT_TEAM_ACCESS,
  SG_EDIT_TEAM_ACCESS,
  ADD_TEAM_ACCESS,
  SG_ADD_TEAM_ACCESS,
  ALL_ERRORS,
} from "common/constants/actions";

function* loadteams(action) {
  //   yield put({ type: GET_teamS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_TEAM_ACCESS, action.payload);
    yield put({ type: GET_TEAM_ACCESSS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamLoad() {
  yield takeLatest(SG_GET_TEAM_ACCESSS, loadteams);
}

function* fetchteams(action) {
  try {
    const json = yield call(api.FETCH_TEAM_ACCESS, action.payload);
    yield put({ type: FETCH_TEAM_ACCESS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamFetch() {
  yield takeLatest(SG_FETCH_TEAM_ACCESS, fetchteams);
}

function* updateteams(action) {
  try {
    const json = yield call(api.EDIT_TEAM_ACCESS, action.payload);
    yield put({ type: EDIT_TEAM_ACCESS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamUpdate() {
  yield takeLatest(SG_EDIT_TEAM_ACCESS, updateteams);
}

function* addteams(action) {
  try {
    const json = yield call(api.ADD_TEAM_ACCESS, action.payload);
    yield put({ type: ADD_TEAM_ACCESS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamAdd() {
  yield takeLatest(SG_ADD_TEAM_ACCESS, addteams);
}

function* deleteteams(action) {
  try {
    yield call(api.DELETE_TEAM_ACCESS, action.payload);
    yield put({ type: DELETE_TEAM_ACCESS, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamDelete() {
  yield takeLatest(SG_DELETE_TEAM_ACCESS, deleteteams);
}


export default function* index() {
  yield all([
    teamLoad(),
    teamFetch(),
    teamUpdate(),
    teamAdd(),
    teamDelete(),
  ]);
}
