import {
  UPDATE_GPT_MEMBER_PAGE_NUMBER,
  GET_GPT_MEMBER_PENDING,
  GET_GPT_MEMBERS,
  GET_GPT_MEMBER_SELECT,
  DELETE_GPT_MEMBER,
  FETCH_GPT_MEMBER,
  EDIT_GPT_MEMBER,
  ADD_GPT_MEMBER,
  CLEAR_GPT_MEMBER,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  gpt_members: [],
  gpt_member_select: [],
  created: [],
  updated: [],
  deleted: [],
  pending: false,
  pageNumber: 1,
};

export default function GptMember(state = initialState, action) {
  switch (action.type) {
    case GET_GPT_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_GPT_MEMBER_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_GPT_MEMBERS:
      return {
        ...state,
        gpt_members: action.payload,
        pending: false,
      };
    case GET_GPT_MEMBER_SELECT:
      return {
        ...state,
        gpt_member_select: action.payload,
        pending: false,
      };
    case FETCH_GPT_MEMBER:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_GPT_MEMBER:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };

    case DELETE_GPT_MEMBER:
      return {
        ...state,
        // gpt_members: state.gpt_members.filter((row) => row.id !== action.payload),
        deleted: action.payload,
        pending: false,
      };

    case ADD_GPT_MEMBER:
      return {
        ...state,
        // gpt_members: [...state.gpt_members, action.payload],
        created: action.payload,
        pending: false,
      };

    case CLEAR_GPT_MEMBER:
      return {
        ...state,
        gpt_members: [],
        created: [],
        updated: [],
        deleted: [],
        pending: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        gpt_members: [],
        created: [],
        updated: [],
        deleted: [],
        pending: false,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
