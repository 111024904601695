import {
  UPDATE_WEB_IMAGE_NUMBER,
  GET_WEB_IMAGES,
  FETCH_WEB_IMAGE,
  DELETE_WEB_IMAGE,
  EDIT_WEB_IMAGE,
  ADD_WEB_IMAGE,
  CLEAR_WEB_IMAGE,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  webimage: [],
  created: [],
  pageNumber: 1,
  pending: false,
};

export default function SuveyToken(state = initialState, action) {
  switch (action.type) {
    case GET_WEB_IMAGES:
      return {
        ...state,
        webimage: action.payload,
        pending: false,
      };
    case UPDATE_WEB_IMAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case FETCH_WEB_IMAGE:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case DELETE_WEB_IMAGE:
      let resultDeleted = (state.webimage.results.filter(
        (row) => row.id !== action.payload.id))

      return {
        ...state,
        webimage: {
          ...state.webimage,
          results: resultDeleted,
        },
        pending: false,
      };


    case EDIT_WEB_IMAGE:
      let resultUnEdited = (state.webimage.results.filter(
        (row) => row.id !== action.payload.id))
      let resultEdited = [
        ...resultUnEdited, action.payload
      ]

      return {
        ...state,
        webimage:
        {
          ...state.webimage,
          results: resultEdited,
        },
        pending: false,
      };

    case ADD_WEB_IMAGE:

      let resultAdded = [
        ...state.webimage.results, action.payload
      ]
      return {
        ...state,
        webimage:
        {
          ...state.webimage,
          results: resultAdded,
        },
        pending: false,
      };

    case CLEAR_WEB_IMAGE:
      return {
        ...state,
        webimage: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        webimage: [],
        pending: false,
      };
    default:
      return state;
  }
}
