import React from 'react'
import {Route, Routes} from 'react-router-dom';

import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";
import SelectOrganization from "./SelectOrg";

function Account() {

  return (
    <div>

      <Routes>
      <Route path="" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="logout" element={<Logout />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="select-organization" element={<SelectOrganization />} />
      </Routes>
    </div>
  );
}

export default Account;
