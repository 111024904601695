import {
  GET_BLOG_TAGS,
  DELETE_BLOG_TAG,
  FETCH_BLOG_TAG,
  EDIT_BLOG_TAG,
  CLEAR_BLOG_TAG,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  blog_tags: [],
  created: false,
  blog_pics: [],
  pending: false,
};

export default function BlogTag (state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_TAGS:
      return {
        ...state,
        blog_tags: action.payload,
        pending: false,
      };


  case FETCH_BLOG_TAG:

          return { ...state,
            [action.payload.id]: action.payload,
            pending: false };

    case EDIT_BLOG_TAG:
      return { ...state,
        [action.payload.id]: action.payload
    };

    case DELETE_BLOG_TAG:
      return {
        ...state,
        blog_tags: state.blog_tags.filter(
          (row) => row.id !== action.payload
        ),
      };



    case CLEAR_BLOG_TAG:
      return {
        ...state,
        blog_tags: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        blog_tags: [],
      };

    default:
      return state;
  }
}
