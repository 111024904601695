import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_QUESTIONS } from "common/constants/actions";

import { Message, Button, Header, List, Card } from 'semantic-ui-react'

import { format } from 'date-fns'

import { SURVEY_TYPE, STATUS } from "common/data/AdminData"
import Select from "react-select";

const OnBoardingQuestions = ({
    organizationId, surveyTypeId, setQuestionList, returnValue = false }) => {
    const dispatch = useDispatch();

    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [servicesEnabled, setServicesEnabled] = useState([])
    const [serviceSelected, setServiceSelected] = useState([])
    const [questionDetails, setQuestionDetails] = useState(null);
    const [surveyQuestion, setSurveyQuestion] = useState([])
    const [askComments, setAskComments] = useState([])
    const [sliderValue, setSliderValue] = useState(1)
    const [sliderValueMax, setSliderValueMax] = useState(1)
    const [selectedQuestion, setSelectedQuestion] = useState(false)

    function GetSurveyQuestion() {
        if (serviceSelected?.sortOrder > 0) {
            dispatch({
                type: SG_GET_SURVEY_QUESTIONS,
                payload: `status=2&sort_order=${serviceSelected?.sortOrder}`,
            });
        }
        else if (serviceSelected?.value) {
            dispatch({
                type: SG_GET_SURVEY_QUESTIONS,
                payload: `status=2&survey_type=${serviceSelected?.value}`,
            });
        }
    }

    useEffect(() => {
        if (returnValue) {
            setQuestionList(surveyQuestion)
        }
    }, [returnValue, setQuestionList, surveyQuestion]);

    useEffect(() => {
        if (surveyTypeId) {
            if (servicesEnabled) {
                const stypeId = servicesEnabled.filter(ss => ss.value === surveyTypeId)?.[0]?.value
                if (stypeId) {
                    dispatch({
                        type: SG_GET_SURVEY_QUESTIONS,
                        payload: `status=2&survey_type=${stypeId}`,
                    });
                }
            }
        }
    }, [dispatch, surveyTypeId, servicesEnabled]);

    const { get_questions,
        get_organization } = useSelector(
            (state) => ({
                get_questions: state.surveyquestion,
                get_organization: state.organizations,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationDetails(get_organization[organizationId]?.services_enabled)
    }, [get_organization, organizationId]);


    useEffect(() => {
        if (serviceSelected?.value === 7) {
            setSurveyQuestion(edi_arrange_Questions(get_questions?.survey_question?.results?.[0]?.questions))
        } else {
            setSurveyQuestion(arrange_Questions(get_questions?.survey_question?.results?.[0]?.questions))
        }

        setAskComments(get_questions?.survey_question?.results?.[0]?.questions?.comments || [])
        setQuestionDetails(get_questions?.survey_question?.results?.[0])

    }, [get_questions, serviceSelected]);

    useEffect(() => {
        const _ServiceList = []
        if (organizationDetails) {
            SURVEY_TYPE.map(sr => {
                const Found = organizationDetails.filter(od => od.name === sr.label)?.[0]
                if (Found?.id && Found.enabled === true) {
                    _ServiceList.push(
                        {
                            value: sr.value,
                            label: Found.name,
                            sortOrder: Found.question_sort_order
                        }
                    )
                }
                return null
            })
        }
        setServicesEnabled(_ServiceList)
    }, [organizationDetails]);

    useEffect(() => {
        if (surveyQuestion) {
            setSelectedQuestion(surveyQuestion.filter(sq => sq.order === sliderValue)?.[0])
            setSliderValueMax(surveyQuestion?.length)
        }
    }, [surveyQuestion, sliderValue]);

    return (
        <>
            <div>
                {!surveyTypeId &&
                    <SingleRowContent>
                        <SectionCell>
                            <Header as='h2'>
                                Survey Questions
                            </Header>
                        </SectionCell>
                        <SectionCell>
                            <Select
                                name="ServicesEnabled"
                                options={servicesEnabled}
                                isClearable
                                onChange={setServiceSelected}
                                value={serviceSelected}
                            // getOptionLabel={(answers) => answers.name}
                            // getOptionValue={(answers) => answers.id}
                            />
                        </SectionCell>
                        <SectionCell>
                            <Button
                                onClick={GetSurveyQuestion}>
                                Fetch
                            </Button>
                        </SectionCell>
                    </SingleRowContent>
                }
                {questionDetails &&
        <Card fluid>
        <Card.Content>
                      <Container>
                            <List horizontal>
                                <List.Item>

                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>Survey Name</List.Header>
                                            {questionDetails?.name}
                                        </ListContainer>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>Survey Type</List.Header>
                                            {questionDetails?.survey_type && SURVEY_TYPE.filter(sy => sy.value === questionDetails?.survey_type)?.[0]?.label}
                                        </ListContainer>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>Status</List.Header>
                                            {questionDetails?.status && STATUS.filter(sy => sy.value === questionDetails?.status)?.[0]?.label}
                                        </ListContainer>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>Created</List.Header>
                                            {questionDetails?.created_at && format(new Date(questionDetails?.created_at), 'MMM. d, yyyy')}
                                        </ListContainer>

                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>Last Updated</List.Header>
                                            {questionDetails?.updated_at && format(new Date(questionDetails?.updated_at), 'MMM. d, yyyy')}
                                        </ListContainer>

                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <ListContainer>
                                            <List.Header>ID</List.Header>
                                            {questionDetails?.sort_order}
                                        </ListContainer>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Container>
                        </Card.Content>
        </Card>

                }
                {surveyQuestion ? surveyQuestion.map((result) => (
                    <SectionDiv>
                        <Message info>
                            Question #{result?.order}
                            <Message.Header>{result?.question}</Message.Header>
                            {result?.fr ? (
                                <Message.Header>{result?.fr}</Message.Header>) : ""}
                        </Message>
                    </SectionDiv>
                )) :
                    <SectionDiv>
                        <p></p>
                    </SectionDiv>}

            </div>
        </>
    );
};

export default OnBoardingQuestions;


const arrange_Questions = (questions) => {

    if (questions?.dimensions) {
        return questions.dimensions.map((item, i) => {
            return item.factors.map((factor, f) => {
                return factor.questions.map((q, idx) => {
                    return {
                        question: q.q,
                        dimension: i,
                        factor: f,
                        q: idx,
                        role: q.role,
                        reverse: q.reverse,
                        order: q.id,
                        fr: 'fr' in q ? q.fr : null
                    }
                })
            })
        }).flat().flat().sort((a, b) => a.order - b.order)
    }
}

const edi_arrange_Questions = (questions) => {
    if (questions) {
        return questions?.factors.map((factor, f) => {
            return factor?.questions.map((q, idx) => {
                return {
                    question: q.q,
                    factor: f,
                    q: idx,
                    role: q.role,
                    reverse: q.reverse,
                    order: q.id
                }
            })
        }).flat().flat().sort((a, b) => a.order - b.order)
    }
}

const SectionDiv = styled.div`
  padding: 0px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
`
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 20px 15px;
    min-width: 350px
`
