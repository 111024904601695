import React from 'react';

import PropTypes from 'prop-types';

import {Form} from 'semantic-ui-react'

type SearchBarProps = {
    searchTerm: string,
    setSearchTerm: (searchTerm: string) => void
}

const SearchBar = (props: SearchBarProps) => {
    return (
        <Form success>
            <Form.Input label='Search'
            value={props.searchTerm}
            onChange={(e) => props.setSearchTerm(e.target.value)}
            placeholder='Search...' />
        </Form>
    );
};

SearchBar.propTypes = {
    className: PropTypes.string
};

export default SearchBar;
