import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Segment, Dimmer, Loader, Card, Pagination, Button, } from 'semantic-ui-react'

import RowCard from "./RowCard";

import { SUB_DOMAIN_NAMES } from "common/data/AdminData"

import Select from "react-select";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import CopyTemplate from "./CopyTemplate"

import {
  UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER,
  SG_GET_EMAIL_TEMPLATES,
  SG_GET_ORGANIZATION_SELECT
} from "common/constants/actions";


const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  // const [organizationSelect, setOrganizationSelect] = useState([])
  const [orgSelected, setOrgSelected] = useState([])
  const [surveyTypeSelected, setSurveyTypeSelected] = useState([])
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [templateCopied, setTemplateCopied] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [showCopyTemplate, setShowCopyTemplate] = useState(false)
  const { get_email_template,
    get_organization_select,
    get_email_template_copied } = useSelector(
      (state) => ({
        get_email_template_copied: state.emailTemplate.email_template_copied,
        get_email_template: state.emailTemplate.email_templates,
        get_organization_select: state.organizations.organization_select,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (page) {
      dispatch({
        type: SG_GET_EMAIL_TEMPLATES,
        payload: `page=${page}`,
      });
    } else {
      dispatch({
        type: SG_GET_EMAIL_TEMPLATES,
      });
    }

  }, [dispatch, templateCopied, page]);
  useEffect(() => {

    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    setTemplateCopied(false)
    if (get_email_template_copied?.new_email_template_id) {
      setTemplateCopied(get_email_template_copied?.new_email_template_id)
    }

  }, [get_email_template_copied]);

  useEffect(() => {
    setResults(get_email_template?.results);
    setPage(get_email_template?.pageNumber)
    setTotalPages(Math.ceil(get_email_template?.count / get_email_template?.page_size))
    if (get_email_template?.count % get_email_template?.page_size) {
      setTotalPages(Math.ceil(get_email_template?.count / get_email_template?.page_size))
    }

  }, [get_email_template, page, ReloadPage]);

  useEffect(() => {
    if (ReloadPage) {
      dispatch({
        type: UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER,
      });

      // const timer = setTimeout(() => {
      //   navigate('/app/questions')
      // }, 1000);
      // return timer

    }
  }, [ReloadPage, navigate, dispatch]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  function UpdateQuestions() {
    dispatch({
      type: SG_GET_EMAIL_TEMPLATES,
      payload: `${surveyTypeSelected?.value ? `&domain=${surveyTypeSelected.value}` : ''}${orgSelected?.id ? `&organization=${orgSelected.id}` : ''}`
    });
  }

  return (
    <Container>
      <Message
        color='orange'
        icon='warning sign'
        header='Do not modify before you read this'
        content={
          `This data is crucial for the entire survey, please 
          do not change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />


      <SingleRowContent>
        <SectionCell>

          <Select
            name="survey_preference"
            options={SUB_DOMAIN_NAMES}
            isClearable
            onChange={setSurveyTypeSelected}
            // getOptionLabel={(option) => `${option.name}`}
            // getOptionValue={(option) => `${option.id}`}
            value={surveyTypeSelected}
          />
        </SectionCell>
        <SectionCell>

          <Select
            name="org_preference"
            options={get_organization_select}
            isClearable
            onChange={setOrgSelected}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={orgSelected}
          />
        </SectionCell>


        <SectionCell>
          <Button onClick={UpdateQuestions}>Filter</Button>

          <Button.Group>
            <Button
              floated='left'
              color="red"
              onClick={() => setShowCopyTemplate(true)}>
              Copy Ext
            </Button>

            <Button.Or />

            <Button
              floated='right'
              color="green"
              onClick={() => navigate(`/app/email-templates/new/`)}>
              Add New
            </Button>
          </Button.Group>
        </SectionCell>


      </SingleRowContent>

      <Segment id="results" basic>
        <Dimmer
        // active={loading}
        >
          <Loader />
        </Dimmer>
        <Card.Group>
          {results && results.map((result) => (
            <RowCard
              row={result}
              key={result?.id}
              get_organization_select={get_organization_select} />
          ))}
        </Card.Group>
      </Segment>
      <SectionDiv>

        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages ? totalPages : 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
      <CopyTemplate
        showCopyTemplate={showCopyTemplate}
        setShowCopyTemplate={setShowCopyTemplate}
      />
    </Container>
  );
};

export default TemplateList;

const Container = styled.div`
  margin-bottom: 30px;
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`

