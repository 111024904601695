import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Icon, Message } from "semantic-ui-react";

import { SG_ADD_WEB_IMAGE, UPDATE_WEB_IMAGE_NUMBER } from "common/constants/actions";

import defaultImage from "assets/image/image_placeholder.jpg";


const ImageAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageFile, setImageFile] = useState(defaultImage)

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    if (isMounted)
      return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  });

  const handleDrop = (dropped) => {
    const validFileExt = ["png", "jpg", "jpeg", "bmp"]
    if (validFileExt.includes(dropped[0]?.name.split(".").pop())) {
      setUploadedFiles(dropped[0]);
      setImageFile(URL.createObjectURL(dropped[0]));
    } else {
      setErrorMessage(`The file ${dropped[0]?.name} is not a ${validFileExt.toString()} file`);
    }
  };

  const onClickSave = () => {

    // // update employee
    dispatch({
      type: SG_ADD_WEB_IMAGE,
      payload: { image: uploadedFiles },
    });
    // setOpen(false)
    dispatch({
      type: UPDATE_WEB_IMAGE_NUMBER,
      payload: 1,
    });
    setUploadedFiles(false);
    navigate("/app/web-image")

  };
  return (
    <>
      <div
        aria-labelledby="max-width-dialog-title"
        style={{ paddingLeft: 30 }}
      >
        {errorMessage && (
          <Message
            negative
            onDismiss={() => setErrorMessage(false)}
            header="There was an Error!"
            content={errorMessage}
          />
        )}

        {imageFile && <img src={imageFile} alt="..." /> }
        <div className="dialog">
          <P>Drag &amp; Drop your File here</P>

          <Dropzone
            onDrop={handleDrop}
            noKeyboard
            // getUploadParams={getUploadParams}
            // onChange={handleChange}
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            maxFiles={1}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                file={uploadedFiles?.length > 0}
              >
                {uploadedFiles?.name ? (
                  <InnerBox
                    onClick={() => setUploadedFiles([])}
                  >
                    {" "}
                    {uploadedFiles?.name}{" "}
                    <Icon name="cancel" />
                  </InnerBox>
                ) : (
                  <p>
                    {" "}
                    <Icon name="upload" /> Drag 'n' drop Image
                    files here, or click to select Image files
                    to upload
                  </p>
                )}

                {<input {...getInputProps()} />}
              </Box>
            )}
          </Dropzone>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {uploadedFiles?.name && (
            <StyledButton
              onClick={() => onClickSave()}
              color="grey"
            >
              Upload File
            </StyledButton>
          )}
        </div>
      </div>
    </>
  );

};

export default ImageAdd;

const P = styled.p`
    font-size: 16px;
    font-family: "Barlow", sans-serif;
`;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2d50e2;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-right: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;


const Box = styled.div`
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    cursor: ${(props) => (props.file ? "default" : "pointer")};
    //   cursor:pointer;
    //   @media (max-width:768px){
    //     width:250px;
    //   }
`;
const InnerBox = styled.div`
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 15px;
    cursor: pointer;
    background-color: #dcdcdc;
`;
