import {
  GET_BLOG_CATEGORIES,
  DELETE_BLOG_CATEGORY,
  FETCH_BLOG_CATEGORY,
  EDIT_BLOG_CATEGORY,
  ADD_BLOG_CATEGORY,
  CLEAR_BLOG_CATEGORY,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  blog_category: [],
  created: false,
  blog_pics: [],
  pending: false,
};

export default function BlogCategory (state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_CATEGORIES:
      return {
        ...state,
        blog_category: action.payload,
        pending: false,
      };


  case FETCH_BLOG_CATEGORY:

          return { ...state,
            [action.payload.id]: action.payload,
            pending: false };

    case EDIT_BLOG_CATEGORY:
      return { ...state,
        [action.payload.id]: action.payload
    };

    case DELETE_BLOG_CATEGORY:
      return {
        ...state,
        blog_category: state.blog_category.filter(
          (row) => row.id !== action.payload
        ),
      };

    case ADD_BLOG_CATEGORY:
        return {
          ...state,
          blog_category: [...state.blog_category, action.payload],
        };


    case CLEAR_BLOG_CATEGORY:
      return {
        ...state,
        blog_category: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        blog_category: [],
      };

    default:
      return state;
  }
}
