import {
  GET_LEADERS_IN_SCHEDULES,
  DELETE_LEADERS_IN_SCHEDULE, 
  FETCH_LEADERS_IN_SCHEDULE, 
  EDIT_LEADERS_IN_SCHEDULE, 
  ADD_LEADERS_IN_SCHEDULE, 
  CLEAR_LEADERS_IN_SCHEDULE, 
  CLEAR_ALL,
} from "common/constants/actions";
  
  const initialState = {
    leaders_in_schedules: [],
    pending: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {

      case GET_LEADERS_IN_SCHEDULES:
        return {
          ...state,
          leaders_in_schedules: action.payload,
          pending: false,
        };
      
    case FETCH_LEADERS_IN_SCHEDULE:
            return { ...state, 
              [action.payload.id]: action.payload, 
              pending: false };

      case EDIT_LEADERS_IN_SCHEDULE:
        const schedule = state.leaders_in_schedules.filter(
            (row) => row.id !== action.payload.id
          )
        schedule.push(action.payload)
        return { ...state, 
          leaders_in_schedules: schedule
      };
  
      case DELETE_LEADERS_IN_SCHEDULE:
        return {
          ...state,
          leaders_in_schedules: state.leaders_in_schedules.filter(
            (row) => row.id !== action.payload.id
          ),
        };
      
      case ADD_LEADERS_IN_SCHEDULE:
        return {
          ...state,
          leaders_in_schedules: [...state.leaders_in_schedules, action.payload],
        };
      
      case CLEAR_LEADERS_IN_SCHEDULE:
        return {
          ...state,
          leaders_in_schedules: [],
        };
      
      case CLEAR_ALL:
        return {
          ...state,
          leaders_in_schedules: [],
        };
      
      default:
        return state;
    }
  }
