import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Header } from "semantic-ui-react";

import { SET_PAGE_TITLE } from "common/constants/actions";

const PageTitle = ({ newPageTitle, newPageSubtitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_TITLE,
      payload: newPageTitle,
    });
  }, [newPageTitle])

  return (
    newPageSubtitle ?
      <SectionDiv>
        <Header.Subheader>
          {newPageSubtitle}
        </Header.Subheader>
      </SectionDiv>
      :
      null
  );

}

export default PageTitle;

export const Container = styled.div`
  padding: 30px;
`;

export const SectionDiv = styled.div`
  padding-bottom: 35px;
`;
