import {
  UPDATE_ACTIVITY_LOG_PAGE_NUMBER,
  GET_ACTIVITY_LOG_PENDING,
  GET_ACTIVITY_LOGS,
  DELETE_ACTIVITY_LOG,
  FETCH_ACTIVITY_LOG,
  FETCH_ACTIVITY_LOG_STATUS,
  EDIT_ACTIVITY_LOG,
  ADD_ACTIVITY_LOG,
  CLEAR_ACTIVITY_LOG,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  activity_log: [],
  activity_update: [],
  pending: false,
  created: false,
  updated: false,
  pageNumber: 1,
};

export default function GptMember(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITY_LOG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_ACTIVITY_LOG_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        activity_log: action.payload,
        pending: false,
      };

    case FETCH_ACTIVITY_LOG:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };
      case FETCH_ACTIVITY_LOG_STATUS:

        return {
          ...state,
          activity_update: {[action.payload.id]: action.payload},
          pending: false
        };
    case EDIT_ACTIVITY_LOG:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };

    case DELETE_ACTIVITY_LOG:
      return {
        ...state,
        deleted: action.payload,
        pending: false,
      };

    case ADD_ACTIVITY_LOG:
      return {
        ...state,
        created: action.payload,
        pending: false,
      };

    case CLEAR_ACTIVITY_LOG:
      return {
        ...state,
        activity_log: [],
        pending: false,
        created: false,
        updated: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        activity_log: [],
        pending: false,
        created: false,
        updated: false,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
