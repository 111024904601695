import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_FETCH_EMAIL_TEMPLATE,
    SG_GET_ORGANIZATION_SELECT,
    SG_EDIT_EMAIL_TEMPLATE
} from "common/constants/actions";
//
import Select from "react-select";
//
import { Icon, Label, Button, Header, List } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { RowObjectInputEdit } from "./Components";
//
import { SUB_DOMAIN_NAMES, EMAIL_DEFINITIONS } from "common/data/AdminData"

const Details = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [templateId, setTemplateId] = useState(false)
    const [templateDetails, setTemplateDetails] = useState(null);
    const [selectDef, setSelectDef] = useState(false)
    const [emailRef, setEmailRef] = useState({})
    const [selectedObject, setSelectedObject] = useState({})

    function BackArrow() {
        navigate(`/app/email-templates`)
    }

    useEffect(() => {
        setTemplateId(id)
    }, [id]);

    useEffect(() => {
        dispatch({
            type: SG_GET_ORGANIZATION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        if (templateId) {
            dispatch({
                type: SG_FETCH_EMAIL_TEMPLATE,
                payload: { id: templateId }
            });
        }
    }, [dispatch, templateId]);

    const { get_email_template,
        get_organization_select } = useSelector(
            (state) => ({
                get_email_template: state.emailTemplate,
                get_organization_select: state.organizations.organization_select,
            }),
            shallowEqual
        );

    useEffect(() => {
        setTemplateDetails(get_email_template?.[templateId])
    }, [get_email_template, templateId]);

    useEffect(() => {
        setEmailRef(templateDetails?.email_ref)
        setSelectedObject({})
        if (selectDef?.value) {
            setEmailRef(templateDetails?.email_ref[`${selectDef.value}`])
            setSelectedObject(templateDetails?.email_ref[`${selectDef.value}`])
        }
    }, [templateDetails, selectDef]);

    function EditTemplate() {
        navigate(`/app/email-templates/edit/${templateId}`)
    }

    function UpdateDetails() {
        setSelectDef(false)
        const _emref = templateDetails?.email_ref
        _emref[selectDef.value] = selectedObject
        setEmailRef(_emref)

        if (templateId) {
            dispatch({
                type: SG_EDIT_EMAIL_TEMPLATE,
                payload: {
                    id: templateId,
                    email_ref: _emref,
                }
            });
        }
    }

    return (
        <>
            <Header as='h2'>
                <Icon
                    style={{ cursor: "pointer" }}
                    onClick={BackArrow} name='arrow left' />
                Details of {templateDetails?.name}
            </Header>
            <SectionDiv>
                Created {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}

            </SectionDiv>
            <SectionDiv>
                <Container>
                    <List horizontal>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Domain</List.Header>
                                    {templateDetails?.domain && SUB_DOMAIN_NAMES.filter(sy => sy.value === templateDetails?.domain)?.[0]?.label}
                                </ListContainer>
                            </List.Content>
                        </List.Item>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Name</List.Header>
                                    {templateDetails?.name}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Organization</List.Header>
                                    {templateDetails?.organization ?
                                        get_organization_select.filter(org => org.id === templateDetails?.organization)?.[0]?.name :
                                        "For all Org"}
                                </ListContainer>
                            </List.Content>
                        </List.Item>



                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Created</List.Header>
                                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Last Updated</List.Header>
                                    {templateDetails?.updated_at && format(new Date(templateDetails?.updated_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                    </List>
                </Container>
            </SectionDiv>
            <SectionDiv>
                <SingleRowContent>
                    <SectionCell>
                        <Header as='h3'>Template List</Header>
                    </SectionCell>
                    <SectionCell>
                        {!selectDef?.value ?
                            <Select
                                name="def_list"
                                options={EMAIL_DEFINITIONS}
                                isClearable
                                onChange={setSelectDef}
                                value={selectDef}
                            />
                            :
                            <Label>
                                {selectDef?.value} {" "}
                            <Icon
                                style={{
                                    cursor: "pointer",
                                    marginTop: "5px"
                                }}
                                onClick={() => setSelectDef(false)}
                                name='close' />
                            </Label>

                        }
                    </SectionCell>
                </SingleRowContent>

            </SectionDiv>
            <SectionDiv>
                {selectDef?.value ?
                    <RowObjectInputEdit
                        selectedObject={selectedObject}
                        setSelectedObject={setSelectedObject}

                    />
                    :
                    <pre>{JSON.stringify(emailRef, null, 3)}</pre>
                }

                <div>
                    <Button color='green' onClick={() => setSelectDef(false)}>Cancel</Button>
                    <Button color='red' onClick={UpdateDetails}>Save &amp; Close</Button>
                </div>
            </SectionDiv>

            <SectionDiv lastSection={true}>
                <Button floated='right'
                    color="blue"
                    onClick={EditTemplate}>
                    Edit Template JSON
                </Button>
            </SectionDiv>
        </>
    );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`
