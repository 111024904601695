import {
  GET_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  FETCH_SUBSCRIPTION,
  EDIT_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION,
  GET_STRIPE_TAX_RATES,
  NEXT_INVOICE,
  GET_STRIPE_PAYMENT_METHODS,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPGRADE_PLAN,
  DOWNGRADE_PLAN,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  subscriptions: [],
  invoice: [],
  taxRates: [],
  payment_methods: [],
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case NEXT_INVOICE:
      return {
        ...state,
        invoice: action.payload,
        pending: false,
      };
    case GET_STRIPE_TAX_RATES:
        return {
          ...state,
          taxRates: action.payload,
          pending: false,
        };
    case GET_STRIPE_PAYMENT_METHODS:
      return {
        ...state,
        payment_methods: action.payload,
        pending: false,
      };
      case UPGRADE_PLAN:
        return {
          ...state,
          subscriptions: action.payload,
          pending: false,
        };
      case DOWNGRADE_PLAN:
        return {
          ...state,
          subscriptions: action.payload,
          pending: false,
        };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.payload,
        pending: false,
      };
    // regular methods
      case GET_SUBSCRIPTIONS:
        return {
          ...state,
          subscriptions: action.payload,
          pending: false,
        };
    case UPDATE_SUBSCRIPTION:
      state.subscriptions = state.subscriptions.filter((row) => row.id !== action.payload.id)
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
        pending: false,
      };

    case FETCH_SUBSCRIPTION:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_SUBSCRIPTION:
      return { ...state, [action.payload.id]: action.payload };

    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.filter((row) => row.id !== action.payload),
      };

    case ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
      };

    case CLEAR_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: [],
        invoice: [],
        payment_methods: [],
        taxRates: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        subscriptions: [],
        invoice: [],
        payment_methods: [],
        taxRates: [],
      };

    default:
      return state;
  }
}
