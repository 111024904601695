import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import List from "./List.js";
import Details from "./Details.js";
import { useDispatch } from 'react-redux';
import { SG_GET_REPORT_TEMPLATE } from "common/constants/actions";
import { RecommendationType } from "common/constants/global";

function OrganzationalIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SG_GET_REPORT_TEMPLATE,
    });

  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="/" element={<List templateType={RecommendationType.ORGANIZATIONAL_GOALS} />} />
        <Route path="/details/:id" element={<Details templateType={RecommendationType.ORGANIZATIONAL_GOALS} />} />
        <Route path="/details/create" element={<Details templateType={RecommendationType.ORGANIZATIONAL_GOALS} />} />
      </Routes>
    </>
  );
}

export default OrganzationalIndex;

