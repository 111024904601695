import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";
import {
  Message,
  Form,
  Grid,
  Button,
  Header,
  Modal,
  Image,
} from "semantic-ui-react";

import Avatar from "./Avatar";
import { Dimmer, Loader, Card, Icon, Table } from "semantic-ui-react";

import Input from "./Input";
import { useToasts } from "react-toast-notifications";
import { useForm, Controller } from "react-hook-form";

import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SG_FETCH_USER_EMPLOYEE,
} from "common/constants/actions";


import "react-datepicker/dist/react-datepicker.css";

import ImageAvtar from "./ImageAvtar";


const MemberSettings = () => {
  const dispatch = useDispatch();

  const [employee, setEmployee] = useState(false);
  const [organization, setOrganization] = useState(false);

  const [dos, setDos] = useState(false);
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    errors,
    formState,
  } = useForm();
  const employee_id = useSelector((state) => state.auth.employee_id);
  const [month, setMonth] = React.useState("");
  const [day, setDay] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [ValidDate, setValidDate] = React.useState(false);
  const [databaseDate, setDatabaseDate] = React.useState(false);
  const [age, setAge] = React.useState(false);

  const [loading, setLoading] = useState(false);

  // const [reLoad, setReload] = useState(false)
  const [loadingText, setLoadingText] = React.useState("Loading");
  // console.log(formState);
  // console.log(isDirty);

  // React.useEffect(() => {
  //   // console.log("formState.isDirty changed:", formState.isDirty);
  //   setIsDirty(formState.isDirty);
  // }, [formState.isDirty]);

  // React.useEffect(() => {
  //   console.log("formState.isDirty changed:", formState);
  //   formState.reset();
  // }, [reLoad, formState]);

  React.useEffect(() => {
    // if the response is ok 200
    if (employee_id) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: { id: employee_id },
      });
    }
  }, [employee_id, dispatch]);

  const { get_employee, get_auth, organization_id, get_organization } = useSelector(
    (state) => ({
      get_employee: state.employees,
      get_auth: state.auth,
      organization_id: state.auth.organization_id,
      get_organization: state.organizations,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_employee) {
      setEmployee(get_employee?.userEmp);
      setOrganization(get_organization?.[organization_id])
      setDos(Date.parse(get_employee?.userEmp?.join_date));
      if (get_employee?.userEmp?.dob) {
        setDatabaseDate(new Date(get_employee?.userEmp?.dob));
      }
    }
  }, [get_employee, get_auth, organization_id, get_organization]);

  // setYear(new Date(get_employee[get_auth?.employee_id]?.dob.getFullYear()))
  //       setMonth(new Date(get_employee[get_auth?.employee_id]?.dob.getMonth()))
  //       setDay(new Date(get_employee[get_auth?.employee_id]?.dob.getDate()))
  // console.log((get_employee[get_auth?.employee_id]?.dob))
  // React.useEffect(() => {
  //   if (databaseDate) {
  //     setYear(databaseDate.getFullYear());
  //     setMonth(databaseDate.getMonth() + 1);
  //     setDay(databaseDate.getDate());
  //   }
  // }, [databaseDate]);

  const onSubmit = (data) => {
    if (employee) {
      // update employee
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          ...data,
          id: employee.id,
          // dob: ValidDate,
          // join_date: format(
          //   new Date(data?.join_date),
          //   "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          // ),
        },
      });
      // history.push('/dashboard')
      // setIsDirty(false);
      setLoading(true);
      setLoadingText("Profile Updated");
      addToast("Profile Updated", { appearance: "success", autoDismiss: true });
    }
    // else {
    //   // create employee
    //   dispatch({
    //     type: ADD_EMPLOYEE,
    //     payload: {
    //       ...data,
    //       dob: dob,
    //       organization_id: organization_id,
    //     },
    //   });
    // }

    // history.push(`/organization/${route}`);
  };
  // console.log(dos)

  React.useEffect(() => {
    if (employee) {
      setValue("first_name", employee?.first_name);
      setValue("last_name", employee?.last_name);
      setValue("join_date", Date.parse(employee?.join_date));
    }
  }, [employee, setValue]);

  // function ChangeMonth(val) {
  //   // console.log(val);
  //   setMonth(val);
  //   setIsDirty(true);
  // }

  // function ChangeDay(e) {
  //   // console.log(e);
  //   setDay(e);
  //   setIsDirty(true);
  // }

  // function ChangeYear(e) {
  //   // console.log(e);
  //   setYear(e);
  //   setIsDirty(true);
  // }

  // function setDateOfJoin(e) {
  //   // console.log(e)
  //   // console.log(Date.parse(e))
  //   // console.log(format(new Date(e), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
  //   setDos(Date.parse(e));
  // }

  // const date = new Date(year, +month - 1, day);
  // const isValidDate = Boolean(+date) && date.getDate() == day;

  // React.useEffect(() => {
  //   setValidDate(new Date(`${year}/${month}/${day}`));
  //   setAge(yearsDiff(new Date(`${year}/${month}/${day}`)));
  // }, [month, day, year, isValidDate]);

  // function yearsDiff(d2) {
  //   let d1 = Date.now();
  //   let date1 = new Date(d1);
  //   let date2 = new Date(d2);
  //   let yearsDiff = date1.getFullYear() - date2.getFullYear();
  //   return yearsDiff;
  // }

  // React.useEffect(() => {
  //   setDobCheck(false);
  //   if (
  //     isValidDate &&
  //     year > 1000 &&
  //     month > 0 &&
  //     day > 0 &&
  //     age < 100 &&
  //     age > 18
  //   ) {
  //     setDobCheck(true);
  //   } else {
  //     setIsDirty(false);
  //   }
  //   setTimeout(() => {
  //     // console.log("I was here")
  //     setLoading(false);
  //   }, 2000);
  // }, [month, day, year, isValidDate, age]);

  // console.log('year', year);
  // console.log('month', month);
  // console.log('day', day);
  // console.log(isValidDate);
  // console.log(ValidDate);
  // console.log(employee);
  // console.log('dobCheck', dobCheck)
  const [open, setOpen] = React.useState(false);
  const [nameOpen, setNameOpen] = React.useState(false);
  const [onClickSaveImg, setOnClickSaveImg] = useState(false);
  // function UpdateEmployee(e) {
  //   // console.log("UpdateEmployee Changed");
  //   // setIsDirty(true);
  //   // {employee?.last_name}
  // }

  // console.log(employee)
  // console.log(organization)

  function FullName() {
    // console.log(selEmp?.first_name)
    if (employee?.first_name) {
      return `${employee.first_name} ${employee.last_name}`
    }
    return employee?.email?.split('@')[0]
  }
  return (
    <Container>
      <Title>My Profile</Title>
      <FormContainer>
        <FormSection1>
          <Avatar
            picture={employee?.picture ? employee.picture : false}
            onClick={() => setOpen(true)}
            loading={loading}
          />
          <Modal
            closeIcon
            size={"small"}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          // trigger={<Button>Show Modal</Button>}
          >
            <Modal.Header>Default Profile Image</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <ImageAvtar
                  employee_id={employee_id}
                  setOpen={setOpen}
                  onClickSaveImg={onClickSaveImg}
                />
              </Modal.Description>
            </Modal.Content>
          </Modal>

        </FormSection1>

        <FormSection>

          <Table basic='very' celled collapsing>


            <Table.Body>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    {FullName()}
                  </Header.Content>
                </Table.Cell>
                <Table.Cell onClick={() => setNameOpen(true)}><Icon name='edit' />Edit</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    {employee?.email}
                  </Header.Content>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Role</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    {employee?.employee_role_name} / {employee?.account_type_text}
                  </Header.Content>
                </Table.Cell>

              </Table.Row>
              {/* <Table.Row>
      <Table.Cell>Team Type</Table.Cell>
        <Table.Cell>
          <Header.Content>
            {employee?.role_text} 
          </Header.Content>
        </Table.Cell>
      
      </Table.Row> */}
              <Table.Row>
                <Table.Cell>Team</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    {employee?.team}
                  </Header.Content>
                </Table.Cell>

              </Table.Row>
              <Table.Row>
                <Table.Cell>Organization</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    {organization?.name}
                  </Header.Content>
                </Table.Cell>

              </Table.Row>
            </Table.Body>
          </Table>
          <Modal
            closeIcon
            size={"small"}
            onClose={() => setNameOpen(false)}
            onOpen={() => setNameOpen(true)}
            open={nameOpen}
          // trigger={<Button>Show Modal</Button>}
          >
            <Modal.Header>Update Name</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form
                  loading={false}
                  onSubmit={handleSubmit(onSubmit)}
                  error
                  style={{ width: "100%" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", width: "100%" }}
                  >
                    <Controller
                      as={Input}
                      name="first_name"
                      placeholder="First name"
                      type="text"
                      control={control}
                      defaultValue={employee?.first_name}
                      rules={{
                        required: "Please enter first name",
                      }}
                      error={errors.first_name ? errors.first_name.message : ""}
                      half={true}
                    />

                    <Controller
                      as={Input}
                      name="last_name"
                      placeholder="Last name"
                      type="text"
                      control={control}
                      defaultValue={employee?.last_name}
                      rules={{
                        required: "Please enter last name",
                      }}
                      error={errors.last_name ? errors.last_name.message : ""}
                      half={true}
                    />
                  </div>


                  <StyleButton>Save</StyleButton>
                </Form>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <Dimmer.Dimmable blurring dimmed={loading}>

            <Dimmer active={loading} onClickOutside={() =>
              setLoading(false)}>
              {loadingText === "Loading" && <Loader />}{" "}
              <Header as="h2" icon inverted>
                {loadingText}
              </Header>
              {loadingText !== "Loading" && (
                <p>Click anywhere to dismiss message</p>
              )}
            </Dimmer>
          </Dimmer.Dimmable>
        </FormSection>

      </FormContainer>
    </Container>
  );
};

export default MemberSettings;

const Container = styled.div`
  margin-left: 30px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 50%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const DOBContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
