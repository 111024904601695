import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {format} from 'date-fns'

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    CLEAR_ERRORS,
    DEBRIEF_SCHEDULE_PAGE_NUMBER,
    SG_GET_DEBRIEF_SCHEDULES,
    SG_GET_SURVEY_QUESTION_SELECT,
} from "common/constants/actions";

import {Dimmer, Grid, Header, Loader, Pagination, Table} from 'semantic-ui-react'

import PageTitle, {Container, SectionDiv} from "common/layouts/AuthLayout/PageTitle"
import OrganizationSelect from "areas/organizations/components/OrganizationSelect";

const List = ({ hideOrgSelect }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_QUESTION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch({
            type: SG_GET_DEBRIEF_SCHEDULES,
            payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ''}`,
        });

        dispatch({ type: CLEAR_ERRORS })
        setLoading(true);


    }, [dispatch, page, organizationId]);


    const {
        get_brief_schedules,
        get_selectedOrg,
    } = useSelector(
        (state) => ({
            get_error: state.errors,
            get_brief_schedules: state.debrief_schedule,
            get_selectedOrg: state.selectedOrg,
            get_organization_select: state.organizations.organization_select,
            get_survey_question_select: state.surveyquestion.survey_question_select,
        }),
        shallowEqual
    );

    useEffect(() => {
        setOrganizationId(get_selectedOrg?.organization?.id)
        setPage(1)
    }, [get_selectedOrg]);

    useEffect(() => {
        setResults(get_brief_schedules?.debrief_schedule?.results);
        setPage(get_brief_schedules?.pageNumber)
        setTotalPages(Math.ceil(get_brief_schedules?.debrief_schedule?.count / get_brief_schedules?.debrief_schedule?.page_size))
    }, [get_brief_schedules, page]);

    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: DEBRIEF_SCHEDULE_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

     useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 500);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (
        <Container>
            <PageTitle newPageTitle="Survey Schedules" />

            <SectionDiv>
                <Grid>
                    <Grid.Row columns={2} >
                        <Grid.Column floated="left" width={11}>
                            <Header.Subheader>
                                Manage survey schedules
                            </Header.Subheader>
                        </Grid.Column>
                        <Grid.Column floated="right" width={4}>
                            {!hideOrgSelect &&
                                <OrganizationSelect setResetPageLoad={setResetPageLoad} />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </SectionDiv>

            <Dimmer active={loading}>
                <Loader />
            </Dimmer>

            <Table selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Survey Questions</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Start Date</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Close Date</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Invitations</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Reponses</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {results && results.map((result, i) => (
                    <Table.Body>
                        <Table.Row key={i} onClick={() => navigate(`/app/brief-schedule/details/${result?.id}`)} style={{ cursor: 'pointer' }}>
                            <Table.Cell>{result?.name}</Table.Cell>
                            <Table.Cell>{result?.brief_question?.name}{" ("}{result?.type_of_text}{")"} </Table.Cell>
                            <Table.Cell>{result?.status_text}</Table.Cell>
                            <Table.Cell>{result?.scheduled_for && format(new Date(result?.scheduled_for), 'MMM. d, yyyy h:mm a')}</Table.Cell>
                            <Table.Cell>{result?.close_at && format(new Date(result?.close_at), 'MMM. d, yyyy h:mm a')}</Table.Cell>
                            <Table.Cell>{result?.total_requests}</Table.Cell>
                            <Table.Cell>{result?.total_responses}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                ))}
            </Table>

            <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={page || 1}
                totalPages={totalPages || 1}
                onPageChange={handlePageChange}
            />
        </Container>
    );
};

export default List;


