import _ from "lodash";
import {
  GET_ERRORS,
  SHOW_ERRORS,
  ALL_ERRORS,
  VALIDATION_ERRORS,
  CLEAR_ERRORS,
  CLEAR_PASSWORD_DATA,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAIL,
  CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
  msg: {},
  errors: {},
  status: {},
  data: {},
  headers: {},
  fullError: {},
  message: false,
  password: [],
  passwordFail: [],
};

const removeLocalStorage = () => {
  localStorage.removeItem("token");
  // expires_in
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("role_id");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("organization_id");
  localStorage.removeItem("employee_id");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("is_staff");
};
export default function ShowErrors (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      // console.log(action)
      return {
        ...state,
        msg: action.payload,
        status: action.payload,
        errors: action.payload,
      };
      case CREATE_MESSAGE:
      case UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
      case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password: action.payload,
      };
      case CONFIRM_RESET_PASSWORD_FAIL:
        return {
          ...state,
          passwordFail: action.payload,
        };
    case ALL_ERRORS:
      // console.log(action)
      // console.log(action.payload)
      if (action.payload.message?.data) {
        return {
          msg: action.response,
          errors: action.response,
          status: action.response.status,
          data: action.payload.data,
          headers: action.payload.headers,
        };
      }
      else if (action.payload?.status === 401) {

        removeLocalStorage();
        state.token = null
        state.key = null
        state.user = null
        state.organization_id = null
        state.employee_id = null
        state.isAuthenticated = false
        state.isLoading = false
        state.loginerror = null
        state.is_staff = null
        state.employee = []
        state.organization = []
      }
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.data,
        headers: action.payload.headers,
        msg: action.payload,
        errors: action.payload,
        fullError: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        msg: {},
        errors: {},
        status: null,
        data: {},
        headers: {},
        fullError: {},
      }
    case CLEAR_PASSWORD_DATA:
    case CLEAR_ALL:
      return {
        ...state,
        msg: {},
        errors: {},
        status: {},
        data: {},
        headers: {},
        fullError: {},
        message: false,
        password: [],
        passwordFail: [],
      };
    default:
      return state;
  }
}

// export default function (state = initialState, action) {
//   switch (action) {
//     case GET_ERRORS:
//       console.log('reducer error payload', action)
//       return {
//         msg: action.payload.msg,
//         status: action.payload.status,
//       };
//     case ALL_ERRORS:
//       console.log('reducer error payload', action)

//       if (_.get(action, "message.data")) {
//         console.log('reducer error payload', action.payload)
//         return {
//           msg: action.message.response,
//           errors: action.message.response.data,
//           status: action.message.response.status,
//         };
//       } else if (_.get(action, "message")) {
//         return {
//           msg: action.message.response,
//           errors: action.message.response,
//           status: action.message.response,
//         };
//       }
//         else if (action.payload.errors?.status === 401) {
//           console.log(action.payload)
//           removeLocalStorage()
//           state.token = null
//           state.key = null
//           state.reset_password_ok = null
//           state.is_staff = null
//           state.reset_password_error = null
//           state.confirm_password_ok = null
//           state.confirm_password_error = null
//           state.employee = []
//           state.organization = []

//           return {
//             msg: action.payload,
//             errors: action.payload,
//             status: action.payload,
//           };
//         }
//        else {
//         return {
//           msg: action.payload,
//           errors: action.payload,
//           status: action.payload,
//         };
//       }

//     case VALIDATION_ERRORS:
//       // console.log('reducer error payload', action)
//       // console.log('reducer error payload', action.error.response.data)
//       if (_.get(action, "error")) {
//         return {
//           msg: action.error.response,
//           errors: action.error.response.data,
//           status: action.error.response.status,
//         };
//       } else {
//         return {
//           msg: action,
//           errors: action,
//           status: action,
//         };
//       }

//     case CLEAR_ERRORS:
//       console.log('reducer error payload', action)
//       return {
//         msg: {},
//         status: {},
//         errors: {},
//       };

//     default:
//       return state;
//   }
// }
