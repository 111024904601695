import React from 'react'
import {Route, Routes} from 'react-router-dom';
import List from './List';
import CreateImage from './ImageAdd';

import PageTitle, {Container} from "common/layouts/AuthLayout/PageTitle"

function WebImage() {

  return (
    <Container>
      <PageTitle
        newPageTitle="Web Images"
        newPageSubtitle="Manage custom organization image files"
      />

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="edit/:id" element={<CreateImage />} />
        <Route path="upload" element={<CreateImage />} />
      </Routes>
    </Container>
  );
}

export default WebImage;
