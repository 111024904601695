import React from 'react';
import './HelpTextIcon.css';
import { Icon } from 'semantic-ui-react'

const HelpTextIcon = ({ helpText }: { helpText: string }) => {
  return (
    <div className="icon-container">
      <Icon name="question circle" size="small" color="grey"/>
      <span className="tooltip-text">{ helpText }</span>
    </div>
  );
};

export default HelpTextIcon;
