import {
    EMPLOYEE_PERMISSION_PENDING,
    GET_EMPLOYEE_PERMISSIONS,
    DELETE_EMPLOYEE_PERMISSION,
    FETCH_EMPLOYEE_PERMISSION,
    EDIT_EMPLOYEE_PERMISSION,
    ADD_EMPLOYEE_PERMISSION,
    CLEAR_EMPLOYEE_PERMISSION,
    CLEAR_ALL,
} from "common/constants/actions";

const initialState = {
    employee_permission: [],
    pending: false,
    created: false,
};

export default function EmployeeCategories(state = initialState, action) {
    switch (action.type) {
        case EMPLOYEE_PERMISSION_PENDING:
            return {
                ...state,
                pending: true,
            }
        case GET_EMPLOYEE_PERMISSIONS:
            return {
                ...state,
                employee_permission: action.payload,
                pending: false,
                created: false,
            };

        case FETCH_EMPLOYEE_PERMISSION:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false,
                created: false,

            };

        case EDIT_EMPLOYEE_PERMISSION:
            if (state.employee_permission.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                    ...state,
                    employee_permission: state.employee_permission.filter(
                        (rw) => rw.id !== action.payload.id
                    ),
                };
            }
            return {
                ...state,
                created: false,
                employee_permission: [...state.employee_permission, action.payload],
            };

        case DELETE_EMPLOYEE_PERMISSION:
            // console.log(action.payload)
            return {
                ...state,
                employee_permission: state.employee_permission.filter(
                    (row) => row.id !== action.payload.id
                ),
            };

        case ADD_EMPLOYEE_PERMISSION:

            const newState = state.employee_permission
            newState.push(action.payload)


            return {
                ...state,
                created: action.payload,
                employee_permission: [...state.employee_permission, action.payload],
            };

        case CLEAR_EMPLOYEE_PERMISSION:
            return {
                ...state,
                employee_permission: [],
                created: false,
            };

        case CLEAR_ALL:
            return {
                ...state,
                employee_permission: [],
                created: false,
            };

        default:
            return state;
    }
}
