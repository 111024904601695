

import {
    UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
    GET_REPORT_TEMPLATE_PENDING,
    GET_REPORT_TEMPLATE,
    DELETE_REPORT_TEMPLATE,
    FETCH_REPORT_TEMPLATE,
    EDIT_REPORT_TEMPLATE,
    ADD_REPORT_TEMPLATE,
    CLEAR_REPORT_TEMPLATE,
    CLEAR_ALL,
  } from "common/constants/actions";

  const initialState = {
    report_template: [],
    pending: false,
    updated: false,
    pageNumber: 1,
  };

  export default function ReportTemplate(state = initialState, action) {
    switch (action.type) {
      case UPDATE_REPORT_TEMPLATE_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
          pending: false,
        };
      case GET_REPORT_TEMPLATE_PENDING:
        return {
          ...state,
          pending: true,
          updated: false,
        };
      case GET_REPORT_TEMPLATE:
        return {
          ...state,
          report_template: action.payload,
          pending: false,
          updated: false,
        };

      case FETCH_REPORT_TEMPLATE:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          updated: false,
        };


      case EDIT_REPORT_TEMPLATE:
        let updatedReportTemplate = state.report_template.results.map((row) => {
          if (Number(row.id) === Number(action.payload.id)) {
            return action.payload;
          }
          return row;
        }
        );
        return {
          ...state,
          [action.payload.id]: action.payload,
          report_template: { ...state.report_template, results: updatedReportTemplate },
          updated:  action.payload,
          pending: false,
        };

      case DELETE_REPORT_TEMPLATE:
        return {
          ...state,
          report_template: {...state.report_template, results: state.report_template?.results.filter(
            (row) => Number(row.id) !== Number(action.payload.id)
          )},
          pending: false,
        };

      case ADD_REPORT_TEMPLATE:
        let newReportTemplate = state.report_template;
        // If report_template does not have a results key, create one and push the new report template
        if (!state.report_template.results) {
          newReportTemplate = { ...state.report_template, results: [] };
        }
        newReportTemplate.results.push(action.payload);
        
        return {
          ...state,
          created: action.payload,
          report_template: newReportTemplate,
          response: action.payload.response,
          pending: false,
        };

      case CLEAR_REPORT_TEMPLATE:
        return {
          ...state,
          ...initialState,
        };

      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };

      default:
        return state;
    }
  }
