import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Button, Input, Message, Modal, Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router';
import Select from "react-select";

import { CATEGORY_COMPLIANCE_HEADERS, CATEGORY_STATUS } from "common/data/AdminData"

import { useDispatch } from "react-redux";
import {
    SG_ADD_SURVEY_STRUCTURE_COMPLIANCE, SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
} from "common/constants/actions";

import { AddRow } from './AddEditRow';
import TableList from './TableList';

const Form = ({ row, detailLevel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [changed, setChanged] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [selectedRow, setSelectedRow] = useState(false)

    const [selectedRowKey, setSelectedRowKey] = useState(false)
    const [dataRows, setDataRows] = useState([])

    const [surveyTypeSelected, setSurveyTypeSelected] = useState([])
    const [categoryStatusSelected, setCategoryStatusSelected] = useState([])

    const [name, setName] = useState('')
    const [openCategoryEdit, setOpenCategoryEdit] = React.useState(false)

    useEffect(() => {
        setSurveyTypeSelected(CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.value === row?.priority)?.[0])
        setCategoryStatusSelected(CATEGORY_STATUS.filter(cc => cc.value === row?.status)?.[0])
        setName(row?.name)
    }, [row]);

    function CancelEdit() {
        navigate(`/app/category-compliance`)
    }

    function UpdateRow() {
        if (row?.id) {
            dispatch({
                type: SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    id: row?.id,
                    priority: surveyTypeSelected?.value,
                    status: categoryStatusSelected?.value,
                    name: name,
                },
            });
        } else {
            dispatch({
                type: SG_ADD_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    priority: surveyTypeSelected?.value,
                    status: categoryStatusSelected?.value,
                    name: name,
                    categories: [],
                    detail_level: detailLevel || 1,
                },
            });
            if (detailLevel && detailLevel === 2) {
                navigate("/app/category-questions/")
            }
            navigate("/app/category-compliance/")
        }
    }

    useEffect(() => {
        setDataRows(row?.categories)
    }, [row]);

    function UpdateAllRows() {
        if (row?.id) {
            dispatch({
                type: SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    id: row?.id,
                    priority: surveyTypeSelected?.id,
                    status: categoryStatusSelected?.id,
                    name: name,
                    categories: dataRows
                },
            });
            setShowMessage({
                header: "Updated Rows",
                content: "Successfully updated Rows"
            })
        }
    }

    function UpdateCategory() {
        UpdateRow()
        UpdateAllRows()
        navigate(`/app/category-compliance?reload=true`)
    }

    function AddToRow(row) {
        const _ListRows = dataRows
        _ListRows.push(row)
        setDataRows(_ListRows)
    }

    function EditSelectRow(i, row) {
        setSelectedRowKey(i)
        setSelectedRow(row)
        setOpenCategoryEdit(true)
    }

    function EditUpdateRow(row) {
        const _ListRows = dataRows
        _ListRows[selectedRowKey] = row
        setSelectedRow(_ListRows)
        setSelectedRowKey(false)
    }

    function deleteRow(id, event) {
        const _ListRows = dataRows.filter(sr => sr.id !== id)
        setDataRows(_ListRows)
        setChanged(Math.random())
        event.stopPropagation()
    }

    return (
        <>
            {showMessage &&
                <Message
                    positive
                    onDismiss={() => setShowMessage(false)}
                    header={showMessage?.header}
                    content={showMessage?.content}
                />
            }

            <SectionDiv>
            <Grid>
                <Grid.Row>
                    <Grid.Column floated="left" width={13}>
                    <SectionHeader>
                    <Select
                        name="survey_preference"
                        options={CATEGORY_COMPLIANCE_HEADERS}
                        onChange={setSurveyTypeSelected}
                        value={surveyTypeSelected}
                    />
                </SectionHeader>
                <RowInputLine>
                    <Input fluid
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </RowInputLine>
                    </Grid.Column>
                    <Grid.Column floated="right" width={3}>
                    <SectionHeader>
                    <Select
                        name="category_status"
                        options={CATEGORY_STATUS}
                        onChange={setCategoryStatusSelected}
                        value={categoryStatusSelected}
                    />
                </SectionHeader>
                    </Grid.Column>
                </Grid.Row>
            </Grid>



                {!row?.id &&
                    <>
                        <Button onClick={CancelEdit}>{"Cancel"}</Button>
                        <Button primary onClick={UpdateRow}>{"Add Category"}</Button>
                    </>
                }

            </SectionDiv>
            <SectionDiv>
                {dataRows && <TableList
                    rows={dataRows}
                    deleteRow={deleteRow}
                    EditSelectRow={EditSelectRow}
                />}

            </SectionDiv>

            <Modal closeIcon onClose={() => setOpenCategoryEdit(false)} open={openCategoryEdit}>
                <Modal.Header>Category`` Details</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {row?.id &&
                            <AddRow
                                AddToRow={AddToRow}
                                setChanged={setChanged}
                                selectedRow={selectedRow}
                                EditUpdateRow={EditUpdateRow}
                                setOpenCategoryEdit={setOpenCategoryEdit}
                            />
                        }
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            {row?.id &&
                <>
                    <Button primary floated='right'
                        onClick={UpdateCategory}>Update Category</Button>

                    <Button floated='left' color='green'
                        onClick={() => setOpenCategoryEdit(true)}>Add Category Option</Button>

                    <Button floated='right' color='grey' onClick={CancelEdit}>
                        {"Cancel"}
                    </Button>

                </>
            }
        </>
    );
};

export default Form;

const SectionDiv = styled.div`
            padding-bottom: 20px;
            `

const SectionHeader = styled.span`
            min-width: 200px;
            font-size: 110%;
            display: inline-block;
            `

const RowInputLine = styled.span`
            min-width: 350px;
            font-size: 110%;
            margin: 10px;
            display: inline-block;
            `
