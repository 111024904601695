import {
    GET_FILE_UPLOADS,
    DELETE_FILE_UPLOAD,
    UPDATE_FILE_UPLOAD_PAGE_NUMBER,
    FETCH_FILE_UPLOAD,
    EDIT_FILE_UPLOAD,
    ADD_FILE_UPLOAD,
    CLEAR_CREATED_FILE_UPLOAD,
    CLEAR_FILE_UPLOAD,
    CLEAR_ALL,
} from "common/constants/actions";

  const initialState = {
    file_uploads: [],
    created: [],
    deleted: false,
    pageNumber: 1,
    pending: false,
  };

  export default function FileUpload(state = initialState, action) {
    switch (action.type) {

      case GET_FILE_UPLOADS:
        return {
          ...state,
          file_uploads: action.payload,
          deleted: false,
          pending: false,
        };
      case UPDATE_FILE_UPLOAD_PAGE_NUMBER:
          return {
            ...state,
            pageNumber: action.payload,
            pending: false,
          };
      case FETCH_FILE_UPLOAD:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          deleted: false,
        };

      case CLEAR_CREATED_FILE_UPLOAD:
        return {
          ...state,
          created: [],
          pending: false
        };

      case EDIT_FILE_UPLOAD:
        return {
          ...state,
          [action.payload.id]: action.payload,

        };

      case DELETE_FILE_UPLOAD:
        return {
          ...state,
          deleted: action.payload.id,
        };

      case ADD_FILE_UPLOAD:
        return {
          ...state,
          created: action.payload,
        };

      case CLEAR_FILE_UPLOAD:
        return {
          ...state,
            file_uploads: [],
            created: [],
            pageNumber: 1,
            pending: false,
            deleted: false,
        };

      case CLEAR_ALL:
        return {
          ...state,
            file_uploads: [],
            created: [],
            pageNumber: 1,
            pending: false,
            deleted: false,
        };

      default:
        return state;
    }
  }
