import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from 'date-fns'

import { Modal, Header, Button, Icon, Table } from 'semantic-ui-react'

import ReactJson from "react-json-view";

const RowCard = ({ row }) => {
  const [openPortal, setOpenPortal] = useState(false)
  const [showResponse, setShowResponse] = useState(false)
  const [briefSummary, setBriefSummary] = useState(false)

  function ShowResponse(resp) {
    setOpenPortal(true)
    setShowResponse(resp)
  }

  return (
    <>
      <Table.Row onClick={() => ShowResponse(row?.response)} style={{ cursor: 'pointer' }}>
        <Table.Cell>{row?.updated_at && format(new Date(row?.updated_at), 'MMM. d, yyyy hh:mm a')}</Table.Cell>
        <Table.Cell>{row?.type_of_text}</Table.Cell>
        <Table.Cell><TypesAndStatusArray serviceUsed={row?.response?.feedback} /></Table.Cell>
        <Table.Cell>{row?.employee}</Table.Cell>
        <Table.Cell>{row?.id}</Table.Cell>
      </Table.Row>

      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        briefResponse={showResponse}
        briefSummary={briefSummary}
      />
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;



function TypesAndStatusArray({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed.length > 0 ?
        <><Icon color='green' name='checkmark' size='small' />Yes</>
        :
        <><Icon color='red' name='close' size='small' />No</>
      }
    </>
  )
}

const MoreDetails = ({ openPortal, setOpenPortal, briefResponse, briefSummary }) => {

  return (<Modal
    closeIcon
    open={openPortal}
    onClose={() => setOpenPortal(false)}
    onOpen={() => setOpenPortal(true)}
  >
    <Header>Detailed Response</Header>
    <Modal.Content>

      {briefResponse &&
        <ReactJson src={briefResponse} />
        // <ContentDetail briefResponse={briefResponse} />
      }
      {briefSummary &&
        <ReactJson src={briefSummary} />
        // <Summary briefChanggeHistory={briefSummary} />
      }
    </Modal.Content>
    <Modal.Actions>

      <Button color='purple' onClick={() => setOpenPortal(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}