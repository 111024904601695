import { call, put, all, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
    GET_SURVEY_COMPLIANCES,
    SG_GET_SURVEY_COMPLIANCES,
    DELETE_SURVEY_COMPLIANCE,
    SG_DELETE_SURVEY_COMPLIANCE,
    SG_FETCH_SURVEY_COMPLIANCE,
    FETCH_SURVEY_COMPLIANCE,
    SG_EDIT_SURVEY_COMPLIANCE,
    EDIT_SURVEY_COMPLIANCE,
    SG_ADD_SURVEY_COMPLIANCE,
    ADD_SURVEY_COMPLIANCE,
    CLEAR_SURVEY_COMPLIANCE,
    SG_CLEAR_SURVEY_COMPLIANCE,
    ALL_ERRORS,
} from "common/constants/actions";

function* loadsurveyCompliances(action) {
  try {
    const json = yield call(api.GET_SURVEY_COMPLIANCES, action.payload);
    yield put({ type: GET_SURVEY_COMPLIANCES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* surveyComplianceLoad() {
  yield takeLatest(SG_GET_SURVEY_COMPLIANCES, loadsurveyCompliances);
}

function* fetchsurveyCompliances(action) {
  try {
    const json = yield call(api.FETCH_SURVEY_COMPLIANCE, action.payload);
    yield put({ type: FETCH_SURVEY_COMPLIANCE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* surveyComplianceFetch() {
  yield takeLatest(SG_FETCH_SURVEY_COMPLIANCE, fetchsurveyCompliances);
}

function* updatesurveyCompliances(action) {
  try {
    const json = yield call(api.EDIT_SURVEY_COMPLIANCE, action.payload);
    yield put({ type: EDIT_SURVEY_COMPLIANCE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* surveyComplianceUpdate() {
  yield takeEvery(SG_EDIT_SURVEY_COMPLIANCE, updatesurveyCompliances);
}

function* addsurveyCompliances(action) {
  try {
    const json = yield call(api.ADD_SURVEY_COMPLIANCE, action.payload);
    yield put({ type: ADD_SURVEY_COMPLIANCE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* surveyComplianceAdd() {
  yield takeEvery(SG_ADD_SURVEY_COMPLIANCE, addsurveyCompliances);
}

function* deletesurveyCompliances(action) {
  try {
    yield call(api.DELETE_SURVEY_COMPLIANCE, action.payload);
    yield put({ type: DELETE_SURVEY_COMPLIANCE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* surveyComplianceDelete() {
  yield takeEvery(SG_DELETE_SURVEY_COMPLIANCE, deletesurveyCompliances);
}


export default function* index() {
  yield all([
    surveyComplianceLoad(),
    surveyComplianceFetch(),
    surveyComplianceUpdate(),
    surveyComplianceAdd(),
    surveyComplianceDelete(),
  ]);
}
