import {
    GET_EMOTION_DEFINITIONS,
    CLEAR_ALL,
  } from "common/constants/actions";

  const initialState = {
    emotion_definition: [],
    pending: false,
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_EMOTION_DEFINITIONS:
        return {
          ...state,
          emotion_definition: action.payload,
          pending: false,
        };
      case CLEAR_ALL:
        return {
          ...state,
          emotion_definition: [],
          pending: false,
        };
      default:
        return state;
    }
  }
