import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  LOAD_PERSONALITY,
  GET_PERSONALITY,
  LOAD_TRAIT_DATA,
  GET_TRAIT_DATA,
  ALL_ERRORS,
} from "common/constants/actions";

import * as api from "common/constants/api";
import eq_data from 'common/data/trait_data.json'


function* getPersonality(action) {

  try {
    const json = yield call(api.GET_PERSONALITY_REPORTS, action.payload);

    yield put({ type: GET_PERSONALITY, payload: json.data });
    // yield put({ type: GET_PERSONALITY, payload: personalityData });


  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadPersonality() {
  yield takeLatest(LOAD_PERSONALITY, getPersonality);
}

function* getTraitData(action) {

  try {
    // const json = yield call(api.GET_TRAIT_DATA, action.payload);
    // yield put({ type: GET_TRAIT_DATA, payload: json.data });
    yield put({ type: GET_TRAIT_DATA, payload: eq_data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadTraitData() {
  yield takeLatest(LOAD_TRAIT_DATA, getTraitData);
}


export default function* index() {
  yield all([loadPersonality(),
    loadTraitData(),
  ]);
}
