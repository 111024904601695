import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
    GET_ACCOUNTS,
    SG_GET_ACCOUNTS,
    FETCH_ACCOUNT,
    SG_FETCH_ACCOUNT,
    EDIT_ACCOUNT,
    SG_EDIT_ACCOUNT,
    DELETE_ACCOUNT,
    SG_DELETE_ACCOUNT,
    ALL_ERRORS,
} from "common/constants/actions";


function* loadaccounts(action) {
//   yield put({ type: GET_accountS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ACCOUNTS, action.payload);
    yield put({ type: GET_ACCOUNTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* accountLoad() {
  yield takeLatest(SG_GET_ACCOUNTS, loadaccounts);
}

function* fetchaccounts(action) {
  try {
    const json = yield call(api.FETCH_ACCOUNT, action.payload);
    yield put({ type: FETCH_ACCOUNT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* accountFetch() {
  yield takeLatest(SG_FETCH_ACCOUNT, fetchaccounts);
}

function* updateaccounts(action) {
  try {
    const json = yield call(api.EDIT_ACCOUNT, action.payload);
    yield put({ type: EDIT_ACCOUNT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* accountUpdate() {
  yield takeLatest(SG_EDIT_ACCOUNT, updateaccounts);
}


function* deleteaccounts(action) {
  try {
    yield call(api.DELETE_ACCOUNT, action.payload);
    yield put({ type: DELETE_ACCOUNT, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* accountDelete() {
  yield takeLatest(SG_DELETE_ACCOUNT, deleteaccounts);
}


export default function* index() {
  yield all([
    accountLoad(),
    accountFetch(),
    accountUpdate(),
    accountDelete(),
  ]);
}
