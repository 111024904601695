import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Dimmer, Grid, Header, Icon, Loader, Pagination, Table} from "semantic-ui-react";

import SearchBar from "./SearchBar";
import {useNavigate} from "react-router-dom";

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {LOAD_EMPLOYEES, SG_GET_EMPLOYE_CATEGORY_LIST, UPDATE_EMPLOYEE_PAGE_NUMBER,} from "common/constants/actions";

import PageTitle, {Container} from "common/layouts/AuthLayout/PageTitle"
import OrganizationSelect from "areas/organizations/components/OrganizationSelect";
import CategoryList from "./CategoryList";


const List = ({ hideOrgSelect, orgId }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(false);
  const [resetPageLoad, setResetPageLoad] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(1);

  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categorySearch, setCategorySearch] = useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    if (orgId) {
      setOrganizationId(orgId);
    }
  }, [orgId]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch({
      type: LOAD_EMPLOYEES,
      payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ""
        }${searchTerm ? `&search=${searchTerm}` : ""}${categorySearch?.id ? `&category=${categorySearch?.id}` : ""}`,
    });

    dispatch({
      type: SG_GET_EMPLOYE_CATEGORY_LIST,
      payload: `show=true${organizationId ? `&organization=${organizationId}` : ""
        }${categorySearch?.id ? `&search=${categorySearch?.id}` : ""
        }`,
    });

    setLoading(true);
  }, [
    dispatch,
    page,
    searchTerm,
    organizationId,
    resetPageLoad,
    categorySearch,
  ]);

  const { get_employees, get_selectedOrg } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_selectedOrg: state.selectedOrg,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationId(get_selectedOrg?.organization?.id);
    // setResetPageLoad(!resetPageLoad)
  }, [get_selectedOrg]);

  useEffect(() => {
    setResults(get_employees?.employees?.results);
    setPage(get_employees?.pageNumber);
    setTotalPages(Math.ceil(get_employees?.employees?.count / get_employees?.employees?.page_size))
    setCategoryNames(get_employees?.employee_category_list?.response?.name);
    setCategoryValues(get_employees?.employee_category_list?.response?.value);
  }, [get_employees, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_EMPLOYEE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

   useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_employees?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_employees]);

  function ResetCategoryList() {
    setPage(1)
    // setShowCategoryList(!showCategoryList);
    setCategorySearch(false);
  }

  function ShowingCategoryList() {
    setShowCategoryList(!showCategoryList);
  }

  return (
    <Container>
      <PageTitle newPageTitle="Employees" />

      <Grid>
        <Grid.Row>
          <Grid.Column floated="left" width={8}>
            <Header.Subheader>
              Manage an organization's employees
            </Header.Subheader>

          </Grid.Column>
          <Grid.Column floated="right" width={5}>
            {!hideOrgSelect && (
              <OrganizationSelect setResetPageLoad={setResetPageLoad} />
            )}
          </Grid.Column>
          <Grid.Column width={3}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <RightAlign>
        {categorySearch?.id &&
          <ContainerFieldSelectable onClick={ResetCategoryList}>
            Reset Category {categorySearch?.name} <Icon name="close" />
          </ContainerFieldSelectable>
        }
        <ContainerFieldSelectable onClick={ShowingCategoryList}>
          {showCategoryList ? (
            <> Hide Category Filter <Icon name="close" /></>
          ) : (
            "Show Category Filter"
          )}
        </ContainerFieldSelectable>
      </RightAlign>

      {showCategoryList && (
        <CategoryList
          values={categoryValues}
          setCategorySearch={setCategorySearch}
          valueIdName={get_employees?.employee_category_list?.response?.value_to_uid_list}
        />
      )}

      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Email</Table.HeaderCell>
            <Table.HeaderCell width={1}>Type</Table.HeaderCell>
            <Table.HeaderCell width={1}>Access</Table.HeaderCell>
            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
            <Table.HeaderCell width={1}>ID</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {results && results.map((result, i) => {
            return (<Table.Row key={i} onClick={() => navigate(`/app/employees/details/${result?.id}`)} style={{ cursor: 'pointer' }}>
              <Table.Cell>{result?.last_name}{", "}{result?.first_name}</Table.Cell>
              <Table.Cell>{result?.email}</Table.Cell>
              <Table.Cell>{result?.account_type_text}</Table.Cell>
              <Table.Cell>{result?.enable_login ? "Portal Access" : ""}</Table.Cell>
              <Table.Cell>{result?.status === 1 ?
                <Icon color='green' name='check' /> :
                <Icon color='red' name='archive' />}
                {result?.status_text}</Table.Cell>
              <Table.Cell>{result?.id}</Table.Cell>
            </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <Grid>
        <Grid.Row>
          <Grid.Column floated="left" width={7}>
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={page || 1}
              totalPages={totalPages || 1}
              onPageChange={handlePageChange}
            />
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right" width={8}>
            <p>Total Employees: {get_employees?.employees?.count}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default List;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

