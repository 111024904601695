import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_EMPLOYEE_RECORD_VALUE,
  SG_UPDATE_EMPLOYEE_RECORD_VALUE,
  SG_ADD_EMPLOYEE_RECORD_VALUE,
  SG_DELETE_EMPLOYEE_RECORD_VALUE,
  SG_GET_ORGANIZATION_SELECT,
} from "common/constants/actions";

import { Message, Button, Header, Input, Confirm, Checkbox } from 'semantic-ui-react'

import { format } from 'date-fns'

import { EMP_VALUE_TYPES, EMP_VALUE_DATA_ACTION, EMP_VALUE_DATA_FOR } from "common/data/AdminData"

import Select from "react-select";
import { BasicErrorMessage } from "common/utils/ErrorMessages"

import { HelpTextIcon } from 'common/components/icons/index';

const AddEditValue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });
  const [orgSelected, setOrgSelected] = useState([])
  const [typeOfSelected, setTypeOfSelected] = useState(EMP_VALUE_TYPES[1])
  const [dataActionSelected, setDataActionSelected] = useState(EMP_VALUE_DATA_ACTION[1])
  const [dataForSelected, setDataForSelected] = useState(EMP_VALUE_DATA_FOR[1])

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [visibleField, setVisibleField] = useState(0)

  /* Todo: TP this works well enough, but there should be a global way of doing this */
  const RequiredStar = () => {
    return (<span style={{ color: "red" }}>{" *"}</span>);
  };

  function CancelEdit() {
    navigate(-1);
  }

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_EMPLOYEE_RECORD_VALUE,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  const { get_employee_record_value,
    get_organization_select, get_selectedOrg } = useSelector(
      (state) => ({
        get_employee_record_value: state.employee_record_value,
        get_organization_select: state.organizations.organization_select,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_employee_record_value?.[templateId]) {
      setTemplateDetails(get_employee_record_value?.[templateId])
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_employee_record_value?.[templateId]?.organization)?.[0])
      setTypeOfSelected(EMP_VALUE_TYPES.filter(sd => sd.value === get_employee_record_value?.[templateId]?.type_of_value)?.[0])
      setDataActionSelected(EMP_VALUE_DATA_ACTION.filter(sd => sd.value === get_employee_record_value?.[templateId]?.data_action)?.[0])
      setDataForSelected(EMP_VALUE_DATA_FOR.filter(sd => sd.value === get_employee_record_value?.[templateId]?.data_for)?.[0])
      setVisibleField(get_employee_record_value?.[templateId]?.sort_order)
    } else {
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_selectedOrg?.organization?.id)?.[0])
    }
  }, [get_employee_record_value, templateId, get_organization_select, get_selectedOrg]);


  useEffect(() => {
    if (get_employee_record_value?.created?.id) {
      navigate(`/app/employee-record-value/details/${get_employee_record_value?.created?.id}`)
    }
  }, [get_employee_record_value, navigate]);

  function ChangeVibility() {
    if (visibleField === 1) {
      setVisibleField(0)
      return
    }
    setVisibleField(1)
  }

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_EMPLOYEE_RECORD_VALUE,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/employee-record-value?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_UPDATE_EMPLOYEE_RECORD_VALUE,
          payload: {
            id: templateId,
            name: templateDetails?.name,
            external_name: templateDetails?.external_name,
            cat_field_name: templateDetails?.cat_field_name,
            data_location: templateDetails?.data_location,
            type_of_value: typeOfSelected?.value,
            data_for: dataForSelected?.value,
            data_action: dataActionSelected?.value,
            organization: orgSelected?.id,
            sort_order: visibleField,
          }
        });
      } else {
        dispatch({
          type: SG_ADD_EMPLOYEE_RECORD_VALUE,
          payload: {
            name: templateDetails?.name,
            external_name: templateDetails?.external_name,
            cat_field_name: templateDetails?.cat_field_name,
            data_location: templateDetails?.data_location,
            type_of_value: typeOfSelected?.value,
            data_for: dataForSelected?.value,
            data_action: dataActionSelected?.value,
            organization: orgSelected?.id,
            sort_order: visibleField,
          }
        });
      }
      setPristine(true)
      navigate(`/app/employee-record-value?reload=true`)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    if (!typeOfSelected?.value) {
      ErrorList.push("Select a Data Type")
    }
    if (!dataActionSelected?.value) {
      ErrorList.push("Select a New Data Action")
    }
    if (!templateDetails?.external_name) {
      ErrorList.push("Enter an External Name")
    }
    if (!dataForSelected?.value) {
      ErrorList.push("Select a Data Entity")
    }
    setFormErrors(ErrorList)
  }, [templateDetails, typeOfSelected, orgSelected,
    valueChanged, dataActionSelected, dataForSelected]);

  function ChangeFormValues(e, name) {
    let _ques = templateDetails
    _ques[`${name}`] = e.target.value ? e.target.value : null
    setTemplateDetails(_ques)
    setValueChanged(e.target.value + name)
  }

  function ChangeDataType(e) {
    setTypeOfSelected(e)
    setValueChanged(e)
  }

  function ChangeOrg(e) {
    setOrgSelected(e)
    setValueChanged(e)
  }

  return (
    <>
      <Container>
        <Header as='h2'>
          {templateId ? `${templateDetails?.name}` : "New Employee Record"}
        </Header>
        {formErrors.length > 0 && !pristine &&
          <Message negative
            header='Please supply values for all required fields'
            list={formErrors}
          />
        }
        <BasicErrorMessage />

        <SectionDiv>
          <FormRow>
            <FormLabel>
              <LabelHead>Name<RequiredStar /></LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="name"
                defaultValue={templateDetails?.name}
                value={templateDetails?.name || ""}
                onChange={(e) => ChangeFormValues(e, "name")}
              />
            </FormInput>
          </FormRow>
          <FormRow>
            <FormLabel>
              <LabelHead>Organization<RequiredStar /></LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="org_preference"
                options={get_organization_select}
                onChange={(e) => ChangeOrg(e)}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                value={orgSelected}
                defaultValue={orgSelected}
              />
            </FormInput>
          </FormRow>
          <FormRow>
            <FormLabel>
              <LabelHead>Data Type<RequiredStar /></LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="type_of_value"
                options={EMP_VALUE_TYPES}
                onChange={(e) => setTypeOfSelected(e)}
                value={typeOfSelected}
                defaultValue={typeOfSelected}
              />
            </FormInput>
          </FormRow>


          <FormRow>
            <FormLabel>
              <LabelHead>New Data Action<RequiredStar /></LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="direction"
                options={EMP_VALUE_DATA_ACTION}
                onChange={(e) => setDataActionSelected(e)}
                value={dataActionSelected}
                defaultValue={dataActionSelected}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>External Name<RequiredStar /></LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="external_name"
                value={templateDetails?.external_name || ""}
                defaultValue={templateDetails?.external_name}
                onChange={(e) => ChangeFormValues(e, "external_name")}
              />
            </FormInput>
          </FormRow>
          <FormRow>
            <FormLabel>
              <LabelHead>{"Category Field Name "}
                <HelpTextIcon helpText={"Used for CSV upload and api. Use lowercase letters."} />
              </LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="cat_field_name"
                value={templateDetails?.cat_field_name || ""}
                defaultValue={templateDetails?.cat_field_name}
                onChange={(e) => ChangeFormValues(e, "cat_field_name")}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>{"Data Location "}
                <HelpTextIcon helpText={"Seperated by periods (.) and can be up to 5 levels deep"} />
              </LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="data_location"
                onChange={(e) => ChangeFormValues(e, "data_location")}
                value={templateDetails?.data_location || ""}
                defaultValue={templateDetails?.data_location}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Visible</LabelHead>
            </FormLabel>
            <FormInput>
              <Checkbox toggle checked={visibleField ? true : false} onClick={ChangeVibility} />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>{"Data Entity"}
                {/* Todo: There are "category" and "main" types in the API as well. This might be worth keeping, but I don't know if it works at the moment.*/}
                {/*<HelpTextIcon helpText={"Only valid survey structure values can be specified for category imports."} />*/}
              </LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="direction"
                options={EMP_VALUE_DATA_FOR}
                onChange={(e) => setDataForSelected(e)}
                value={dataForSelected}
                defaultValue={dataForSelected}
              />
            </FormInput>
          </FormRow>

          {templateId &&
            <FormRow>
              <FormLabel>
                <LabelHead>Created On</LabelHead>
              </FormLabel>
              <FormInput>
                {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'MMM. d, yyyy')}
              </FormInput>
            </FormRow>
          }
        </SectionDiv>

        <SectionDiv lastSection={true}>
          <Button primary floated='right'
            onClick={UpdateQuestions}>
            {/* Todo: TP The delete button doesn't seem to work. This could be useful and should be fixed, probably an API issue.*/}
            {templateId ? "Update Record" : "Create Record"}
          </Button>
          <Button floated="right" color="grey" onClick={CancelEdit}>
            {"Cancel"}
          </Button>
          {templateId &&
            <>
              {/* Todo: TP The delete button doesn't seem to work. This could be useful and should be fixed, probably an API issue.*/}
              <Button floated='left'
                color="red"
                onClick={() => setConfirmOpen(true)}>
                Delete Record
              </Button>
              <Confirm
                open={confirmOpen}
                header="Delete Record"
                content="Are you sure you want to delete this record?"
                onCancel={() => setConfirmOpen(false)}
                onConfirm={DeleteQuestion}
              />
            </>}
        </SectionDiv>
      </Container>
    </>
  );
};

export default AddEditValue;


const Container = styled.div`
  padding: 30px;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const LabelHead = styled.label`
font-weight: 700;
display: block;
`

const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
