import React from 'react'
import {Route, Routes} from 'react-router-dom';
import List from './List';
import Details from './Details';
import SelectOrg from "areas/authentication/components/SelectOrg"
import {Header} from 'semantic-ui-react'

function StaffLogin() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Login List
        <Header.Subheader>
          List of All Users who can login.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="select-organization" element={<SelectOrg />} />
      </Routes>
    </>
  );
}

export default StaffLogin;
