import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'

const EmployeeRecordValue = ({ rows }) => {
    const navigate = useNavigate()
    return (

        <>
            <Table selectable >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Data Type</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Data Entity</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Category Name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>External Name</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i} onClick={() => navigate(`/app/employee-record-value/details/${row?.id}`)} style={{ cursor: 'pointer' }}>
                            <Table.Cell>{row?.name}</Table.Cell>
                            <Table.Cell>{row?.type_of_value_text} </Table.Cell>
                            <Table.Cell>{row?.data_for_text} </Table.Cell>
                            <Table.Cell>{row?.cat_field_name}</Table.Cell>
                            <Table.Cell>{row?.external_name}</Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeRecordValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeRecordValue;