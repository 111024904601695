import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  GET_BLOG_TAGS,
  SG_GET_BLOG_TAGS,
  DELETE_BLOG_TAG,
  SG_DELETE_BLOG_TAG,
  FETCH_BLOG_TAG,
  SG_FETCH_BLOG_TAG,
  EDIT_BLOG_TAG,
  SG_EDIT_BLOG_TAG,
  ADD_BLOG_TAG,
  SG_ADD_BLOG_TAG,
  CLEAR_BLOG_TAG,
    ALL_ERRORS,
} from "common/constants/actions";



function* loadblogs(action) {
//   yield put({ type: GET_blog_TAGES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_BLOG_TAGS, action.payload);
    yield put({ type: GET_BLOG_TAGS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogLoad() {
  yield takeLatest(SG_GET_BLOG_TAGS, loadblogs);
}

function* fetchblogs(action) {
  try {
    const json = yield call(api.FETCH_BLOG_TAG, action.payload);
    yield put({ type: FETCH_BLOG_TAG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogFetch() {
  yield takeLatest(SG_FETCH_BLOG_TAG, fetchblogs);
}

function* updateblogs(action) {
  try {
    const json = yield call(api.EDIT_BLOG_TAG, action.payload);
    yield put({ type: EDIT_BLOG_TAG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogUpdate() {
  yield takeLatest(SG_EDIT_BLOG_TAG, updateblogs);
}

function* addblogs(action) {
  try {
    const json = yield call(api.ADD_BLOG_TAG, action.payload);
    console.log(json.data)
    yield put({ type: ADD_BLOG_TAG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogAdd() {
  yield takeLatest(SG_ADD_BLOG_TAG, addblogs);
}


function* deleteblogs(action) {
  try {
    const json = yield call(api.DELETE_BLOG_TAG, action.payload);
    yield put({ type: DELETE_BLOG_TAG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogDelete() {
  yield takeLatest(SG_DELETE_BLOG_TAG, deleteblogs);
}


export default function* index() {
  yield all([
    blogLoad(),
    blogFetch(),
    blogUpdate(),
    blogAdd(),
    blogDelete(),
  ]);
}
